<template>
	<template v-if="model.chartClan=='part'">
		<chartpart :data-set="dataSet" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="datas" />
	</template>
	<template v-else-if="model.chartClan=='grid'">
		<chartgrid :data-set="dataSet" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="datas" />
	</template>
	<template v-else-if="model.chartClan=='polar'">
		<chartpolar :data-set="dataSet" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="datas" />
	</template>
	<template v-else-if="model.chartClan=='map'">
		<geomap :data-set="dataSet" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="datas" />
	</template>
	<template v-else>
		<div>未知图表组件类型{{model.type}}</div>
	</template>
</template>

<script>
	import Part from './part/Part.vue'
	import Grid from './echarts/grid/GridChart.vue'
	import Polar from './echarts/polar/PolarChart.vue'
	import GeoMap from './echarts/map/MapChart.vue'

	import CommEvent from '../../../commevent.js'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase, CommEvent],
		data() {
			return {
				dataSet: null,
				lastTime: 0,
				superComp: true
			}
		},

		methods: {
			flushData(event, eventKey) {
			
				if (event.error) { //如果失败
					this.dataSet = null
					let err = event.error
					//防止高频请求连续失败的错误频繁弹出，如果当前时间离上次弹出时间超过5秒才弹出
					let time = new Date().getTime()
					let sconds = (time - this.lastTime) / 1000
					if (sconds > 5) {
						this.lastTime = time
						this.$logic.tip.error('数据请求失败：' + err.msg)
					}

				} else {
					this.doEvent(event, eventKey) //通过用户程序处理一遍数据 
					let dataSet = event.result.results ? event.result.results[0] : event
						.result //接口返回数据，多个数据集的情况将第一个数据集中的数据送给图表组件
					let model = this.model
					let fieldHead = {}
					if (model.dataOption) {
						for (let head of model.dataOption.jsonArray.heads) {
							if (head.field) {
								fieldHead[head.field] = head.label
							}
						}
					}
					let head = []
					for (let item of dataSet.heads) {
						let label = fieldHead[item.label]
						head.push(label ? label : item.label)
					}
					let rs = [head].concat(dataSet.dataList)
					this.dataSet = rs
				 
				}
			}



		},
		mounted() {


		},

		created() {
			this.model.$flushData = this.flushData


		},


		components: {
			chartpart: Part,
			chartgrid: Grid,
			chartpolar: Polar,
			geomap: GeoMap,

		},

	}
</script>

<style scoped>

</style>