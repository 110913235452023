import md5 from './md5.js'

class Security {
	getMd5(str, ex) {
		let rs = md5(str)
		if (ex) {
			rs = md5(rs + ex)
		}
		return rs
	}

	getBase64(data) {
		return btoa(unescape(encodeURIComponent(data)))
	}

	encode62(src) {
		if (src == null) {
			return '';
		}
		let sb = '';
		for (let i = 0; i < src.length; i++) {
			let code = src.charCodeAt(i);

			sb = sb + this.getChart62(code);
		}
		return sb;
	}

	getChart62(code) { //0123...abc...ABC    一个字符码由3个符号表示

		let sb = '';
		let last = code;
		while (last > 0) {
			let tail = last % 62;
			let chr = 0;
			if (tail > 35) {
				chr = (tail - 36) + 65; //大写字母
			} else if (tail > 9) {
				chr = (tail - 10) + 97; //小写字母
			} else {
				chr = tail + 48;
			}
			sb = sb + String.fromCharCode(chr)
			last = Math.floor(last / 62);

		}

		let len = sb.length;
		for (let i = 0; i < 3 - len; i++) {
			sb = sb + '0';
		}
		let rs = sb
		//  return rs.replaceAll("000","-").replaceAll("00", "_");
		return rs.replace("00", "_");

	}

	fromBase642(data) {


		let cs = data.split('');
		for (let i = 1; i < cs.length; i = i + 2) {
			let c = cs[i];
			cs[i] = cs[i - 1];
			cs[i - 1] = c;
		}
		let rs = cs.join('')
		rs = rs.replace(/@/g, "+")
		rs = rs.replace(/\$/g, "/")
		rs = rs.replace(/_/g, "=")
		rs = this.fromBase64(rs)
		return rs


	}

	fromBase64(data) {
		return decodeURIComponent(escape(atob(data)))
	}
	
	encodeSql(sql) {
		let md = this.getMd5(sql, 'logic' + (new Date().getTime()))
		let es = md + this.getBase64(sql)

		let rs = es //this.getBase64(sql)
		rs = rs.replace(/\+/g, "@")
		rs = rs.replace(/\//g, "$")
		rs = rs.replace(/=/g, "_")

		rs = '$' + this.encode62(rs)
		return rs
	}
}

export default new Security()