import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import extend from '../../public/extend.js'


let base = [
	{key: 'title',text: '企微定位'	},
    {key: 'apiKey',text: '应用KEY',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
 //   {key: 'title',text: '扫一扫'	},   
    {key: 'LocationType',text: '坐标类型',type: 'select',init: 'wgs84',	target: '',bursh: true,bind: true,tip: '',list: options.toOptions('GPS坐标=wgs84,火星坐标=gcj02')},
     {key: 'longitudeTo',text: '经度回填',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
    {key: 'latitudeTo',text: '纬度回填',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
    {key: 'speedTo',text: '纬度回填',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},

   {key: 'title',text: '定位值'	},
	{key: 'longitude',text: '经度',type: 'number',init: null,	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
	{key: 'latitude',text: '纬度',type: 'number',init: null,	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
	{key: 'speed',text: '速度',type: 'number',init: null,	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
	{key: 'accuracy',text: '位置精度',type: 'number',init: null,	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
	
	 
 
	]
	
let actions=[
	{key: '$comp_qwSuccess',text: '定位成功',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '从返回的code值判断成功或失败',list: null	},
	{key: '$comp_qwFail',text: '定位失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '从返回的code值判断成功或失败',list: null	},
	{key: '$comp_qwCancel',text: '取消定位',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
	 
	
]
	
let qiYeWeiXinDingWei = {
	data: {
		type: 'qwdw',
	},
	delete: '',
	hide: '',
	base: base,//.concat(compBase.base)
	special: [],
	action:actions.concat(options.getEvents(null,'$hook'))//  options.getCommonEvents().concat(actions).concat(options.getEvents(null,'$hook'))
}

export default qiYeWeiXinDingWei