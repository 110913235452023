import options from '../../../../../public/options.js'
import polarConfig from '../polarConfig.js'
import echartConfig from '../../echartConfig.js'
let initData=JSON.stringify( [
 	['名称','数值'],
 	['周一',60],
	['周二',80],
	['周三',100],
	['周四',120],
	['周五',150],

 ]).replaceAll('],','],\r\n')
let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
let base = [].concat( echartConfig.base)
let polar=[
	 {key:'seriesFunnelLeft',text:'水平对齐',type:'select',init:'center',path:'_series.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	 {key:'seriesFunnelTop',text:'垂直对齐',type:'select',init:'middle',path:'_series.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},
	 {key:'seriesFunnelLeftPx',text:'左边距离',type:'number',init:null,path:'_series.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	 {key:'seriesFunnelTopPx',text:'顶部距离',type:'number',init:null,path:'_series.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},
//	 {key:'seriesFunnelRightPx',text:'右边距离',type:'number',init:null,path:'_series.right',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
//	 {key:'seriesFunnelBottomPx',text:'底部距离',type:'number',init:null,path:'_series.bottom',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},

	 {key:'seriesFunnelWidth',text:'宽度',type:'text',init:'60%',path:'_series.width',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesFunnelHeidght',text:'高度',type:'text',init:'75%',path:'_series.height',bursh:true,bind:true,tip:'',list:null},

]
options.insertBaseConfig(base,polar)
 
let special = [
	 {key:'title',text:'漏斗图系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'funnel',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesPieColorBy',text:'取色方式',type:'select',init:'data',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesFunnelAlign',text:'图形对齐',type:'select',init:'center',path:'_series.funnelAlign',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	 {key:'seriesItemBorderColor',text:'图形描边色',type:'colorA',init:null,path:'_series.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesItemBorderType',text:'描边线类型',type:'select',init:'solid',path:'_series.itemStyle.borderType',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	 {key:'seriesItemBorderWidth',text:'描边线粗细',type:'number',init:1,path:'_series.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesItemBorderOpacity',text:'图形透明度',type:'number',init:1,path:'_series.itemStyle.opacity',bursh:true,bind:true,tip:'',list:null},

{key:'seriesFunnelLabelShow',text:'显示标签',type:'switch',init:'Y',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesFunnelLabelFontSize',text:'标签字号',type:'number',init:14,path:'_series.label.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesFunnelLabelFontFamily',text:'标签字体',type:'select',init:'',path:'_series.label.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'seriesFunnelLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesFunnelLabelPosition',text:'标签位置',type:'select',init:'outside',path:'_series.label.position',bursh:true,bind:true,tip:'',list:options.chartPosition},
	 //{key:'seriesFunnelLabelAlign',text:'标签对齐',type:'select',init:'center',path:'_series.label.align',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
  {key:'FunnelLabelLineShow',text:'显示引导线',type:'switch',init:'Y',path:'_series.labelLine.show',bursh:true,bind:true,tip:'',list:options.YON},
  {key:'FunnelLabelLineLength',text:'引导线长度',type:'number',init:20,path:'_series.labelLine.length',bursh:true,bind:true,tip:'象数值',list:null},
  {key:'FunnelLabelLineWidth',text:'引导线粗细',type:'number',init:1,path:'_series.labelLine.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
  {key:'FunnelLabelLineColor',text:'引导线颜色',type:'color',init:options.initColor.line.dark,path:'_series.labelLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 

]//.concat(polarConfig.special)
let action = polarConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_funnel',polarAxisLineShow:'N',legendShow:'N',color:options.chartColor.bar5r,
		chartClan: 'polar', initData:initData
	},
	delete: '',
	hide: 'seriesType,dataOption',
	base:[].concat(base) ,
	special: special,
	action: polarConfig.action.timer.concat(polarConfig.action.comm)
}
export default cfg
