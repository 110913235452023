import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
import Clone from '../../../../../plugins/deepClone.js'

let datas =JSON.stringify( [
		{id:0,pid:null,label:'根节点',icon:'far fa-home-lg-alt',color:null,data:{} },
		{id:1,pid:0,label:'节点1',icon:'fad fa-user-alt',data:{} },
		{id:2,pid:0,label:'节点2',icon:'fas fa-user-alt',color:'#ff0000',data:{} },
		{id:3,pid:0,label:'节点3',icon:'far fa-user-alt',color:'#00ff00',data:{} },
		{id:4,pid:0,label:'节点4',icon:'far fa-user-alt',data:{} },
		{id:11,pid:1,label:'节点1.1',icon:'fas fa-user-alt',data:{} },
		{id:12,pid:1,label:'节点1.2',icon:'far fa-user-alt',data:{} },
		{id:21,pid:2,label:'节点2.1',icon:null,data:{} },
		{id:22,pid:2,label:'节点2.2',icon:null,data:{} },
	 	 
])
let events=[
	{key:'title',text:'组件业务事件'},
	{key: '$comp_open',text: '对话框弹出',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '对话框弹出前',list: null	},
	{key: '$comp_nodeclick',text: '节点点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '点击节点触发',list: null	},
	{key: '$comp_before',text: '选择完成前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '完成选择前触发，返回false阻止对话框选择，必须使用同步方法',list: null	},
	{key: '$comp_after',text: '选择完成后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '完成选择后触发',list: null	},
//	{key: '$comp_cancel',text: '取消选择',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '取消选择后触发',list: null	},
	
]
let dataConfig=compBase.getDataConfig()
let configText= Clone.deepClone(dataConfig)
let configTextPath= Clone.deepClone(dataConfig)
let configIdPath= Clone.deepClone(dataConfig) 

let validate=compBase.getValidate() 
validate.validateSet.exclude='input'

let ruleId= validate.validateRule
let ruleText= Clone.deepClone(ruleId) 
let ruleTextPath= Clone.deepClone(ruleId) 
let ruleIdPath= Clone.deepClone(ruleId) 

let inputOption=options.getInputOption() //options.inputOption  
let base = [
{key: 'title',text: '树选择对话框'	}, 
	{key:'inputType',text:'输入方式',type:'text',init:'treechoose',target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'inputOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null,attribute:'inputType'},

	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
{key: 'nodeId',text: '节点ID',type: 'text',init: null,	target: '',	bursh: true,bind: true,tip: ''},
{key: 'nodeText',text: '节点名称',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: ''},
{key: 'textPath',text: '名称路径',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: ''},
{key: 'idPath',text: 'ID路径',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: ''},
{key: 'splitChar',text: '层级分隔符',type: 'text',init: '/',	target: '',	bursh: true,bind: true,tip: ''},
{key: 'idSide',text: 'ID路径附加',type: 'select',init: 'none',	target: '',bursh:true,bind:false,tip: '',list:options.toOptions('默认=none,头部添加分隔符=head,尾部添加分隔符=tail,两端添加分隔符=both')},	
{key: 'labelSide',text: '文本路径附加',type: 'select',init: 'none',	target: '',bursh:true,bind:false,tip: '',list:options.toOptions('默认=none,头部添加分隔符=head,尾部添加分隔符=tail,两端添加分隔符=both')},	

//{key: 'currentNode',text: '当前对象',type: 'json',init: '',	target: '',bursh:true,bind:true,tip: '只读属性，JSON数据对象，运行时有效',list:null},	

{key: 'fieldId',text: 'ID字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'nodeId'},
{key: 'fieldText',text: '名称字段',type: 'field',init: configText,	target: '',	bursh: true,bind: false,tip: '',attribute:'nodeText'},
{key: 'fieldTextPath',text: '路径字段',type: 'field',init: configTextPath,	target: '',	bursh: true,bind: false,tip: '',attribute:'textPath'},
{key: 'fieldIdPath',text: 'ID路径字段',type: 'field',init: configIdPath,	target: '',	bursh: true,bind: false,tip: '',attribute:'idPath'},

{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
{key: 'validateId',text: 'ID校验',type: 'validate',init: ruleId,	target: '',	bursh: true,bind: false,tip: '',attribute:'nodeId'},
{key: 'validateText',text: '文本校验',type: 'validate',init: ruleText,	target: '',	bursh: true,bind: false,tip: '',attribute:'nodeText'},
{key: 'validateTextPath',text: '文本路径',type: 'validate',init: ruleTextPath,	target: '',	bursh: true,bind: false,tip: '',attribute:'textPath'},
{key: 'validateIdPath',text: 'Id路径',type: 'validate',init: ruleIdPath,	target: '',	bursh: true,bind: false,tip: '',attribute:'idPath'},
{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
{key:'dialogReset',text:'弹出时重置',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'就否缓存上次选择的结果',list:null},	 

{key:'dialogTitle',text:'对话框标题',type:'text',init:'请选择',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'dialogIcon',text:'对话框图标',type:'icona',init:'far fa-folder-open',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 

{key:'dialogWidth',text:'对话框宽度',type:'text',init:'800px',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'dialogHeight',text:'对话框高度',type:'text',init:'80vh',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 


/* {key: 'allowSearch',text: '允许查找',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	

{key: 'allowEdit',text: '允许编辑',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key:'edit',text:'编辑功能',type:'text',init:'add,rename,delete',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key: 'allowCheck',text: '允许勾选',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'checked',text: '默认勾选',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '逗号分隔字符串或数组',list:null},	
{key: 'expandAll',text: '展开全部',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'expanded',text: '默认展开',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '逗号分隔字符串或数组',list: null},	
{key: 'expandClick',text: '点击时展开',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'accordion',text: '手风琴模式',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'allowDrag',text: '允许拖拽',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'deleteEmpty',text: '仅删空节点',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
	 */	
	 compBase.status2,
{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
	
 
			
			




]
 
  
let treeview = {
	data: {
		type: 'treedialog',isInput:'M',
	},
	delete: '',
	hide: 'width,height,inputType',
	base: base.concat(compBase.base),
	special:extend.tip.concat( [
	//	{key: 'treeSql',text: '动态树Sql',type: 'json',init:'select id,pid,label from table where',	target: '',	bursh: true,bind: true,tip: 'SQL返回结果中至少需要有id：节点ID、pid：上级节点ID、label节点标签 这三个字段，icon：节点图标，color:节点颜色，非必要项',list: null	},

	//	{key: 'listData',text: '树节点数据',type: 'json',init: datas,	target: '',bursh:false,bind:true,tip: '静态树节点数据，如果设置动态获取节点数据的SQL，此配置失效',treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:'唯一标识',pKey:'上级节点标识',label:'标题文本',icon:'图标'}},
	]),

	action:events.concat( options.getEvents(null,'$event_focus,$event_blur')).concat(options.getEvents(null,'$hook'))
}

export default treeview
