import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
 

let base=[
	{key:'title',text:'文本'},
	compBase.mode,
	{key:'value',text:'内容',type:'area',init:'文本',target:'',bursh: true,bind: true,tip:'',list:null},	 
	{key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
   {key:'title',text:'转换金额'},
   {key: 'autoCapital',text: '金额转大写',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key: 'srcNum',text: '金额数值',type: 'text',init: null,	target: '',	bursh: true,bind: true,read:true,tip: '',list: null},
//    {key: 'unit',text: '金额单位',type: '元',init: null,	target: '',	bursh: true,bind: true,tip: '',list: null},
{key:'title',text:'转换拼音'},

{key: 'toPingyin',text: '转换方式',type: 'select',init: '',	target: '',	bursh: true,bind: true,tip: '',list: options.toOptions('不转换=,拼音全拼=full,中文单词首字母=first,中文首字母单词全=first2')},
{key: 'chinese',text: '中文',type: 'text',init: '',	target: '',	bursh: true,bind: true,read:true,tip: '',list: null},
 {key: 'toCase',text: '大小写转换',type: 'select',init: '',	target: '',	bursh: true,bind: true,tip: '',list: options.toOptions('默认规则=,全大写=up,全小写=low')},
 {key: 'withTone',text: '包含声调',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},

]

let label= {
	data:{
		type:'label',
	},
	delete:'',
	hide:'mode',
	base: base.concat(font).concat(compBase.base),
	special: extend.badge.concat(extend.menu).concat(extend.tip),
	action:options.getCommonEvents().concat([compBase.menuClick]).concat([
		//options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default label