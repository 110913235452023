<template>

	<div :style="compStyle">
		<div ref="calendarHead" v-if="model.titleShow==='Y'" class="carlendar-top">
			<span>{{model.calendarText}}</span>
			<span>{{ currentMonth }}</span>

			<div v-if="model.buttonShow=='Y'">
				<el-button-group v-if="model.buttonMode=='button'">
					<el-button v-for="button in buttonList" size="small" @click="changeDate(button.key)"
						:type="model.buttonType">
						<i :class="'far '+button.icon "></i>
						{{button.label}}
					</el-button>


				</el-button-group>
				<el-dropdown v-else-if="model.buttonMode=='fold'" trigger="click" split-button type="default"
					size="small" :type="model.buttonType" @click="changeDate('today')" @command="changeDate"
					style="width: unset;">
					<i class=""></i> 本月
					<template #dropdown>
						<el-dropdown-menu>
							<template v-for="(button,index) in buttonList">
								<el-dropdown-item :command="button.key">
									<span>
										<i :class="button.icon"></i>
										{{button.label}}
									</span>
								</el-dropdown-item>
							</template>
						</el-dropdown-menu>
					</template>
				</el-dropdown>

			</div>
			<span v-else> </span>

		</div>
		<table ref="calendarTable" border="0px" class="calendar-table"
			:style="{'--date-cell-width':colWidth,'--date-label-v':model.showItems==='Y'?'flex-start':'center'}">
			<tr class="calendar-table-head">
				<td>日</td>
				<td>一</td>
				<td>二</td>
				<td>三</td>
				<td>四</td>
				<td>五</td>
				<td>六</td>
			</tr>
			<tr v-for="week in weekList" class="calendar-table-row">
				<td v-for="day in week" @click="dateClick(day)">

					<div v-if=" (model.onlyMonth =='N' || day.inMonth)"
						:class="'date-cell '+(day.inMonth? 'date-cell-in':'date-cell-out')+(  currentDay==day.date?' date-cell-current':'')">
						<div :class="'date-label '+( day.inMonth?'date-label-in':'date-label-out')">

							<el-badge v-if="model.badgeMode && day.badge" :is-dot="model.badgeMode==='dot'"
								:value="day.badge" class="date-badge">
								<span> {{day.day}}</span>
							</el-badge>


							<span v-else>{{day.day}}</span>

						</div>
						<template v-if="model.showItems==='Y'">
							<template v-for="item in day.list">
								<div v-if="item.data.$join" :class="'date-data-item '+ item.$style "
									:style="item.data.$join?'background-color:'+(item.data.color?item.data.color: '#3788d8')+';color:#ffffff':''"
									:title="item.data.title +' ( '+ item.data.timestart+' / '+item.data.timeend +' )'">
									<template v-if="day.weekDay===0 || item.$startIn">
										<span>
											<i v-if="item.data.icon" :class="item.data.icon" />
											{{item.data.title}}
										</span>
									</template>
								</div>
								<div v-else :class="'date-data-item '+ item.$style "
									:style="'border-color:'+(item.data.color?item.data.color: '#cccccc')"
									:title="item.data.title +' ( '+ item.data.timestart+' / '+item.data.timeend +' )'">
									<span :style="{color:item.data.color}">
										<i v-if="item.data.icon" :class="item.data.icon"
											:style="{color:item.data.iconcolor}" />
										{{item.data.title}}
									</span>
								</div>

							</template>

							<!-- 
							<div v-for="item in day.list" :class="'date-data-item '+ item.$style "
								:title="item.data.title +' ( '+ item.data.timestart+' / '+item.data.timeend +' )'"
								:style="item.data.$join?'background-color:'+(item.data.color?item.data.color: '#3788d8')+';color:#ffffff':''"
								@click="dataClick(day,item)">

								<template v-if="day.weekDay===0 || item.$startIn">
									<span v-if="item.data.$join">
										<i v-if="item.data.icon" :class="item.data.icon"
											:style="{color:item.data.iconcolor}" />
										{{item.data.title}}
									</span>

									<span v-else :style="{color:item.data.color}">
										<i v-if="item.data.icon" :class="item.data.icon"
											:style="{color:item.data.iconcolor}" />
										{{item.data.title}}
									</span>
								</template>


							</div> -->
							<!-- <el-tag v-for="item in day.list" type="" effect="light" class="event-item">
								<i :class="item.icon" />
								{{item.label}}
							</el-tag> -->
						</template>
					</div>
					<div v-else class="date-cell date-cell-out">
						<div class="date-label date-label-out">
							{{day.day}}
						</div>

					</div>

				</td>

			</tr>
		</table>

	</div>


</template>

<script>
	import LogicCalendar from '../../../../comm/calendar/Calendar.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				monthRange: null,
				calendarRange: null,
				currentDate: null,

				weekList: [],
				dateList: [],
				dateMap: {},
				colWidth: '100%', //'20px',
				buttonList: [{
						key: 'prev-year',
						label: '上年',
						icon: 'far fa-angle-double-left'
					},
					{
						key: 'prev-month',
						label: '上月',
						icon: 'far fa-angle-left'
					},
					{
						key: 'today',
						label: '本月',
						icon: 'far fa-map-marker'
					},
					{
						key: 'next-month',
						label: '下月',
						icon: 'far fa-angle-right'
					},
					{
						key: 'next-year',
						label: '下年',
						icon: 'far fa-angle-double-right'
					},
				],

			}
		},

		methods: {
			dataClick(day, dayData) {
				this.currentDate = day.dateTime
				this.doEvent({
					eventName: 'dataClick',
					dataItem: dayData.data,
					dateItem: day,
					dateItems: this.dateList,
					dateMap: this.dateMap

				}, '$comp_dataclick')

			},
			changeDate(changeType) {
				if (this.monthRange) {
					let start = this.monthRange.start
					let target = null
					switch (changeType) {
						case 'prev-year':
							target = new Date(start.getTime())
							target.setFullYear(target.getFullYear() - 1);
							break
						case 'prev-month':
							target = this.$logic.date.addMonthsToDate(start.getTime(), -1)
							break
						case 'today':
							target = new Date()
							break
						case 'next-month':
							target = this.$logic.date.addMonthsToDate(start.getTime(), 1)
							break
						case 'next-year':
							target = new Date(start.getTime())
							target.setFullYear(target.getFullYear() + 1);
							break
					}
					if (target) {
						this.setDateRange(target)

					}
				}
			},
			setcurrentDate(dateTime) {
				if (!dateTime) {
					return
				}
				this.setData('selectDate', this.$logic.date.toDateString(dateTime))
				this.setData('selectYear', dateTime.getFullYear())
				this.setData('selectMonth', dateTime.getMonth() + 1)
				this.setData('selectDay', dateTime.getDate())
				this.setData('selectWeek', dateTime.getDay())
				this.setData('selectTimes', dateTime.getTime())

			},
			dateClick(day) {

				if (day.inMonth) {
					this.currentDate = day.dateTime
				} else {
					this.setDateRange(day.dateTime)
				}

				this.doEvent({
					eventName: 'dateClick',
					dateItem: day,
					dateItems: this.dateList,
					dateMap: this.dateMap
				}, '$comp_dateclick')


			},
			selectDate(val) {
				this.$refs.calendar.selectDate(val)
				let date = this.$refs.calendar.selectedDay
				this.setDateRange(date.$d)
				//console.log(this.$refs.calendar)
				//console.log(this.$refs.calendar.selectedDay)
			},
			getDateRange(date, onlyMonth = true) { //获取指定日期的两端日期，onlyMonth是否只取本月的头尾，否则是本月第一天的周日日期和本月最后一天的周末的日期
				let monthFirstDate = this.$logic.date.getFirstDateOfMonth(date)
				let monthLastDate = this.$logic.date.getLastDateOfMonth(date)
				let date1 = monthFirstDate
				let date2 = monthLastDate
				if (!onlyMonth) {
					date1 = this.$logic.date.getFirstDayOfWeek(monthFirstDate)
					date2 = this.$logic.date.getLastDayOfWeek(monthLastDate)
				}

				return {
					start: date1,
					end: date2
				}
			},
			setDateRange(date) {
				let monthRange = this.getDateRange(date, true)
				this.monthRange = monthRange
				let calendarRange = this.getDateRange(date, false)
				this.calendarRange = calendarRange
				let dateList = []
				let dateMap = {}
				let pd = new Date(calendarRange.start.getTime());
				let dateTime = pd.getTime()
				let endTime = calendarRange.end.getTime()
				while (dateTime <= endTime) {
					let dateString = this.$logic.date.toDateString(pd)
					let dayTime = new Date(pd.getTime())
					//dayTime.setHours(23, 59, 59, 999);
					let dateItem = {
						inMonth: dateTime >= monthRange.start.getTime() && dateTime <= monthRange.end.getTime(),
						day: pd.getDate(),
						date: dateString,
						dateTime: dayTime,
						startTimes: dayTime.getTime(),
						weekDay: dayTime.getDay(),
						year: dayTime.getFullYear(),
						badge: null,
						list: []
					}
					dateList.push(dateItem)
					dateMap[dateString] = dateItem
					pd.setDate(pd.getDate() + 1)
					dateTime = pd.getTime()
				}
				this.dateList = dateList
				this.dateMap = dateMap
				let weeks = []
				for (let i = 0; i < dateList.length / 7; i++) {
					let week = []
					weeks.push(week)
					for (let j = 0; j < 7; j++) {
						week.push(dateList[i * 7 + j])
					}
				}
				this.weekList = weeks

				this.currentDate = date

				this.model.todayTime = this.$logic.date.getString(date)
				this.model.monthStart = this.$logic.date.getString(monthRange.start)
				this.model.monthEnd = this.$logic.date.getString(monthRange.end)
				this.model.calendarStart = this.$logic.date.getString(calendarRange.start)
				this.model.calendarEnd = this.$logic.date.getString(calendarRange.end)
				this.linkUpdate()
				this.doEvent({
					eventName: 'rangeChanged',
					currentDate: this.currentDate,
					weekList: this.weekList,
					dateItems: this.dateList,
					dateMap: this.dateMap
				}, '$comp_rangechanged')
				this.loadDateData(true)
				//	console.log(dateList)

			},
			loadDateData(request = false) {
				if (request && !this.isedit) {
					this.model.$dataLoad(null, (res) => {
						this.doEvent({
							eventName: 'dataLoaded',
							currentDate: this.currentDate,
							dataList: res,
							dateItems: this.dateList,
							dateMap: this.dateMap
						}, '$comp_dataloaded')

						this.fillDateData(res)
					})
				} else {
					this.fillDateData(this.dataList)
				}
			},
			fillDateData(list) { //根据起始截止日期拆分多个副本到每个日期格中
				if (!this.calendarRange) {
					return
				}
				for (let day of this.dateList) { //清队原有数据
					day.list = []
					day.badge = 0
				}
				let startTimes = this.calendarRange.start.getTime()
				let endTimes = this.calendarRange.end.getTime()
				for (let item of list) {

					if (!item.timestart || !item.timeend) {
						continue
					}
					item.$endTimes = new Date(item.timeend).getTime()
					item.$startTimes = new Date(item.timestart).getTime()

					if (item.$startTimes > endTimes || item.$endTimes < startTimes) { //不在当期范围内的跳过
						continue
					}
					for (let day of this.dateList) { //如果是在此天内的，加入集合,
						//一天的开始时间落在数据的时间区间内,或者数据项的开始时间落在一天的区间内，//或者数据项的完成时间落在一天的区间内
						let dayEnd = day.startTimes + 86400 * 1000 //下一天的零0

						if (day.startTimes >= item.$startTimes && day.startTimes <= item.$endTimes ||
							item.$startTimes >= day.startTimes && item.$startTimes < dayEnd ||
							item.$endTimes >= day.startTimes && item.$endTimes < dayEnd
						) {
							let data = {
								data: item
							}
							//判断数据的起点是否在此天内
							data.$startIn = item.$startTimes >= day.startTimes && item.$startTimes < dayEnd
							data.$endIn = item.$endTimes >= day.startTimes && item.$endTimes < dayEnd
							/* data.$style = data.$startIn && data.$endIn ? 'date-data-item-all' :
								data.$startIn ? 'date-data-item-start' : data.$endIn ?
								'date-data-item-end' : ''
 */
							if (data.$startIn && data.$endIn) {
								data.$style = 'date-data-item-all'
							} else if (data.$startIn) {
								data.$style = 'date-data-item-start'
								data.data.$join = true
							} else if (data.$endIn) {
								data.$style = 'date-data-item-end'
								data.data.$join = true
							}
							day.badge++
							if (this.model.dataMax == 0 || day.badge < this.model.dataMax) {
								day.list.push(data)
							}

						}

					}


				}
				setTimeout(() => { //计算单元格宽度
					this.resizeUpdate()
				}, 100)

			},
			resizeUpdate() {
				//计算表格列宽
				if (this.$refs.calendarHead) {
					let width = this.$refs.calendarHead.offsetWidth
					this.colWidth = ((width / 7)) + 'px'
				} else {

				}

			}


		},
		computed: {
			currentMonth() {
				let ms = ''
				if (this.currentDate) {
					ms = this.currentDate.getFullYear() + '年' + (this.currentDate.getMonth() + 1) + '月'
				}
				return ms


			},
			currentDay() {
				let ds = ''
				if (this.currentDate) {
					ds = this.$logic.date.toDateString(this.currentDate)
				}
				return ds
			}


		},
		watch: {
			currentDate(nv, ov) {
				this.setcurrentDate(nv)
			},
			'model.listData'(newValue, oldValue) {
				this.loadDateData(false)
			},
			'$store.state.resizeTick'() { //APP.vue中设置window.onresize监听事件
				this.resizeUpdate()

			},
		},
		components: {
			logicCalendar: LogicCalendar
		},
		mounted() {



		},
		created() {
			let now = new Date()
			let ts = this.$logic.date.toDateString(now) + ' 00:00:00'

			this.setDateRange(new Date(ts))
			//this.setcurrentDate(new Date()) //初始化当前值 
			this.model.$sqlUpdate = () => { //空方法，用于取消自动联动查询

			}



		}
	}
</script>

<style scoped>
	.carlendar-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: var(--calendar-title-height);
		background-color: var(--calendar-title-bgcolor);
		color: var(--calendar-title-color);
		font-size: var(--calendar-title-size);
		padding: 5px;
		box-shadow: 0px 0px 0px 1px var(--calendar-border-color);
	}

	.calendar-table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: var(--calendar-border-color);

	}

	.calendar-table-head {
		height: 30px;
		background-color: var(--week-bg-color);
		color: var(--week-color);
		font-size: var(--week-font-size);
		text-align: center;
		box-shadow: 0px 0px 0px 1px var(--calendar-border-color);
	}

	.calendar-table-head>td {
		width: 14.28%;
	}

	.calendar-table-row {
		/* height: var(--row-height); */
	}

	.calendar-table-row>td {
		height: var(--row-height);
		padding: 0px;

	}


	.date-cell {
		display: flex;
		flex-direction: column;
		justify-content: var(--date-label-v);
		/* 	flex-wrap: wrap;
		place-content: flex-start;
		align-items: var(--date-label-v); */

		width: var(--date-cell-width);

		height: 100%;
		/* padding-bottom: 5px; */
		cursor: pointer;
		overflow: hidden;
		box-shadow: 0px 0px 0px 1px var(--calendar-border-color);
		/* 用阴影替代表格,使数据项能浮于线条上 */
	}

	.date-cell:hover {
		background-color: rgba(250, 250, 210, 1);
	}


	.date-cell-in {
		background-color: var(--date-bgcolor-in);
	}

	.date-cell-out {
		background-color: var(--date-bgcolor-out);
	}

	.date-cell-current {
		background-color: var(--calendar-current-color);
	}

	.date-label {
		/* 	width: 100%; */
		padding: 2px;
		text-align: var(--date-align);
		font-size: var(--date-font-size);
	}

	.date-label-in {

		color: var(--date-color-in)
	}

	.date-label-out {

		color: var(--date-color-out)
	}

	.date-badge {
		/* font-size: 10px; */
		top: 10px;


		/* 	margin-left: -40px;
		margin-bottom: -15px; */
	}

	.date-data-item {
		display: flex;
		align-items: center;

		/* width: calc(100% - 10px)); */
		/* 要使省略号生效，必须给容器设置一个固定宽度。如果容器宽度设置为自适应或百分比宽度，text-overflow:ellipsis属性将不起作用。
		 */
		/* height: 20px; */
		margin-top: 2px;
		margin-bottom: 2px;
		padding: 2px 5px 2px 5px;
		font-size: 12px;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		/* background-color: #ff00ff; */

	}

	.date-data-item i {
		margin-right: 5px;
	}

	.date-data-item-all {
		/* width: calc(100% - 30px)); */
		margin-left: 5px;
		margin-right: 5px;
		border: solid 1px #cccccc;

		border-radius: 5px 5px 5px 5px;
	}

	.date-data-item-start {
		/* width: calc(100% - 20px)); */
		margin-left: 5px;
		border-radius: 5px 0px 0px 5px;

	}

	.date-data-item-end {
		/* width: calc(100% - 20px); */
		margin-right: 5px;
		border-radius: 0px 5px 5px 0px;
	}

	/* 
	:deep(.el-calendar__body) {
		padding: 0px 0px 0px;
	}

	:deep(.el-calendar-table>thead) {
		background-color: var(--week-bg-color);
	}

	:deep(.el-calendar-table__row>td) {
		height: var(--row-height);
	}

	:deep(.el-calendar-table .el-calendar-day) {
		padding: 0px;
		height: 100%;
	} */
</style>