<template>
	<div style="width:100%;margin-bottom: 5px;">
		<div v-show="! isAdvance" class="items-bar" style="--item-width:100px;">
			<template v-for="item in queryItems">
				<template v-if="item.normal && item.show !==false">

					<div class="item-label">{{item.label}}</div>
					<div :class="item.typeGroup==='time'?'item-input-time':'item-input'"
						:style="{'--item-width':item.width}">
						<template v-if="item.input==='input'">
							<template v-if="item.typeGroup==='num'">
								<el-input v-model="item.value1" type="number" size="default"
									:placeholder="compareTypes[item.compare].label" clearable />
							</template>
							<template v-else-if="item.typeGroup==='text'">
								<el-input v-model="item.value1" type="text" size="default"
									:placeholder="compareTypes[item.compare].label" clearable />
							</template>
							<template v-else-if="item.typeGroup==='time'">
								<el-date-picker v-if="item.unionType==='datetime'" v-model="item.value1" type="datetime"
									value-format="YYYY-MM-DD HH:mm:ss" size="default"
									:placeholder="compareTypes[item.compare].label" />
								<el-date-picker v-else-if="item.unionType==='date'" v-model="item.value1" type="date"
									value-format="YYYY-MM-DD HH:mm:ss" size="default"
									:placeholder="compareTypes[item.compare].label" />
								<el-time-picker v-else-if="item.unionType==='time'" v-model="item.value1" type="time"
									value-format="HH:mm:ss" size="default"
									:placeholder="compareTypes[item.compare].label" />

							</template>

							<template v-else>
								未知数据类型[{{item.typeGroup}}]
							</template>
						</template>
						<template v-else-if="item.input==='user'">
							<orguserdialog :user-item="item.user" />
						</template>
						<template v-else-if="item.input==='org'">
							<div style="width:70px;">
								<select v-model="item.compare" style="width: 100%;" class="input-text">
									<option value="eq">等于</option>
									<option value="in">包含于</option>
								</select>
							</div>
							<div style="width:calc(100% - 72px);margin-left: 2px;">
								<orgdialog :org-node="item.org" :tree-nodes="project.orgTree" />
							</div>
						</template>
						<template v-else-if="item.input==='single'">
							<el-radio-group v-model="item.value1">
								<el-radio v-for="option in enumMap[item.enum]" :key="option.value"
									:value="option.value">
									{{option.label}}
								</el-radio>
							</el-radio-group>

						</template>
						<template v-else-if="item.input==='select1'">
							<el-select v-model="item.value1" class="filter-select" clearable placeholder="请选择">
								<el-option v-for="option in enumMap[item.enum]" :key="option.value"
									:label="option.label" :value="option.value" />
							</el-select>

						</template>
						<template v-else-if="item.input==='multi'">
							<el-checkbox-group v-model="item.multi">
								<el-checkbox v-for="option in enumMap[item.enum]" :key="option.value"
									:label="option.value">
									{{option.label}}
								</el-checkbox>
							</el-checkbox-group>
						</template>
						<template v-else-if="item.input==='select2'">
							<el-select v-model="item.multi" class="filter-select" clearable placeholder="请选择" multiple
								collapse-tags collapse-tags-tooltip :max-collapse-tags="3">
								<el-option v-for="option in enumMap[item.enum]" :key="option.value"
									:label="option.label" :value="option.value" />
							</el-select>

						</template>

						<template v-else-if="item.input==='switch'">

							<el-switch v-if="enumMap[item.enum] && enumMap[item.enum].length>1" v-model="item.value1"
								inline-prompt :width="60" active-color="#13ce66" inactive-color="#C0C4CC"
								:active-text="enumMap[item.enum][0].label" :inactive-text="enumMap[item.enum][1].label"
								:active-value="enumMap[item.enum][0].value"
								:inactive-value="enumMap[item.enum][1].value" size="default">

							</el-switch>
							<el-switch v-else v-model="item.value1" inline-prompt :width="60" active-color="#13ce66"
								inactive-color="#C0C4CC" active-text="是" inactive-text="否" active-value="Y"
								inactive-value="N" size="default" />


						</template>

					</div>
					<template v-if="item.compare==='bt'">
						<span class="item-split">-</span>
						<div :class="item.typeGroup==='time'?'item-input-time':'item-input'">
							<template v-if="item.typeGroup==='num'">
								<el-input v-model="item.value2" type="number" size="default" clearable />
							</template>
							<template v-else-if="item.typeGroup==='text'">
								<el-input v-model="item.value2" type="text" size="default" clearable />
							</template>
							<template v-else-if="item.typeGroup==='time'">
								<el-date-picker v-if="item.unionType==='datetime'" v-model="item.value2" type="datetime"
									value-format="YYYY-MM-DD HH:mm:ss" size="default" />
								<el-date-picker v-else-if="item.unionType==='date'" v-model="item.value2" type="date"
									value-format="YYYY-MM-DD" size="default" />
								<el-time-picker v-else-if="item.unionType==='time'" v-model="item.value2" type="time"
									value-format="HH:mm:ss" size="default" />

							</template>
						</div>
					</template>
				</template>
			</template>

			<div v-if="foldButton && buttons.length>1" class="button-item">
				<el-dropdown trigger="click" split-button :type="buttons[0].type" @click="menuClick(buttons[0].key)"
					@command="menuClick" style="width: unset;">
					<i :class="buttons[0].icon"></i> {{buttons[0].text}}
					<template #dropdown>
						<el-dropdown-menu>
							<template v-for="(button,index) in buttons">
								<template v-if="index>0">
									<el-dropdown-item :command="button.key">
										<span>
											<i :class="button.icon"></i>
											{{button.text}}
										</span>
									</el-dropdown-item>
								</template>

							</template>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<template v-else>
				<el-button v-for="button in buttons" :type="button.type" size="default" @click="ExButtonClick(button)"
					:disabled="button.enabled=='N'" :loading="button.loading=='Y'" class="button-item">
					<i :class="button.icon"></i>
					{{button.text}}
				</el-button>
			</template>


		</div>

		<div v-show="selectHead" class="head-select">
			<el-checkbox-group v-model="headCheckList">
				<template v-for="item in querySet.headItems">
					<el-checkbox v-if="item.mode !== 'hide'" :label="item.id">{{item.label}}</el-checkbox>
				</template>
			</el-checkbox-group>
			<div style="text-align: center;margin-top: 20px;">
				<el-button type="default" size="default" @click="selectHead=false"> 取消
				</el-button>
				<el-button type="primary" size="default" @click="updateVisibleHeads"> 确定
				</el-button>
			</div>
		</div>
		<div v-show="selectOrder" class="head-select">
			<div style="display: flex; justify-content: space-between; ;height: 200px;" class="noselect">
				<div style="width: 50%;border:inset 2px #cccccc;overflow: auto;background-color: #ffffcf;"
					title="点击字段项添加到排序集合">
					<template v-for="item in orderList">
						<el-tag class="order-item" @click="chooseOrderItem(item,true)">{{item.label}}</el-tag>
					</template>
				</div>
				<div style="width:calc(50% - 12px);overflow:auto;border:inset 1px #cccccc;">
					<table border="1" class="table-set">
						<tr class="tabletop">
							<td>
								字段
							</td>
							<td style="width:80px;">
								方向
							</td>

							<td style="width: 200px;">
								操作
							</td>

						</tr>
						<tr v-for="item in orderSels">
							<td>{{item.label}}</td>
							<td>
								<select v-model="item.order" style="width: 100%;" class="input-text">
									<option value="asc">升序</option>
									<option value="desc">降序</option>
								</select>
							</td>
							<td style="text-align: center;">
								<el-button-group size="small">
									<el-button type="primary" @click="setOrderItem(item,'up')"><i
											class="far fa-arrow-up"></i>上移
									</el-button>
									<el-button type="primary" @click="setOrderItem(item,'down')"><i
											class="far fa-arrow-down"></i>下移
									</el-button>
									<el-button type="danger" @click="setOrderItem(item,'del')"><i
											class="fas fa-times"></i>移除
									</el-button>

								</el-button-group>

							</td>

						</tr>
					</table>
				</div>

			</div>


			<div style="text-align: center;margin-top: 20px;">
				<el-button type="default" size="default" @click="selectOrder=false"> 取消
				</el-button>
				<el-button type="primary" size="default" @click="updateOrderItems"> 确定
				</el-button>
			</div>
		</div>

		<div v-show="isAdvance" style="overflow: auto;">
			<div style="min-width: 800px;">
				<titlebar title="自定义高级查询" icon="far fa-layer-group">
					<el-button-group size="default">
						<el-button type="primary" @click="isAdvance=false"><i class="far fa-undo"></i>返回</el-button>
						<el-button type="primary" @click="addFilter(-1)"><i class="fas fa-plus"></i>新增</el-button>
						<el-button type="danger" @click="deleteFilter(-1)"><i class="fas fa-times"></i>清空</el-button>
						<el-button type="success" @click="getQuery"><i class="fas fa-search"></i>查询</el-button>
					</el-button-group>
				</titlebar>
				<table border="1" class="table-set">
					<tr class="tabletop">
						<td style="width:100px;">左括号</td>

						<td style="width: 150px;">
							数据名称
						</td>
						<td style="width: 120px;">
							比较逻辑
						</td>
						<td>
							条件值
						</td>
						<td style="width:100px;">
							右括号
						</td>
						<td style="width: 80px;">
							连接方式
						</td>

						<td style="width: 100px;">
							删除
						</td>
					</tr>
					<tr v-for="(filter,index) in filters" class="table-row">
						<td>
							<div class="brackets" @click="addBracket(filter,'left',1)" title="点击添加左括号">
								<span v-if="filter.left.length===0"> &nbsp;</span>
								<div v-for="bracket in filter.left" @click.stop="addBracket(filter,'left',0)"
									title="点击删除当前括号">{{bracket}}</div>
							</div>

						</td>

						<td>
							<select v-model="filter.queryItem" style="width: 100%;" class="input-text">
								<option v-for="item in  queryItemsPlus" :value="item">{{item.label}}</option>

							</select>
						</td>
						<td>
							<select v-model="filter.compare" style="width: 100%;" class="input-text">
								<template v-if="filter.queryItem.input==='input'">
									<option value="eq">等于</option>
									<option value="ne">不等于</option>
									<option value="lg">大于</option>
									<option value="lgeq">大于等于</option>
									<option value="lt">小于</option>
									<option value="lteq">小于等于</option>
									<template v-if="filter.queryItem.typeGroup==='text'">
										<option value="start">开头</option>
										<option value="contain">包含</option>
										<option value="end">结尾</option>
									</template>
								</template>
								<template v-else-if="filter.queryItem.input==='user'">
									<option value="eq">等于</option>
									<option value="ne">不等于</option>
								</template>
								<template v-else-if="filter.queryItem.input==='org'">
									<option value="eq">等于</option>
									<option value="ne">不等于</option>
									<option value="in">包含于</option>
									<option value="ni">不包含于</option>
								</template>
								<template v-else-if="filter.queryItem.input==='switch'">
									<option value="eq">等于</option>
									<option value="ne">不等于</option>
								</template>
								<template
									v-else-if="filter.queryItem.input==='single' || filter.queryItem.input==='select1'">
									<option value="eq">等于</option>
									<option value="ne">不等于</option>
								</template>
								<template
									v-else-if="filter.queryItem.input==='multi' || filter.queryItem.input==='select2'">
									<option value="in">包含于</option>
									<option value="ni">不包含于</option>
								</template>

							</select>
						</td>
						<td>
							<template v-if="filter.queryItem.input==='input'">
								<template v-if="filter.queryItem.typeGroup==='num'">
									<el-input v-model="filter.value" type="number" size="default" clearable />
								</template>
								<template v-else-if="filter.queryItem.typeGroup==='text'">
									<el-input v-model="filter.value" type="text" size="default" clearable />
								</template>
								<template v-else-if="filter.queryItem.typeGroup==='time'">
									<el-date-picker v-if="filter.queryItem.unionType==='datetime'"
										v-model="filter.value" value-format="YYYY-MM-DD HH:mm:ss" style="width:100%;"
										type="datetime" size="default" />
									<el-date-picker v-else-if="filter.queryItem.unionType==='date'"
										v-model="filter.value" value-format="YYYY-MM-DD" style="width:100%;" type="date"
										size="default" />
									<el-time-picker v-else-if="filter.queryItem.unionType==='time'"
										v-model="filter.value" value-format="HH:mm:ss" style="width:100%;" type="time"
										size="default" />

								</template>
							</template>
							<template v-else-if="filter.queryItem.input==='user'">
								<orguserdialog :user-item="filter.user" />
							</template>
							<template v-else-if="filter.queryItem.input==='org'">
								<orgdialog :org-node="filter.org" :tree-nodes="project.orgTree" />

							</template>
							<template v-else-if="filter.queryItem.input==='switch'">
								<el-select v-model="filter.value" class="filter-select" clearable placeholder="请选择">
									<el-option v-for="option in enumMap[filter.queryItem.enum]" :key="option.value"
										:label="option.label" :value="option.value" />
								</el-select>
							</template>
							<template
								v-else-if="filter.queryItem.input==='single' || filter.queryItem.input==='select1'">
								<el-select v-model="filter.value" class="filter-select" clearable placeholder="请选择">
									<el-option v-for="option in enumMap[filter.queryItem.enum]" :key="option.value"
										:label="option.label" :value="option.value" />
								</el-select>

							</template>
							<template
								v-else-if="filter.queryItem.input==='multi' || filter.queryItem.input==='select2'">
								<el-checkbox-group v-model="filter.multi" class="filter-check">
									<el-checkbox v-for="option in enumMap[filter.queryItem.enum]" :key="option.value"
										:label="option.value">
										{{option.label}}
									</el-checkbox>
								</el-checkbox-group>
							</template>
						</td>


						<td>
							<div class="brackets" style="flex-direction: row-reverse;"
								@click="addBracket(filter,'right',1)" title="点击添加右括号">
								<span v-if="filter.right.length===0"> &nbsp;</span>
								<!-- <span v-for="bracket in filter.right" @click.stop="addBracket(filter,'right',0)"
								title="点击删除当前括号">
								{{bracket}}
							</span> -->
								<div v-for="bracket in filter.right" @click.stop="addBracket(filter,'right',0)"
									title="点击删除当前括号">{{bracket}}</div>
							</div>
						</td>
						<td>
							<select v-model="filter.connect" style="width: 100%;" class="input-text">
								<option value="and">并且</option>
								<option value="or">或者</option>
							</select>
						</td>
						<td style="text-align: center;">
							<el-button type="danger" size="small" @click="deleteFilter(index)"><i
									class="fas fa-minus"></i></el-button>
							<el-button type="success" size="small" @click="addFilter(index)"><i
									class="fas fa-plus"></i></el-button>
						</td>
					</tr>
				</table>
				<div show="isAdvance" class="queryInfo">
					{{queryInfo}}

				</div>

			</div>
		</div>

	</div>
</template>

<script>
	import orgDialog from '../../../../comm/treedialog/OrgDialog.vue'
	//import roleDialog from '../treedialog/RoleDialog.vue'
	import orgUserDialog from '../../../../comm/treedialog/OrgUserDialog.vue'
	export default { //'orderItems','headList','baseSql','itemWidth',
		props: ['project', 'page', 'reportModel', 'querySet', 'autoQuery', 'params', 'allowAdvance',
			'allowHead',
			'allowOrder', 'getCustomSql', 'buttons', 'isedit', 'formDo', 'foldButton'
		],
		emits: ['query', 'setorder', 'buttonclick', ], //'doform'
		data() {
			return {
				isAdvance: false,
				filters: [],
				queryInfo: '',
				selectHead: false,
				selectOrder: false,
				orderList: [],
				orderSels: [],
				headCheckList: [], //可显示的表格列
				compareTypes: {
					eq: {
						label: '等于',
						express: '='
					},
					ne: {
						label: '不等于',
						express: '!='
					},
					lg: {
						label: '大于',
						express: '>'
					},
					lgeq: {
						label: '大于等于',
						express: '>='
					},
					lt: {
						label: '小于',
						express: '<'
					},
					lteq: {
						label: '小于等于',
						express: '<='
					},
					bt: {
						label: '介于',
						express: 'between'
					},
					start: {
						label: '开头',
						express: 'like'
					},
					contain: {
						label: '包含',
						express: 'like'
					},
					end: {
						label: '结尾',
						express: 'like'
					},
					in: {
						label: '包含于',
						express: 'in'
					},
					ni: {
						label: '不包含于',
						express: 'not in'
					},

				}

			}
		},

		methods: {
			/* doForm(type) {
				this.$emit('doform', type)
			}, */
			initFilter() { //重置查询条件后重新查询
				for (let item of this.queryItems) {
					item.multi = item.initMulti
					item.value1 = item.init1
					item.value2 = item.init2
				}
				this.getQuery()
			},
			menuClick(command) {
				this.ExButtonClick({
					key: command
				})
			},
			ExButtonClick(button) {
				if (this.isedit) {
					return
				}

				if (button.key === 'query') {
					this.getQuery()
				} else if (button.key === 'super') {
					this.setAdvance()
				} else if (button.key === 'head') {
					this.setHeads()
				} else if (button.key === 'order') {
					this.setOrder()
				} else if (button.key === 'init') {
					this.initFilter()
				} else {
					this.$emit('buttonclick', button)
				}

			},
			updateOrderItems() {
				this.$emit('setorder', this.orderSels)
				this.selectOrder = false
				this.getQuery()
			},
			setOrderItem(item, tag) {
				let idx = this.orderSels.indexOf(item)
				if (idx < 0) {
					return
				}
				if (tag == 'up') {
					if (idx > 0) {
						this.orderSels.splice(idx, 1)
						this.orderSels.splice(idx - 1, 0, item)
					}
				} else if (tag == 'down') {
					if (idx < this.orderSels.length - 1) {
						this.orderSels.splice(idx, 1)
						this.orderSels.splice(idx + 1, 0, item)
					}
				} else if (tag == 'del') {
					this.orderSels.splice(idx, 1)
					this.orderList.push(item)
				}

			},
			chooseOrderItem(item, tag) {
				let idx = this.orderList.indexOf(item)
				if (idx < 0) {
					return
				}
				this.orderList.splice(idx, 1)
				this.orderSels.push(item)

			},
			updateVisibleHeads() {
				for (let item of this.querySet.headItems) {
					if (this.headCheckList.indexOf(item.id) > -1) {
						item.show = 'show'
					} else {
						item.show = 'hide'
					}
				}
				this.selectHead = false
			},
			setHeads() {
				if (this.selectHead) {
					this.selectHead = false
					return
				}
				let list = []
				for (let item of this.querySet.headItems) {
					if (item.show === 'show' && item.mode !== 'hide') {
						list.push(item.id)
					}
				}
				this.headCheckList = list
				this.selectOrder = false
				this.selectHead = true

			},
			setOrder() {
				if (this.selectOrder) {
					this.selectOrder = false
					return
				}

				let orderList = []
				let orderSels = [].concat(this.querySet.orderItems)
				for (let item of this.querySet.headItems) {
					if (orderSels.indexOf(item) < 0) {
						orderList.push(item)
					}
				}
				this.orderList = orderList
				this.orderSels = orderSels
				this.selectHead = false
				this.selectOrder = true
			},
			getDataTimeText(type, value) {
				let ts = ''
				if (type === 'datetime') {
					ts = this.$logic.date.toDateTimeString(value)
				} else if (type === 'date') {
					ts = this.$logic.date.toDateString(value)
				} else if (type === 'time') {
					ts = this.$logic.date.toTimeString(value)
				} else {
					this.$logic.tip.error('不存在的数据类型：' + type)
					throw new Error('不存在的数据类型：' + type)
				}
				return ts
			},
			setQueryInfo() {
				let info = ""
				let connType = ''
				for (let i = 0; i < this.filters.length; i++) {
					let item = this.filters[i]
					let compare = this.compareTypes[item.compare]
					let value = item.value === null ? '' : item.value
					if (item.queryItem.input === 'input') {
						if (item.queryItem.typeGroup === 'time') {
							value = value === '' ? '?' : value
							value = "#" + this.getDataTimeText(item.queryItem.unionType, value) + "#"
						} else if (item.queryItem.typeGroup === 'text') {
							value = "'" + value + "'"
						} else if (item.queryItem.typeGroup === 'num') {
							value = value === '' ? '?' : value
						}
					} else if (item.queryItem.input === 'user') {
						value = '[' + item.user.userName + ']'
					} else if (item.queryItem.input === 'org') {
						value = '[' + item.org.textPath + ']'
					} else if (item.queryItem.input === 'switch') {
						value = '[' + item.value + ']'
						let options = this.enumMap[item.queryItem.enum]
						if (!options || options.length < 2) {
							options = [{
								label: '是',
								value: 'Y'
							}, {
								label: '否',
								value: 'N'
							}]
						}
						for (let option of options) {
							if (value == option.value) {
								value = '[' + option.label + ']'
								break
							}
						}
					} else if (item.queryItem.input === 'single' || item.queryItem.input === 'select1') {
						value = '[' + item.value + ']'
						for (let option of this.enumMap[item.queryItem.enum]) {
							if (value == option.value) {
								value = '[' + option.label + ']'
								break
							}
						}
					} else if (item.queryItem.input === 'multi' || item.queryItem.input === 'select2') {
						value = item.multi
						let rs = '{'
						if (value) {
							for (let data of value) {
								for (let option of this.enumMap[item.queryItem.enum]) {
									if (data == option.value) {
										if (rs.length > 1) {
											rs = rs + ','
										}
										rs = rs + option.label
										break
									}
								}
							}
						} else {}
						value = rs + '}'
					}


					for (let bs of item.left) {
						info = info + bs + ' '
					}
					let sqlPart = ' ' + item.queryItem.label + ' ' +
						compare.label + ' ' + value + ' '
					info = info + sqlPart
					for (let bs of item.right) {
						info = info + bs + ' '
					}

					connType = item.connect == 'and' ? '并且' : '或者'
					if (i < this.filters.length - 1) {
						info = info + connType + ' '
					}
				}
				this.queryInfo = info
			},
			getQuery() {
				if (!this.querySet.sql) {
					return
				}

				let sql = ''
				let params = []
				let user = this.$logic.getUser()
				let fixParams = {}
				let virtualParams = {}
				let normalParams = {} //用户输入的普通查询条件
				//提取固定条件项和虚拟条件项的值
				for (let item of this.querySet.queryItems) {

					if (item.normal) { //常规查询
						let fieldName = item.queryType == 'replace' || item.queryType == 'virtual' ? item.fieldName : item
							.table + '.' + item.fieldName
						if (item.compare === 'bt') {
							normalParams[fieldName] = [item.value1, item.value2]
						} else {
							normalParams[fieldName] = item.value1
						}
						if (item.queryType == 'replace') {
							fixParams[item.fieldName] = item.value1
						} else if (item.queryType == 'virtual') {
							virtualParams[item.fieldName] = item.value1
						}

					}

				}
				let customSql = this.getCustomSql(fixParams, virtualParams)
				if (customSql === false) { //返回False取消本次查询
					return
				}

				if (this.isAdvance) { //高级查询
					let idx = 0
					let stack = []
					for (let i = 0; i < this.filters.length; i++) {
						let item = this.filters[i]
						idx++
						let compare = this.compareTypes[item.compare]
						let value = item.value
						let sqlPart = item.queryItem.table + '.' + item.queryItem.fieldName + ' ' +
							compare.express + (compare.express == 'in' || compare.express == 'not in' ? ' ( ? ) ' : ' ? ')
						for (let bs of item.left) {
							sqlPart = sqlPart + bs + ' ' //sql = sql + bs + ' '
							stack.push(bs)
						}
						//sql = sql + sqlPart
						if (item.queryItem.input === 'input') {
							switch (item.queryItem.typeGroup) {
								case 'text':
									value = value === null ? '' : value //字符串类型空值转空字符串，问题：不能比较null值

									value = item.compare === 'start' ? value + '%' : item.compare === 'contain' ? '%' +
										value + '%' : item.compare === 'end' ? '%' + value : value
									//	params.push(value)
									break
								case 'num':
									if (value === null || value === '') {
										this.$logic.tip.error('第' + idx + '行，' + item.queryItem.label + '未输入数值')
										return
									}
									//	params.push(value)
									break
								case 'time':
									if (value === null || value === '') {
										this.$logic.tip.error('第' + idx + '行，' + item.queryItem.label + '未输入值')
										return
									}
									value = this.getDataTimeText(item.queryItem.unionType, value)

									//	params.push(value)
									break;
								default:
									this.$logic.tip.error('未知的数据类型：' + item.queryItem.typeGroup)
									return
									break
							}
						} else if (item.queryItem.input === 'user') {
							value = item.user.userId
							//	params.push(value)
						} else if (item.queryItem.input === 'org') {
							//	console.log(this.project.orgTree)
							//let subs = this.project.orgTree.getSubNodes(item.org.id)

							if (compare.express == 'in' || compare.express == 'not in') { //找子部门集合
								let ids = this.getSubTreeIds(this.project.orgTree, item.org.id)
								value = ids
							} else {
								value = item.org.id
							}

							//	params.push(value)

						} else if (item.queryItem.input === 'single' || item.queryItem.input === 'select1' || item
							.queryItem.input === 'switch') {
							value = item.value
							//	params.push(value)

						} else if (item.queryItem.input === 'multi' || item.queryItem.input === 'select2') {
							value = item.multi ? item.multi : []
							//	params.push(value)
						}


						for (let bs of item.right) {
							sqlPart = sqlPart + bs + ' ' //sql = sql + bs + ' '
							if (stack.length > 0) {
								stack.pop()
							} else {
								this.$logic.tip.error('左右括号关系不匹配，请确认')
								return
							}
						}
						if (compare.express == 'not in') { //空结果忽略此条件
							if (value.length == 0) {
								continue
							}
						}


						let connType = item.connect
						if (i < this.filters.length - 1) {
							sqlPart = sqlPart + connType + ' ' //sql = sql + connType + ' '
						}
						sql = sql + sqlPart
						params.push(value)


					}
					if (stack.length > 0) {
						this.$logic.tip.error('左右括号不匹配，请确认')
						return
					}

				} else { //普通查询

					for (let item of this.querySet.queryItems) { //虚拟查询项不作处理
						if (!item.normal || item.queryType == 'replace' || item.queryType == 'virtual') {
							continue
						}

						if (item.input === 'input') {
							if (item.compare === 'bt' && item.value1 === null && item.value1 === '' && item.value2 ===
								null &&
								item.value2 === '') {
								continue
							} else if (item.value1 === null || item.value1 === '') {
								continue
							}
						} else if (item.input === 'user' && !item.user.userId) {
							continue
						} else if (item.input === 'org' && !item.org.id) {
							continue
						} else if (item.input === 'switch' && !item.value1) {
							continue
						} else if ((item.input === 'single' || item.input === 'select1') && !item.value1) {
							continue
						} else if ((item.input === 'multi' || item.input === 'select2') && (!item.multi || item.multi
								.length < 1)) { //都没选忽略条件
							continue
						}

						let field = item.table + '.' + item.fieldName
						let v1 = item.value1
						let v2 = item.value2
						if (item.typeGroup === 'time') {
							v1 = this.getDataTimeText(item.unionType, v1)
							v2 = this.getDataTimeText(item.unionType, v2)
						}

						let compare = this.compareTypes[item.compare]
						if (item.input === 'input') {


							switch (item.compare) {
								case 'start':
									sql = sql + ' and ' + field + ' ' + compare.express + ' ?'
									params.push(v1 + '%')
									break
								case 'contain':
									sql = sql + ' and ' + field + ' ' + compare.express + ' ?'
									params.push('%' + v1 + '%')
									break
								case 'end':
									sql = sql + ' and ' + field + ' ' + compare.express + ' ?'
									params.push('%' + v1)
									break
								case 'bt':
									if (v1 !== null && v1 !== '' && v2 !== null && v2 !== '') {
										sql = sql + ' and ' + field + ' ' + compare.express + ' ? and ?'
										params.push(v1)
										params.push(v2)
									} else {
										if (v1 !== null && v1 !== '') {
											sql = sql + ' and ' + field + ' >= ?'
											params.push(v1)
										}
										if (v2 !== null && v2 !== '') {
											sql = sql + ' and ' + field + ' <= ?'
											params.push(v2)
										}

									}

									break
								default:
									sql = sql + ' and ' + field + ' ' + compare.express + '?'
									params.push(v1)
									break

							}
						} else if (item.input === 'user') {
							if (item.user.userId) {
								sql = sql + ' and ' + field + '= ?'
								params.push(item.user.userId)
							}

						} else if (item.input === 'org') {

							if (item.org.id) {
								if (compare.express === 'in' || compare.express === 'not in') { //找子部门集合
									let ids = this.getSubTreeIds(this.project.orgTree, item.org.id)
									sql = sql + ' and ' + field + ' ' + compare.express + ' ( ? ) '
									params.push(ids)
								} else {
									sql = sql + ' and ' + field + '= ?'
									params.push(item.org.id)
								}

							}

						} else if (item.input === 'single' || item.input === 'select1' || item.input === 'switch') {
							if (item.value1) {
								sql = sql + ' and ' + field + '= ?'
								params.push(item.value1)
							}

						} else if (item.input === 'multi' || item.input === 'select2') {
							if (item.multi && item.multi.length > 0) {
								sql = sql + ' and ' + field + ' in ( ? )'
								params.push(item.multi)
							}

						}


						//console.log(item)
					}
					if (sql.length > 0) {
						sql = sql.substring(4)
					}
					//附加权限过滤
					let userId = user.id


					if (this.querySet.rangeUse && this.querySet.rangeField && this.querySet.rangeItems && this.querySet
						.rangeItems.length > 0) {
						//查出权限配置中所有的角色及子角色
						let roleMap = {}
						for (let role of this.querySet.rangeItems) {
							let roles = this.project.roleTree.getSubNodes(role.roleId)
							for (let item of roles) {
								roleMap[item.id] = true
							}
						}
						//查出用户所有的角色，取存在于权限角色中的角色
						let userRoles = {}
						for (let item of this.project.roleUsers) {
							if (item.userId == userId) {
								if (roleMap[item.roleId]) { //存在于权限角色中，加入此角色及子角色
									let roles = this.project.roleTree.getSubNodes(item.roleId)
									for (let role of roles) {
										userRoles[item.roleId] = true
									}
								}
							}
						}
						//获取用户角色所有关联的组织
						let roleOrgMap = {}
						for (let item of this.project.roleOrgs) {
							if (userRoles[item.roleId]) {
								roleOrgMap[item.orgId] = true
							}
						}
						//获取组织的子集
						let orgMap = {}
						let count = 0
						for (let orgId in roleOrgMap) {
							let orgs = this.project.orgTree.getSubNodes(orgId)
							for (let item of orgs) {
								orgMap[item.id] = true
								count++
							}
						}

						if (count > 0) {
							let ids = ''
							for (let id in orgMap) {
								if (ids.length > 0) {
									ids = ids + ','
								}
								ids = ids + id
							}
							//改为替换静态条件的方式
							fixParams[this.querySet.rangeField] = ids

							/* let sqlEx = this.querySet.rangeField + ' in ( ' + ids + ' )'
							if (sql.length > 2) {
								sql = ' ( ' + sql + ' ) and ( ' + sqlEx + ' ) '
							} else {
								sql = sqlEx
							} */
						} else { //没有符合授权的条件情况下 
							//忽略所有其实条件,直接查不出结果
							//sql = '1>2'
							//fixParams[this.querySet.rangeField] = null //清空，使用默认值
						}
					}


				}
				let os = ''
				for (let item of this.querySet.orderItems) {
					if (os.length > 0) {
						os = os + ' , '
					}
					os = os + item.fieldName + ' ' + item.order
				}

				let baseSql = customSql || this.querySet.sql
				/* 				if(!this.isedit && (baseSql==null || baseSql.length<5)){//没有设置过SQL不作处理
								console.log('YY',this.isedit)
									return
								} */

				//console.log(user)
				let queryParams = { //用户属性的内置变量已经转到db中自动处理//查询参数初始化系统内置变量，如变量相同，以传入的为准
					/*	$userId: user.id,
						$leaderId: user.leaderId,
						$orgId: user.orgId
						*/
				}

				for (let key in this.params) {
					queryParams[key] = this.params[key]
				}
				for (let key in fixParams) {
					queryParams[key] = fixParams[key]
				}
				let paramSql = this.$logic.db.getParamSql(baseSql, queryParams, this.page, this.reportModel)
				let sqlParts = this.$logic.db.explainSql(paramSql)
				//如果含有union,只处理第一部分的SQL
				let sqlPart = sqlParts[0]
				if (sqlParts.length == 1) { //单语句时才允许动态叠加查询逻辑
					if (sql.length > 1) { //存在动态查询逻辑

						if (sqlPart.where.idx > 0) {
							sqlPart.where.content = '(' + sqlPart.where.content + ') and (' + sql + ')'
						} else {
							sqlPart.where.content = sql
						}

					}

				}
				if (os.length > 0) { //动态排序逻辑 ,兼容union,规则放在最后一条语句 后面
					sqlParts[sqlParts.length - 1].order.content = ' by ' + os
				}
				sql = this.$logic.db.getSql(sqlParts) //[sqlPart]
				//console.log(sql)
				this.$emit('query', sql, params)
			},
			getSubTreeIds(tree, nodeId) {
				let ids = []
				//let rs=''
				if (nodeId) {
					let nodes = tree.getSubNodes(nodeId)
					for (let node of nodes) {
						ids.push(parseInt(node.id)) //转数值型node.id
						/* if(rs.length>0){
							rs=rs+','
						}
						rs=rs+node.id */
					}
				}
				if (ids.length < 1) { //没有值时传空值内容，以防SQL异常
					ids.push(null)
				}
				return ids
			},
			addBracket(filter, key, tag) { //添加括号
				if (tag > 0) {
					filter[key].push(key === 'left' ? '(' : ')')
				} else {
					filter[key].splice(0, 1)
				}
			},
			addFilter(index = -1) {
				if (this.queryItemsPlus.length < 1) {
					this.$logic.tip.error('没有可查询字段，请先设置报表数据集')
					return
				}
				let item = {
					left: [],
					queryItem: this.queryItemsPlus[0],
					compare: 'eq',
					value: null,
					user: {
						userId: null,
						userName: ''
					},
					org: {
						id: null,
						textPath: ''
					},
					multi: [],
					right: [],
					connect: 'and'

				}
				if (index > -1) {
					this.filters.splice(index + 1, 0, item)
				} else {
					this.filters.push(item)
				}

			},
			deleteFilter(idx) {
				if (idx > -1) {
					this.filters.splice(idx, 1)
				} else {
					this.filters = []
				}
			},
			setAdvance() {
				if (this.filters.length < 1) {
					this.addFilter(-1)
				}
				this.isAdvance = true

			},
			initEnumOptins() {
				if (this.reportModel && this.reportModel.$dataLoad) {
					for (let en of this.querySet.enumItems) {
						if (en.inputOption && en.inputOption.jsonArray.load == 'Y') {

							en.$dataLoad = () => { //调用父组件的方法加载数据，在BaseElement中
								this.reportModel.$dataLoad(null, (dataList) => {
									en.inputOption.jsonArray.list = dataList
								}, en)
							}
							en.$dataLoad()

						}
					}
				}

			}

		},
		computed: {
			queryItems() {
				return this.querySet.queryItems
			},
			queryItemsPlus() { //高级查询
				let list = []
				for (let item of this.querySet.queryItems) {
					if (item.queryType == 'replace' || item.queryType == 'virtual') {
						continue
					}
					list.push(item)
				}
				return list

			},
			enumMap() {
				let map = {}
				for (let en of this.querySet.enumItems) {
					let list = en.inputOption ? en.inputOption.jsonArray.list : []
					map[en.id] = list
				}
				return map

			},
			/* queryStyle() {
				let css = {

					'--item-width': this.itemWidth
				}
				return css
			} */
		},
		watch: {

			filters: {
				deep: true,
				handler: function(newValue, oldValue) {
					this.setQueryInfo()
				}

			}
		},
		components: {
			orgdialog: orgDialog,
			orguserdialog: orgUserDialog

		},
		mounted() {
			this.$nextTick(() => {
				if (this.isedit) {
					this.$emit('query', '', [])
				} else {
					this.initEnumOptins() //初始化加载动态选项值
					if (this.autoQuery == 'Y') {
						this.getQuery()
					}

				}
			})

		},
		created() {




		}
	}
</script>

<style scoped>
	.items-bar {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

	}

	.item-label {
		/* 	width: var(--label-width); */
		/* 	text-align: var(--label-align); */
		margin-top: 10px;
		margin-left: 20px;
		margin-right: 5px;
		color: var(--table-text-color);
	}

	.item-input {
		display: flex;
		align-items: center;
		width: var(--item-width);
		margin-left: 5px;
		margin-top: 10px;
	}

	.item-input-time {
		/* width: 150px; */
		margin-left: 5px;
		margin-top: 10px;
	}


	.item-split {
		font-size: 18px;
		margin-left: 5px;

	}

	.query-button {
		/* margin-top: 5px; */
	}

	.button-item {
		margin-top: 10px;
		/* margin-bottom: 5px; */
		margin-left: 5px;
	}

	.table-set {
		width: 100%;
		margin-top: 2px;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.tabletop {
		position: sticky;
		position: -webkit-sticky;
		top: 0px;
		background-color: #e7e7e7;
		height: 30px;
		z-index: 100;
		/* 避免浮动时被文本框覆盖 */
	}

	.tabletop>td {
		text-align: center;
		height: var(--head-height);
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
		border-left: solid 1px #CCCCCC;

	}

	.table-row {
		height: 40px;
	}

	.brackets {
		width: calc(100% - 0px);
		height: 100%;
		/* 		padding-left: 10px;
		padding-right: 10px; */
		cursor: pointer;
		display: flex;
		/*justify-content: space-between; */
	}

	.brackets div {
		font-size: 18px;
		/* margin: 0px 5px; */
		text-align: center;
		width: 20px;
		cursor: pointer;
	}

	.brackets div:hover {
		color: #F56C6C;
		background-color: #ffff7f;
	}

	.input-text {
		height: 30px;
	}

	.input-select {
		width: 100%;
	}

	.queryInfo {
		margin-top: 1px;
		padding: 5px;
		border: solid 1px #cccccc;

	}

	.head-select {
		width: calc(100% - 12px);
		margin-top: 2px;
		padding: 5px;
		background-color: #fcfcfc;
		border: solid 1px #cccccc;
		border-radius: 0px 0px 10px 10px;
	}

	.order-item {
		margin: 5px;
		cursor: pointer;
	}

	.filter-select {
		width: 100%;
	}

	.filter-check {
		padding: 0px 5px;
	}
</style>