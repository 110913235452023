<template>

	<treeviewdialog :node-list="nodes" :text-path="model.textPath" :reset="model.dialogReset=='Y'" :size="model.size"
		:title="model.dialogTitle" :icon="model.dialogIcon" :disabled="model.status=='disabled'"
		:width="model.dialogWidth" :height="model.dialogHeight" :split-char="model.splitChar" :before-oK="beforeOk"
		@open="dialogOpen" @choose="nodeClick" @select="nodeSelect" @focus="onFocus" @blur="onBlur" />


</template>

<script>
	import TreeViewDialog from '../../../../comm/treedialog/TreeViewDialog.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				nodes: []
			}
		},

		methods: {
			doLinkUpdate() {
				this.linkUpdate('nodeId')
				this.linkUpdate('nodeText')
				this.linkUpdate('textPath')
				this.linkUpdate('idPath')
			},
			nodeSelect(result) {

				let idPath = result.idPath
				let labelPath = result.textPath
				let side = this.model.idSide
				let split = this.model.splitChar
				if (side === 'head') {
					idPath = split + idPath
				} else if (side === 'tail') {
					idPath = idPath + split
				} else if (side === 'both') {
					idPath = split + idPath + split
				}
				side = this.model.labelSide
				if (side === 'head') {
					labelPath = split + labelPath
				} else if (side === 'tail') {
					labelPath = labelPath + split
				} else if (side === 'both') {
					labelPath = split + labelPath + split
				}
				let model = this.model
				/* model.nodeId = result.id
				model.nodeText = result.label
				model.textPath = labelPath
				model.idPath = idPath */
				//this.onChange(result) 
				this.setData('nodeText', result.label)
				this.setData('nodeId', result.id)
				this.setData('textPath', labelPath)
				this.setData('idPath', idPath)
				if (this.isedit) {
					return
				}
				//this.doLinkUpdate()

				//给页面组件传值
				for (let item of 'id,label,pid,textPath,idPath'.split(',')) {
					let objId = model.inputOption.tree[item]
					if (objId) {

						let target = this.$logic.pageUtil.getElementById(this.page.formData, objId) // this.page.$(objId)

						if (!target || target.isInput !== 'S') {
							continue
						}

						if (target.$value) {
							target.$value(result[item])
						}
					}
				}
				this.doEvent({
					eventName: 'after',
					result: result
				}, '$comp_after')
			},
			nodeClick(nodeObj) {
				if (!this.isedit) {
					this.doEvent({
						eventName: 'nodeclick',
						node: nodeObj
					}, '$comp_nodeclick')
				}

			},
			beforeOk(result) {
				if (!this.isedit) {
					let ck = this.doEvent({
						eventName: 'before',
						result: result
					}, '$comp_before')
					return ck
				} else {
					return true
				}
			},
			dialogOpen() {
				if (!this.isedit) {
					let sql = this.model.inputOption.tree.sql
					if (sql && sql.length > 10) { //如果有效SQL
						sql = this.$logic.db.getParamSql(sql, this.page.$params || {}, this.page)

						this.nodes = []
						let sourceKey = this.model.inputOption.sourceKey == 0 ? this.project.dataSource.sourceKey : this
							.model.inputOption.sourceKey
						this.$logic.http.sqlQuery(sourceKey, sql, [], {
							isJson: true
						}).then(res => {
							this.nodes = res.data.data.dataList
							this.doEvent({
								eventName: 'dialogopen',
								nodes: this.nodes
							}, '$comp_open')
						}).catch(err => {
							this.$logic.tip.error('数据请求异常：' + err.info)
						})


					} else {
						this.doEvent({
							eventName: 'dialogopen',
							nodes: this.nodes
						}, '$comp_open')
					}



				}
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return {
						nodeId: model.nodeId,
						nodeText: model.nodeText,
						textPath: model.textPath,
						idPath: model.idPath
					}

				} else {
					if ('nodeId' in value) {
						model.nodeId = value.nodeId
					}
					if ('nodeText' in value) {
						model.nodeText = value.nodeText
					}
					if ('textPath' in value) {
						model.textPath = value.textPath
					}
					if ('idPath' in value) {
						model.idPath = value.idPath
					}


				}
			}

		},
		created() {
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate
			}

		},
		components: {
			treeviewdialog: TreeViewDialog
		},
	}
</script>

<style scoped>

</style>