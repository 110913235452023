class ArrayUtil {
	getTreeNode(tree, nodeId) { //返回节点路径列表，上级节点在前
		let data = null
		let sk = [].concat(tree)
		while (sk.length > 0) {
			let node = sk.pop()
			if (node.id == nodeId) {
				data = node
				break
			}
			if (node.children) {
				for (let item of node.children) {
					sk.push(item)
				}
			}
		}
		return data
	}
	getSubNodes(tree, nodeId) {
		let nodes = []
		let find = false
		let sk = [].concat(tree)
		while (sk.length > 0) {
			let node = sk.pop()
			if (node.id == nodeId) {
				find = true
				sk.splice(0, sk.length) //如果发现目标节点，清空栈避免别的节点加入
			}
			if (find) { //找到了节点的情况下，加入其子节点，包含自己
				nodes.push(node)
			}
			if (node.children) {
				for (let item of node.children) {
					sk.push(item)
				}
			}
		}

		return nodes

	}
	getTreeNodePath(tree, nodeId) { //返回节点路径列表，上级节点在前
		let path = []
		let sk = [].concat(tree)
		while (sk.length > 0) {
			let node = sk.pop()
			if (node.id == nodeId) {
				let parent = node
				while (parent) {
					path.push(parent)
					parent = parent.$parent
				}
				break
			}
			if (node.children) {
				for (let item of node.children) {
					sk.push(item)
				}
			}
		}
		path.reverse()
		return path
	}

	parseTree(list) { //从扁平列表数据生成树型数据,包括基本的id,pid,label属性
		let root = []
		let datas = []
		if (!list || list.length > 0 && list[0].children) { //含用children属性表示本身已是树结构，直接返回
			return list
		}
		for (let item of list) { //创建数据副本
			let data = {
				...item,
				$parent: null,
				children: []
			}
			if (!('label' in data)) {
				if ('groupName' in data) {
					data.label = data.groupName
				} else {
					data.label = 'null'
				}
			}

			datas.push(data)
		}
		for (let data of datas) {
			let parent = null
			for (let item of datas) { //查找每行数据的父级数据，找不到父节点的即为根元素
				if (data.pid == item.id) {
					parent = item
					break
				}
			}
			if (parent) {
				//检测循环数据
				let pdata = data
				while (pdata) {
					if (pdata.pid == data.id) {
						alert('loop tree data:' + data.id)
						console.error('loop tree data:', data)
						return []
					}
					pdata = data.$parent
				}

				data.$parent = parent
				parent.children.push(data)
			} else {
				root.push(data)
			}
		}


		return root

	}
	indexByKey(list, key, value) {
		let idx = -1
		let c = -1
		for (let item of list) {
			c++
			if (item[key] === value) {
				idx = c
				break
			}
		}
		return idx
	}
	itemByKey(list, key, value) {
		for (let item of list) {
			if (item[key] === value) {
				return item
			}
		}
		return null
	}
	getItemIndex(list, obj) {
		let x = -1
		let idx = -1
		for (let item of list) {
			x++
			if (item === obj) {
				idx = x
				break
			}
		}
		return idx
	}
	sort(list, sort, func) { //返回新的数组,sort:-1 升序，1降序
		if (sort && func) {
			let isFunction = typeof(func) != 'string'
			let rs = []
			for (let item of list) {
				let idx = -1
				for (let i = 0; i < rs.length; i++) {
					let comp = null
					if (isFunction) {
						comp = func(item, rs[i])
					} else {
						comp = item[func] < rs[i][func] ? -1 : 1
					}
					if (comp === sort) {
						idx = i
					} else {
						break
					}
				}
				rs.splice(idx + 1, 0, item)
			}
			//最终结果是反向顺序，需再反
			rs.reverse()
			for (let i = 0; i < rs.length; i++) { //不改变原数组对象引用
				list[i] = rs[i]
			}
			return list
		} else {
			return list
		}
	}

	/*
		moveBefor(list, srcObj, targetObj) { //srcObj移动 targetObj前面的位置，查出两个所在的位置，
			//如果src在target之前,src以下到target之间的节点全部上移，src放到上移完后的空位置
			//如果src在target之后，target及其后面的到src之间的下移，src放到上面的target原来的位置
			let s = -1
			let t = -1
			let x = -1
			for (let item of list) {
				x++
				if (item === srcObj) {
					s = x
				}
				if (item === targetObj) {
					t = x
				}
			}
			if (s == t || s <0 || t < 0) {//如果源与目标是其本身或任意一个不存在不处理
				return
			}
			let obj=list[s]
			console.log('s='+s+',t='+t)
			if(s<t){			
				for(let i=s;i<t-1;i++){
					list[i]=list[i+1]
				}
				list[t-1]=obj
			}else{
				for(let i=s;i<t;i--){
					list[i]=list[i-1]
				}
				list[t]=obj
			}

		}
		moveAfter(list, srcObj, targetObj){
			let s = -1
			let t = -1
			let x = -1
			for (let item of list) {
				x++
				if (item === srcObj) {
					s = x
				}
				if (item === targetObj) {
					t = x
				}
			}
			if (s == t || s <0 || t < 0) {//如果源与目标是其本身或任意一个不存在不处理
				return
			}
			let obj=list[s]
			if(s<t){			
				for(let i=s;i<t;i++){
					list[i]=list[i+1]
				}
				list[t]=obj
			}else{
				for(let i=s;i<t-1;i--){
					list[i]=list[i-1]
				}
				list[t-1]=obj
			}
			
		}
	 */

}

export default new ArrayUtil()