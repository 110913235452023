import options from '../../../../../public/options.js'
import polarConfig from '../polarConfig.js'
import echartConfig from '../../echartConfig.js'
let initData=JSON.stringify( [
 	['指标项','最大值', 'A类', 'B类', 'C类'],
 	['周一',150, 50, 150, 70],
 	['周二',180, 150, 80, 130],
 	['周三',180, 90, 130, 150],
 	['周四',180, 110, 100, 60],
 	['周五',180, 70, 140, 120]
 ]).replaceAll('],','],\r\n')

let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'

let base =[].concat( echartConfig.base)
let radar=[
	{key:'polarCenterX',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'polarCenterY',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'polarRadiusI',text:'雷达内半径',type:'text',init:'0%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	{key:'polarRadiusO',text:'雷达外半径',type:'text',init:'75%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	{key:'polarType',text:'雷达形状',type:'select',init:'polygon',path:'radar.shape',bursh:true,bind:true,tip:'',list:options.toOptions('圆形=circle,多边形=polygon')},
	
]
options.insertBaseConfig(base,radar)
 

let special = [
	 {key:'title',text:'雷达图'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'radar',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesRadarColorBy',text:'取色方式',type:'select',init:'series',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'radarAxisNameShow',text:'指标显示',type:'switch',init:'Y',path:'radar.axisName.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'radarAxisNameFontSize',text:'指标字号',type:'number',init:12,path:'radar.axisName.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'radarAxisNameFontFamily',text:'指标字体',type:'select',init:'',path:'radar.axisName.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'radarAxisNameColor',text:'指标颜色',type:'color',init:options.initColor.text.dark,path:'radar.axisName.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'radarNameGap',text:'指标偏距',type:'number',init:15,path:'radar.axisNameGap',bursh:true,bind:true,tip:'',list:null},

	 {key:'radarAxisLineShow',text:'显示轴线',type:'switch',init:'Y',path:'radar.axisLine.show',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'radarAxisLineColor',text:'轴线颜色',type:'colorA',init:options.initColor.line.dark,path:'radar.axisLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	{key:'radarAxisLineWidth',text:'轴线粗细',type:'number',init:1,path:'radar.axisLine.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
	{key:'radarAxisLineType',text:'轴线类型',type:'select',init:'solid',path:'radar.axisLine.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},

/* 	 {key:'radarAxisTickShow',text:'显示刻度',type:'switch',init:'Y',path:'radar.axisTick.show',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'radarAxisTickLeng',text:'刻度长度',type:'number',init:5,path:'radar.axisTick.length',bursh:true,bind:true,tip:'象数值',list:null},
	{key:'radarAxisTickColor',text:'刻度颜色',type:'colorA',init:options.initColor.line.dark,path:'radar.axisTick.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	{key:'radarAxisTickWidth',text:'刻度粗细',type:'number',init:1,path:'radar.axisTick.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
 */

	 {key:'radarAxisTickLabelShow',text:'刻度标签',type:'switch',init:'N',path:'radar.axisLabel.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'radarAxisTickLabelColor',text:'刻度标签色',type:'color',init:options.initColor.text.dark,path:'radar.axisLabel.color',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'radarAxisTickLabelRotate',text:'刻度标签角',type:'number',init:0,path:'radar.axisLabel.rotate',bursh:true,bind:true,tip:'',list:null},

	{key:'radarSplitNumber',text:'分割数量',type:'number',init:5,path:'radar.splitNumber',bursh:true,bind:true,tip:'象数值',list:null},
	 {key:'radarSplitLineShow',text:'显示分隔线',type:'switch',init:'Y',path:'radar.splitLine.show',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'radarSplitLineColor',text:'分隔线颜色',type:'color',init:options.initColor.line.dark,path:'radar.splitLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'radarSplitLineWidth',text:'分隔线粗细',type:'number',init:1,path:'radar.splitLine.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
	 {key:'radarSplitLineType',text:'分隔线类型',type:'select',init:'solid',path:'radar.splitLine.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	
	 {key:'radarSplitAreaShow',text:'显示分隔区',type:'switch',init:'Y',path:'radar.splitArea.show',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'radarSplitAreaColor',text:'分隔区填充',type:'colorB',init:['rgba(250,250,250,0.3)','rgba(200,200,200,0.3)'],path:'radar.splitArea.areaStyle.color',bursh:true,bind:true,tip:'',list:null},
	{key:'radarSplitAreaOpacity',text:'分隔透明度',type:'number',init:0.7,path:'radar.splitArea.areaStyle.opacity',bursh:true,bind:true,tip:'',list:null},
	
	
	 {key:'seriesSymbolSize',text:'标记点大小',type:'number',init:5,path:'_series.symbolSize',bursh:true,bind:true,tip:'',list:null},
	
	 {key:'seriesRadarLabelShow',text:'数值标签',type:'switch',init:'N',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesRadarLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesRadarLabelRotate',text:'旋转角度',type:'number',init:0,path:'_series.label.rotate',bursh:true,bind:true,tip:'',list:null},

	{key:'seriesLineWidth',text:'线条粗细',type:'number',init:1,path:'_series.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
	{key:'seriesLineType',text:'线条类型',type:'select',init:'solid',path:'_series.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	{key:'seriesAreaColor',text:'区域填充色',type:'colorA',init:'rgba(255,255,255,0.5)',path:'_series.areaStyle.color',bursh:true,bind:true,tip:'',list:null},
	{key:'seriesAreaAotoFill',text:'调色板填充',type:'switch',init:'N',bursh:true,bind:true,tip:'如果开启，区域填充色将失效，自动采用调色板颜色填充区域',list:options.YON},

	{key:'seriesAreaOpacity',text:'区域透明度',type:'number',init:0.7,path:'_series.areaStyle.opacity',bursh:true,bind:true,tip:'',list:null},


	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 




]
let action = polarConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_radar',polarAxisLineShow:'N',seriesAreaAotoFill:'Y',polarCenterY:'55%',color:options.chartColor.bar3,
		chartClan: 'polar',legendOrient:'vertical'//默认垂直方向图例
	},
	delete: '',
	hide: 'seriesType,seriesPieRadiusI,seriesPieRadiusS,polarStartAngle,polarRadiusAxis,dataOption',
	base:[].concat(base) ,
	special: special,
	action: polarConfig.action.timer.concat(polarConfig.action.comm)
} 
export default cfg
