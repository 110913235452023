<template>
	<table v-show="model.show !=='none'" :border="model.lineShow=='Y'?model.lineSize:'0px'" @click="mouseClick"
		@mouseover="mouseOver" @mouseleave="mouseLeave" @mouseup="mouseUp" :style="tableStyle " :class="tableCss">

		<tr v-for=" row in  model.items" v-show="row.show !='none'"
			:class="isedit?  currentElement==row? 'layouttr noselect trselect': 'layouttr noselect' : ''"
			:style="{visibility:row.show=='hidden'?'hidden':'visible',height: row.trH || model.trH,backgroundColor: row.trBC || model.backColor}">
			<td v-for="cell in row.items" v-show="row.show !='none'" :align="cell.tdH || model.tdH"
				:valign="cell.tdV || cell.parent.parent.tdV"
				:class="(isedit? currentElement==cell?'layouttd tdcurrent': 'layouttd' : '') + (cell.sel ? ' tdsel':'')"
				:style="{visibility:cell.show=='hidden'?'hidden':'visible',width:cell.tdW,height:row.trH || model.trH,padding:cell.tdP || model.tdP,fontFamily:cell.fontName,fontSize:cell.fontSize=='0' ?'':cell.fontSize,color:cell.fontColor,fontWeight:cell.fontBold,textDecoration:cell.fontLine,fontStyle:cell.fontItalic,lineHeight:cell.fontHeight,backgroundColor:cell.tdBC || row.trBC || model.backColor}"
				:rowspan="cell.span[0][1] - cell.span[0][0]+1" :colspan="cell.span[1][1] - cell.span[1][0]+1"
				@click.stop="tdMouseClick(cell)" @mousedown.stop="tdMouseDown(cell)"
				@mouseover.stop="tdMouseHover(cell)" @mouseup="tdMouseUp($event,cell)" @mouseleave="tdMouseLeave(cell)">

				<!-- <input type="text" v-if="editCell==cell" class="input-text cell-edit" /> -->
				<textarea v-if="editCell===cell" :id="'text_'+cell.id" v-model="cell.tdT" rows="1"
					class="input-text cell-edit" @blur="editCell=null" @keyup="editKeyUp"></textarea>
				<template v-else>
					<span v-if="cell.tdT ">{{cell.tdT}}</span>

					<template v-for="item in cell.items">
						<container v-if=" item.group=='layout' " :model="item" :project="project" :page="page"
							:host="host" :isedit="isedit" :datas="bindData" />
						<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
							:datas="bindData" />


					</template>
				</template>
				<!-- <div v-if="editCell==cell" class="cell-edit"> 
				 <input type="text" v-if="editCell==cell" class="cell-edit" />
				</div> -->
			</td>
		</tr>

	</table>
</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				tdStart: null,
				bindData: null,
				editCell: null
			}
		},

		methods: {
			editKeyUp(event) {
				if (event.key.indexOf('Arrow') != 0) {
					return
				}
				let cell = this.editCell
				let tr = cell.parent
				let tb = tr.parent
				let row = null
				let rowIdx = tb.items.indexOf(tr)
				let colIdx = tr.items.indexOf(cell)
				let target = null

				if (event.key === 'ArrowUp') {
					row = this.getNextEditRow(tb, rowIdx, -1)
					if (!row) {
						return
					}
					if (row.items.length > colIdx && row.items[colIdx].tdM === 'edit') {
						target = row.items[colIdx]
					} else {
						for (let i = row.items.length - 1; i > -1; i--) {
							if (row.items[i].tdM === 'edit') {
								target = row.items[i]
								break
							}
						}

					}
				} else if (event.key === 'ArrowDown') {
					row = this.getNextEditRow(tb, rowIdx, 1)
					if (!row) {
						return
					}
					if (row.items.length > colIdx && row.items[colIdx].tdM === 'edit') {
						target = row.items[colIdx]
					} else {
						for (let i = 0; i < row.items.length; i++) {
							if (row.items[i].tdM === 'edit') {
								target = row.items[i]
								break
							}
						}
					}
				} else if (event.key === 'ArrowLeft') {
					for (let i = colIdx - 1; i > -1; i--) {
						if (tr.items[i].tdM === 'edit') {
							target = tr.items[i]
							break
						}
					}

				} else if (event.key === 'ArrowRight') {
					for (let i = colIdx + 1; i < tr.items.length; i--) {
						if (tr.items[i].tdM === 'edit') {
							target = tr.items[i]
							break
						}
					}
				}

				if (target) {
					this.editCell = target
					//this.tdMouseClick(target)
					this.$nextTick(() => {
						this.tdMouseClick(target)
					})
					/* 	setTimeout(() => {
							this.tdMouseClick(target)
						}, 10) */
				}


			},
			getNextEditRow(table, rowIdx, step) { //-1 向上找,1向下找
				if (step == -1) {
					for (let i = rowIdx - 1; i > -1; i--) {
						let tr = table.items[i]
						for (let td of tr.items) {
							if (td.tdM === 'edit') {
								return tr
							}
						}
					}
				} else if (step == 1) {
					for (let i = rowIdx + 1; i < table.items.length; i++) {
						let tr = table.items[i]
						for (let td of tr.items) {
							if (td.tdM === 'edit') {
								return tr
							}
						}
					}
				}

				return null
			},
			setBindData() { //覆盖公共的数据绑定方法，需要绑到下级子元素

				let data = this.bindData
				if (data) {
					let sk = [this.model]
					
					while (sk.length > 0) {
						let model = sk.pop()
						
						let map = model.$DataMap
						if (map) {
							
							for (let key in map) {
								let dataName = map[key].dataName 
								if (dataName && (dataName in data)) {
									model[key] = data[dataName] 
								}
							}
						}
						if (model.items) {
							for (let item of model.items) {
								sk.push(item)
							}
						}
					}
				}
			},
			tdAreaSel(start, end) { //区域框选
				if (start == null || end == null) {
					return
				}
				let table = this.model
				//不论从哪个方向开始框选，一定是个标准的矩形区域，从起始和终止单元格中分别取出最大行号、最小行号、最小列号、最大列号，比对落在此范围内的单元格设置标记
				//先假定从上往下框选
				let row1 = start.span[0][0] //单元格的起始最小行
				let row2 = end.span[0][1] //单元格截止的最大行					
				if (row1 > end.span[0][0]) { //起始行大于截止行是从下往上框选的
					row1 = end.span[0][0]
					row2 = start.span[0][1]
				}
				//假定从左往右选
				let col1 = start.span[1][0]
				let col2 = end.span[1][1]
				if (col1 > end.span[1][0]) { //如果是从右往左的方向
					col1 = end.span[1][0]
					col2 = start.span[1][1]
				}
				this.tdSelClear()
				let selBox = {
					table,
					row1,
					row2,
					col1,
					col2
				}
				this.$store.commit('setTableBox', selBox)
				//设置已选状态
				for (let i = row1; i <= row2; i++) { //跨行合并的单元格只要上部落在行区间内即可，如只有底部落在区间内不丢弃
					let row = table.items[i]
					for (let col of row.items) {
						let c1 = col.span[1][0]
						if (c1 >= col1 && c1 <=
							col2) { //只需要起始位置在区间内即被选中（如果中间有个较长的合并单元格，另一头超出了区间也被选中，框选区域合并操作时须注意不允许作合并操作）
							col.sel = true
						}
					}
				}


			},
			tdSelClear() {
				//全部置为未选状态
				let table = this.model
				this.$store.commit('setTableBox', null)
				for (let row of table.items) {
					for (let col of row.items) {
						col.sel = false
					}
				}
			},
			tdMouseDown(cell) {
				if (this.isedit) {
					this.tdStart = cell
					if (this.tableBox) {
						this.tdSelClear()
					}
				} else {
					this.doEvent({
						eventName: 'mousedown',
						cell: cell._proxy
					}, '$event_mousedown')

				}
			},
			tdMouseHover(cell) { //单元格框选
				if (this.isedit && this.tdStart) {
					this.tdAreaSel(this.tdStart, cell)

				} else if (!this.isedit) {
					this.doEvent({
						eventName: 'mouseover',
						cell: cell._proxy
					}, '$event_mouseover')
				}

			},
			tdMouseUp(e, cell = null) {
				if (this.isedit) {
					if (this.newComp.show) { //创建或拖动组件
						this.mouseUp(e, cell)
					}
					this.tdStart = null //只要是鼠标弹起即取消框选状态
				} else { 
					if (cell) { //只触发单元格发生的
						this.doEvent({
							eventName: 'mouseup',
							cell: cell._proxy
						}, '$event_mouseup')
					}
				}
			},
			tdMouseLeave(cell = null) {
				if (!this.isedit) {
					this.doEvent({
						eventName: 'mouseleave',
						cell: cell._proxy
					}, '$event_mouseleave')
				}
			},
			tdMouseClick(cell = null) {
				if (this.isedit) {
					this.$store.commit('setElement', cell)
				} else {
					this.doEvent({
						eventName: 'click',
						cell: cell._proxy
					}, '$event_click')

				}
				if (cell.tdM == 'edit') {
					this.editCell = cell

					let textId = 'text_' + cell.id
					setTimeout(() => {
						let obj = document.getElementById(textId);

						if (obj) {
							let td = obj.parentNode
							/* let rect = td.getBoundingClientRect();  
							let width1 = rect.width;  */
							let width = td.clientWidth
							let style = window.getComputedStyle(td);
							let paddingTop = parseFloat(style.paddingTop);
							let paddingRight = parseFloat(style.paddingRight);
							let paddingBottom = parseFloat(style.paddingBottom);
							let paddingLeft = parseFloat(style.paddingLeft);
							obj.style.width = (width - paddingLeft - paddingRight - 2) + 'px'
							//console.log(width , paddingLeft , paddingRight,obj.style.width)
							obj.focus()
						}
					}, 100)
				} else {
					this.editCell = null
				}

			},
		},
		computed: {
			tableBox() {
				return this.$store.state.tableBox
			},
			tableStyle() {
				let model = this.model
				let style = this.layoutstyle
				style.width = model.width
				style.borderCollapse = model.lineColl
				if (model.lineShow === 'Y') {
					style.border = 'solid ' + model.lineSize + ' ' + model.borderColor
				}
				return style

			},
			tableCss() {
				let css = this.layoutcss
				if (this.isedit && this.model == this.currentElement) {
					css.push('tbselect')
				}

				return css
			},
		},
		created() {
			this.createInit()

		}
	}
</script>

<style scoped>
	.trselect {
		background-color: #ffffd7 !important;
		border-color: #ffaa00;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
	}

	.layouttr:hover {
		background-color: #ffffd7;
		border-color: #ffaa00;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
	}

	.layouttd:hover {
		background-color: #ffff7f !important;
	}

	.tdcurrent {
		border-color: #ffaa00;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
	}

	.tdsel {
		background-color: #ff00ff !important;
	}

	.tbselect {
		border-color: #ffaa00 !important;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
		/* 	border-color: #ff00ff;
		box-shadow: 0px 0px 0px 2px #ffaaff inset; */
	}

	.cell-edit {
		/* width: calc(100% - 6px); */
		width: 10px;
		height: calc(100% - 2px);
		border-color: rgb(26, 115, 232);
		resize: none;
	}
</style>