<template>
	<div :ref="'chart_'+model.id" :style="chartSiz">

	</div>
</template>

<script>
	import echartBaes from '../echartsBase.js'
	export default {
		mixins: [echartBaes],
		data() {
			return {


			}
		},

		methods: {
			doUpdate(data) { //覆盖公共方法

				let dataSet = null
				if ('heads' in data) { //接口数据
					let head = []
					for (let item of data.heads) {
						head.push(item.label)
					}
					dataSet = head.concat(data.dataList)
				} else { //内置数据
					dataSet = data
				}

				this.updateOption(data)


			},
			optionFilter(option) { //处理种类组件特殊的配置项
			
				let model = this.model
				let series = option.series
				let dataSource = option.dataset.source
				/* let cx = model.seriesPieCenterX.indexOf('%') > -1 ? model.seriesPieCenterX : parseInt(model
					.seriesPieCenterX)
				let cy = model.seriesPieCenterY.indexOf('%') > -1 ? model.seriesPieCenterY : parseInt(model
					.seriesPieCenterY) */
				let cx = model.polarCenterX ? model.polarCenterX.indexOf('%') > -1 ? model.polarCenterX : parseInt(model
					.polarCenterX) : '50%'
				let cy = model.polarCenterY ? model.polarCenterY.indexOf('%') > -1 ? model.polarCenterY : parseInt(model
					.polarCenterY) : '50%'

				let center = [cx, cy]
				let polar = null
				if ('chart_radar' === model.type) {
					polar = option.radar

				} else {
					polar = {}
					option.polar = polar
				}
				polar.center = center
				polar.radius = [model.polarRadiusI, model.polarRadiusO]

				switch (model.type) {
					case 'chart_water':
						let wdata = [],
							colors = []
						/* for (let i = 1; i < dataSource.length; i++) {
							let row = dataSource[i]
							wdata.push({
								name: row[0],
								value: row[1]
							})
							colors.push(model.color[(i-1) % model.color.length])
						} */
						if (dataSource.length > 1) {
							let head = dataSource[0]
							let row = dataSource[1]
							for (let i = 1; i < row.length; i++) {
								let ss = series[i - 1]
								ss.data = [{
									name: head[i],
									value: row[i]
								}]
								ss.center = polar.center
								ss.color = [model.color[(i - 1) % model.color.length]]
							}
						}
						let list = this.$logic.arrayUtil.sort(series,
							1, (s1, s2) => {
								return s1.data[0].value > s2.data[0].value ? 1 :
									s1.data[0].value < s2.data[0].value ? -1 : 0
							})
						option.series = list

						delete option.polar
						break
					case 'chart_funnel':

						delete option.polar
						break
					case 'chart_gauge':
						if (dataSource.length > 1) {
							let row = dataSource[1]
							series[0].data = [{
								value: row[1],
								name: row[0]
							}]
							if (row.length > 2 && row[2] !== null) {
								series[0].max = row[2]
							}
							if (row.length > 3 && row[3] !== null) {
								series[0].min = row[3]
							}
						}
						series[0].center = option.polar.center
						series[0].radius = model.polarRadiusO
						let tx = model.seriesGaugeTitleCenterX ? model.seriesGaugeTitleCenterX.indexOf('%') > -1 ? model
							.seriesGaugeTitleCenterX : parseInt(model
								.seriesGaugeTitleCenterX) : '0%'
						let ty = model.seriesGaugeTitleCenterY ? model.seriesGaugeTitleCenterY.indexOf('%') > -1 ? model
							.seriesGaugeTitleCenterY : parseInt(model
								.seriesGaugeTitleCenterY) : '40%'

						let dx = model.seriesGaugeDetailCenterX ? model.seriesGaugeDetailCenterX.indexOf('%') > -1 ? model
							.seriesGaugeDetailCenterX : parseInt(model
								.seriesGaugeDetailCenterX) : '0%'
						let dy = model.seriesGaugeDetailCenterY ? model.seriesGaugeDetailCenterY.indexOf('%') > -1 ? model
							.seriesGaugeDetailCenterY : parseInt(model
								.seriesGaugeDetailCenterY) : '70%'

						series[0].title.offsetCenter = [tx, ty]
						series[0].detail.offsetCenter = [dx, dy]
						delete option.polar //公共配置带出来的
						delete option.dataset

						option.series = series[0]



						break
					case 'chart_radar':
						//设置了最大值后会提示警告，修改node_modules\_echarts@5.4.0@echarts\lib\coord\axisAlignTicks.js
						//使警告条件不满足 if (false && ticks[1]
						let indicator = []
						let radarData = []
						for (let i = 1; i < dataSource.length; i++) {
							let row = dataSource[i]
							indicator.push({
								name: row[0],
								max: row[1],
							})

						}
						//雷达图采用不一样的数据格式
						for (let i = 1; i < series.length; i++) { //第一组为最大值对应产生的系列，排除掉
							if (model.seriesAreaAotoFill === 'Y') {
								series[i].areaStyle.color = model.color[(i - 1) % model.color.length]
							}

							series[i].data = []
							let item = {
								value: [],
								name: series[i].name
							}
							series[i].data.push(item)
							for (let j = 1; j < dataSource.length; j++) {
								item.value.push(dataSource[j][i + 1])
							}
						}

						polar.indicator = indicator

						delete option.dataset
						option.series.splice(0, 1)

						break
					case 'chart_pie':
					case 'chart_pieS':
					case 'chart_pieM':
					case 'chart_barR'://在此给柱条单独设置系列的数据
						let ri = parseInt(model.polarRadiusI) //parseInt(model.seriesPieRadiusI)
						let ro = parseInt(model.polarRadiusO) //parseInt(model.seriesPieRadiusO)

						let rs = parseInt(model.seriesPieRadiusS) //parseInt(model.seriesPieRadiusS)

						//计算每圈外径，从大到小,全部使用百分比


						let length = model.type == 'chart_pie' || model.type == 'chart_pieS' ? 1 : series.length

						let step = (ro - ri) / length
						let radius = [model.seriesPieRadiusI, model.seriesPieRadiusO]
						let seriesList = []
						let ds = option.dataset.source
						//console.log(ds.length)
						for (let i = 0; i < length; i++) {
							//生成每个系统的数据，多维度下echarts自动生成的各系列数据一样
							let datas = []
							for (let r = 1; r < ds.length; r++) {
								let row = ds[r]
								datas.push({
									name: row[0],
									value: row[i + 1]
								})
							}
							series[i].data = datas
							series[i].center = center
							//第一层圈内不叠加间距
							series[i].radius = [(i * step + ri + (i == 0 ? 0 : rs)) + '%', ((i + 1) * step + ri) + '%']
							seriesList.push(series[i])
						}
						option.series = seriesList
						break

				}
				/* option.angleAxis.splitLine.show=false
				option.angleAxis.axisTick.show=false
				option.angleAxis.axisLiner={show:false}
				option.radiusAxis.axisLine={show:false}
				 */
				
				 
				// console.log(JSON.stringify( option))
				  
			},

		},
		created() {


		}
	}
</script>

<style scoped>

</style>
