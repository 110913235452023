import font from '../../public/font.js'
import layout from '../../public/layout.js'
import options from '../../public/options.js'
let base =[
	{key:'headShow',text:'启用页眉',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'headHeight',text:'页眉高(mm)',type:'number',init:10,target:'',bursh:true,bind:true,tip:'单位毫米mm',list:null},

	{key:'footShow',text:'启用页脚',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'footHeight',text:'页脚高(mm)',type:'number',init:10,target:'',bursh:true,bind:true,tip:'单位毫米mm',list:null},
	{key:'printNo',text:'打印页码',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list:null},

	{key:'topPad',text:'上边距(mm)',type:'number',init:5,target:'',bursh:true,bind:true,tip:'单位毫米mm',list:null},
	{key:'bottomPad',text:'下边距(mm)',type:'number',init:5,target:'',bursh:true,bind:true,tip:'单位毫米mm',list:null},
	{key:'leftPad',text:'左边距(mm)',type:'number',init:5,target:'',bursh:true,bind:true,tip:'单位毫米mm',list:null},
	{key:'rightPad',text:'右边距(mm)',type:'number',init:5,target:'',bursh:true,bind:true,tip:'单位毫米mm',list:null},
	
	{key:'pageType',text:'纸张类型',type:'select',init:'A4',target:'',bursh:true,bind:true,tip:'纸张类型：A3,A4',list:[{label:'A4',value:'A4'},{label:'A3',value:'A3'},{label:'A5',value:'A5'},{label:'A6',value:'A6'},{label:'自定义',value:'other'}]},
	{key:'direction',text:'纸张方向',type:'select',init:'V',target:'',bursh:true,bind:true,tip:'竖向:V,横向：H',list:[{label:'竖向',value:'V'},{label:'横向',value:'H'}]},
	{key:'pageWidth',text:'纸张宽度',type:'number',init:210,target:'',bursh:true,bind:true,read:true,tip:'单位毫米mm',list:null},
	{key:'pageHeight',text:'纸张高度',type:'number',init:297,target:'',bursh:true,bind:true,read:true,tip:'单位毫米mm',list:null},
	{key:'dpi',text:'打印精度',type:'number',init:96,target:'',bursh:true,bind:true,tip:'windows默认打印精度96dpi，Mac系统72dpi，特殊高质量打印机可自行设置',list:null},

	{key:'autoHide',text:'默认隐藏',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'设置是否运行时不可见，如果集成在表单页面时建议开启',list:null},
	//{key:'previewPrintBtnLabel',text:'按钮文本',type:'text',init:'打印',target:'',bursh:true,bind:true,tip:'编辑预览页面的打印按钮文本',list:null},
	//{key:'autoRow',text:'优化跨页行',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'自动调整表格行的高度，尽量在一纸张上打出整行内容',list:null},
	
	
] //.concat(layout).concat(font)
let print= {
	data:{
		type:'print',width:'100%',height:'',alignV:'flex-start',minH:'20px',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'',
	hide:'pageType,display,flexD,flexW,position,marginT,marginB,marginL,marginR,borderL,borderR,borderT,borderB,borderS',//width,minW',
	base:base,
	special :[],
	action:[
//		{key: '$comp_loadBefore',text: '容器加载前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '可在此加载数据初始化容器内已绑定对象的数据，如返回非空且对象类型数据，将进行数据自动装配',list: null	},
//		{key: '$comp_loadAfter',text: '容器加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '挂载完成后，可可进行数据加载、属性赋值等操作',list: null	},
		{key: '$comp_printBefore',text: '打印前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '打印开始前触发，可进行对象属性设置，如果返回false将取消打印',list: null	},
//		{key: '$comp_printOpen',text: '容器打印中',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '打印开始中，可进行状态更新等操作',list: null	},
		{key: '$comp_printAfter',text: '打印后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '打印完成后',list: null	},
		
		
	].concat( options.getEvents(null,'$hook','$hook_mount,$hook_show,$hook_hide,$hook_destroy'))//$timer,$event,
}

export default print