<template>
	<template v-if="model.type=='text'">


		<el-input ref="inputObj" v-model="textValue" @change="onChange" @input="onInput" @blur="onBlur" @focus="onFocus"
			@keydown="onKeyDown" @keyup="onKeyUp" @keypress="onKeyPress" :type="model.inType"
			:maxlength="model.length>0?model.length:-1" :placeholder="model.tip" :size="model.size"
			:prefix-icon="model.iconIH" :suffix-icon="model.iconIT" :disabled="model.status=='disabled'"
			:readonly="model.status=='read'" :clearable="model.clearable=='Y'" :style="compStyle">

			<template v-if="model.iconOH && model.iconOH.length>0 ||  model.textOH && model.textOH.length>0" #prepend>
				<el-button v-if="model.iconOH && model.iconOH.length>0">
					<i v-if="model.iconOH" :class="model.iconOH" style="margin-right:0px"></i>
				</el-button>
				<span v-if="model.textOH && model.textOH.length>0">{{model.textOH}}</span>
			</template>
			<template v-if="model.iconOT && model.iconOT.length>0 ||  model.textOT && model.textOT.length>0" #append>
				<el-button v-if="model.iconOT && model.iconOT.length>0">
					<i v-if="model.iconOT" :class="model.iconOT" style="margin-right:0px"></i>
				</el-button>
				<span v-if="model.textOT && model.textOT.length>0">{{model.textOT}}</span>
			</template>
		</el-input>


	</template>
	<template v-else-if="model.type=='textselect'">
		<el-input ref="inputObj" v-model="textValue" @change="onChange" @input="onInput" @blur="onBlur" @focus="onFocus"
			@keydown="onKeyDown" @keyup="onKeyUp" @keypress="onKeyPress" :type="model.inType"
			:maxlength="model.length>0?model.length:-1" :placeholder="model.tip" :size="model.size"
			:prefix-icon="model.iconIH" :disabled="model.status=='disabled'" :readonly="model.editAllow =='N'"
			:clearable="model.clearable=='Y'" :style="compStyle">

			<template #append>
				<el-dropdown :trigger="model.status?'':  model.trigger" @command="textMenuSelect">
					<el-button>
						<i class="far fa-angle-down" style="margin-right:0px"></i>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu>
							<template v-for="(item,index) in dataList" :key="item.value">
								<el-dropdown-item :command="item.value">
									<i v-if="item.icon" :class="item.icon"></i>
									{{item.value}}</el-dropdown-item>
							</template>


						</el-dropdown-menu>
					</template>
				</el-dropdown>



			</template>
		</el-input>
	</template>

	<el-input ref="inputObj" v-else-if="model.type=='area'" v-model="textValue" @change="onChange" @input="onInput"
		@blur="onBlur" @focus="onFocus" @keydown="onKeyDown" @keyup="onKeyUp" @keypress="onKeyPress" type="textarea"
		:maxlength="model.length>0?model.length:-1" :placeholder="model.tip" :disabled="model.status=='disabled'"
		:readonly="model.status=='read'" :clearable="model.clearable=='Y'" :style="compStyle">

	</el-input>
	<div v-else-if="'datetime,date,dateR,time,timeR'.indexOf(model.type)>-1" @click="mouseClick" :style="compStyle">
		<el-date-picker ref="inputObj" v-if="'datetime'==model.type || 'date'==model.type" v-model="timeValue"
			:style="compStyle" @change="onChange" @blur="onBlur" @focus="onFocus" :type="model.dType" :size="model.size"
			:placeholder="model.tip" :disabled="model.status=='disabled' || isedit" @clear="onClearTime"
			:clearable="model.clearable=='Y'" :format="model.format" />

		<el-time-picker ref="inputObj" v-else-if="'time'==model.type" v-model="timeValue" :style="compStyle" @change="onChange"
			@click="mouseClick" @blur="onBlur" @focus="onFocus" :size="model.size" :placeholder="model.tip"
			:disabled="model.status=='disabled'  || isedit " :clearable="model.clearable=='Y'" :format="model.format" />
	</div>

	<el-slider v-else-if="'sliderS,sliderM'.indexOf(model.type)>-1 " @change="sliderChange" :style="sliderStyle"
		v-model="sliderValue" :size="model.size" :input-size="model.sizeI" :min="toFloat(model.min)"
		:max="toFloat(model.max)" :step="toFloat(model.step)" :vertical="model.vertical=='Y'" :height="model.vHeight"
		:show-tooltip="model.tooltip=='Y'" :show-input="model.input=='Y'" :show-stops="model.stops=='Y'"
		:range="model.range=='Y'" :disabled="model.status=='disabled'" :marks="marks" />




	<el-input-number ref="inputObj" v-else-if="model.type=='number'" v-model="numValue" @change="onChange"
		@blur="onBlur" @focus="onFocus" :min="toFloat(model.min)" :max="toFloat(model.max)" :step="toFloat(model.step)"
		:step-strictly="model.strict=='Y'" :precision="toInt(model.precision)" :size="model.size"
		:controls="model.controls=='Y'" :disabled="model.status=='disabled'" :controls-position="model.controlsP"
		:placeholder="model.tip" :style="compStyle"></el-input-number>


	<template v-else-if="model.type=='input'">
		<input ref="inputObj" v-model="textValue" :type="model.inputType" @change="onChange" @input="onInput"
			@blur="onBlur" @focus="onFocus" @keydown="onKeyDown" @keyup="onKeyUp" @keypress="onKeyPress"
			:style="inputStyle" :disabled="model.status=='disabled'" :readonly="model.status=='read'"
			:placeholder="model.tip" class="inputtext" />

	</template>
	<div v-else-if="model.type=='iconinput'" :style="compStyle">
		<el-input ref="inputObj" v-model="textValue" @change="onChange" @blur="onBlur" @focus="onFocus"
			:readonly="model.status=='read'" placeholder="点击选择图标" :size="model.size"
			:disabled="model.status=='disabled'" :clearable="true">

			<template #prepend>
				<span>
					<i :class="model.value" style="font-size: 18px;"></i>
				</span>

			</template>
			<template #append>
				<el-button @click="toSetIcon" :size="model.size">
					<i class="far fa-search" style="font-size: 18px;"></i>
				</el-button>
			</template>
		</el-input>
		<iconfont ref="iconfont" @select="iconSelect"></iconfont>
	</div>
	<div v-else-if="model.type=='colorinput'" :style="compStyle">
		<el-input ref="inputObj" v-model="textValue" @change="onChange" @blur="onBlur" @focus="onFocus"
			:readonly="model.status=='read'" placeholder="" :size="model.size" :disabled="model.status=='disabled'"
			:clearable="true">

			<!-- 			<template #prepend>
				<div :style="{width:'18px',height:'18px',border:'solid 1px #cccccc',backgroundColor:textValue}">

				</div>

			</template> -->
			<template #append>
				<inputcolor v-model="textValue" :alpha="model.rgba=='Y'" :size="model.size"></inputcolor>

			</template>
		</el-input>

	</div>

	<template v-else-if="model.type=='hidden'">
		<div v-if="isedit" class="hiddenfield">
			<i class="fad fa-eye-slash"></i>
			<input type="hidden" v-model="textValue" />
		</div>

		<input v-else type="hidden" v-model="textValue" />

	</template>

	<template v-else>
		<div>未知元素{{model.type}}</div>
	</template>


</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				textValue: '',
				numValue: 0,
				timeValue: null,
				sliderValue: null

			}
		},
		computed: {
			inputStyle() {
				let model = this.model
				let css = this.compStyle
				//4px两侧固定外框宽度

				if (css.width) { //+ ' - 4px - '
					css.width = 'calc(' + model.width + ' - ' + model.borderL + ' - ' + model.borderR + ' - 2px)'
				}
				if (css.height) { // + ' + 4px - 
					css.height = 'calc(' + model.height + ' - ' + model.borderT + ' - ' + model.borderB + ')'
				}
				css['border-radius'] = model.borderRadius
				css['--input-text-bgcolor'] = css.backgroundColor
				css['--input-text-bdcolor'] = css.borderColor
				delete css.backgroundColor
				delete css.borderColor
				return css
			},

			sliderStyle() {
				let css = this.compStyle
				if (this.model.vertical == 'Y') {
					css.width = '40px'
				} else {
					css.width = this.model.width
				}
				return css
			},
			marks() {
				let model = this.model
				if (model.mark == 'Y') {
					let marks = this.dataList
					if (!marks) {
						return null
					}
					let rs = {}
					for (let i = 0; i < marks.length; i++) {
						let mark = marks[i]
						let label = {
							label: mark.label
						}
						if (mark.color && mark.color.length > 0) {
							label.style = {
								color: mark.color
							}
						}
						rs[mark.value] = label
					}
					return rs
				} else {
					return null
				}

			},

			areaSize() {
				let size = {
					minRows: parseInt(this.model.minRows),
					maxRows: parseInt(this.model.maxRows)
				}
				return size
			},
			dateInit() {
				return this.model.init
			},

		},

		methods: {
			onClearTime() {
				console.log(999)
			},
			textMenuSelect(item) {
				if (!this.isedit) {
					//this.setData('value', item)
					this.model.value = item
					this.textValue = item
					this.onChange(item, 'value')
				}

			},
			iconSelect(icon) {
				this.textValue = icon
				this.$refs.iconfont.hide()
			},
			toSetIcon(dataItem, key) {
				//let dataItem={icon:null}
				this.iconObj = null
				this.$refs.iconfont.show()
			},


			sliderChange(nv) {
				let model = this.model
				if ('sliderM' === model.type && nv != null) {
					this.model.valueS = nv[0]
					this.model.valueE = nv[1]
					//this.linkUpdate('valueS')
					//this.linkUpdate('valueE')
				} else {
					this.model.value = nv
					this.linkUpdate('value')
				}
				this.onChange(nv)

			},
			setDefaultDateTime() {
				let model = this.model
				if (model.init == 'none') {
					model.value = ''
					this.timeValue = null
				} else if (model.init == 'now') {
					this.timeValue = new Date()
				} else if (model.init == 'set') {
					this.parseDateTime()
				}
			},
			updateNumber(value) { ///////////////////////需核实
				let model = this.model
				let v = 0
				if (value) {
					if (model.precision && (model.precision == '0' || model.precision == 0)) {
						v = parseInt(value)
						this.numValue = v
					} else {
						v = parseFloat(value)
						this.numValue = v
					}
					if (model.max !== null && model.max !== '' && v > model.max) {
						this.$logic.tip.error(model.name + '当前设定值:' + v + '超出允许最大值：' + model.max)
					}
					if (model.min !== null && model.min !== '' && v < model.min) {
						this.$logic.tip.error(model.name + '当前设定值:' + v + '超出允许最小值：' + model.min)
					}
				} else {
					this.numValue = 0
				}
			},

			parseDateTime(value) {
				let model = this.model
				if (value) {
					if (typeof(value) === 'string') {
						let ds = null
						switch (model.type) {
							case 'datetime':
								ds = value ? value : null //model.dateTime ? model.dateTime : null
								break
							case 'date':
								//补齐完整时间
								ds = value.indexOf(':') < 0 ? value + ' 00:00:00' : value
								break
							case 'time':
								if (value.length > 10) {
									ds = value
								} else {
									ds = value ? '1000-01-01 ' + value : null
								}


								break
						}
						this.timeValue = this.toDate(ds)
						if (this.timeValue && isNaN(this.timeValue.getTime())) { //如果是无效时间置为空值
							this.timeValue = null
						}
					} else if (Array.isArray(value)) { //忽略数组
						this.timeValue = null
					} else {
						this.timeValue = value
					}
				} else {
					this.timeValue = null
				}


			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					let rs = null
					switch (model.type) {
						case 'datetime':
							rs = this.toDateTimeString(this.timeValue)
							break
						case 'date':
							rs = this.toDateString(this.timeValue)
							break
						case 'time':

							rs = this.toTimeString(this.timeValue)
							break
						case 'number':
							rs = this.numValue
							break
						case 'sliderS':
							rs = this.sliderValue //parseFloat(model.value)
							break
						case 'sliderM':
							rs = this.sliderValue //[parseFloat(model.valueS), parseFloat(model.valueE)]
							break
						default:
							rs = this.textValue
					}
					return rs
				} else { //写入值

					if ('datetime,date,time,dateR,timeR'.indexOf(model.type) > -1) {
						this.parseDateTime(value)
						if (model.type.endsWith('R')) {

						} else {
							model.value = this.timeValue //需要立即更新值，否则用户侧设置值后要到下个周期触发了值更新才能取到
							this.linkUpdate('value')
						}
						this.upateDateFormatResult()

					} else if ('number' === model.type) {
						this.updateNumber(value)
						model.value = this.numValue
						this.linkUpdate('value')

					} else if ('sliderS' === model.type || 'sliderM' === model.type) {
						if (model.range == 'Y') {
							let vs = null
							if (Array.isArray(value)) {
								vs = value
							} else {

								vs = value.split(',')
							}

							if (vs == null || vs.length < 2) {
								this.$logic.tip.error('滑块组件请输入区间型数据，数组或逗号分隔的数值字符')
							} else {
								this.sliderValue = [parseInt(vs[0]), parseInt(vs[1])]
								model.valueS = this.sliderValue[0] //立即更新model属性值
								model.valueE = this.sliderValue[1]
								this.linkUpdate('valueS')
								this.linkUpdate('valueE')
							}

						} else {
							if (typeof(value) === 'number') {
								this.sliderValue = value
							} else if (typeof(value) === 'string') {
								this.sliderValue = parseInt(value)
							} else {
								this.$logic.tip.error('滑块值不是有效数值')
							}
							model.value = this.sliderValue //立即更新model属性值
							this.linkUpdate('value')
						}
						/*  */
					} else {
						this.textValue = value
						model.value = this.textValue //需要立即更新值，否则用户侧设置值后要到下个周期触发了值更新才能取到
						this.linkUpdate('value')
						/* 	if ('value' in model && model.value != value) {
								this.mode.value = value
							} */
					}

					//this.linkUpdate()
					return value
				}
			},
			upateDateFormatResult() {
				let fs = ''
				if (this.timeValue) {
					switch (this.model.type) { //区间型的输入还需要增加'datetime,date,time,dateR,timeR'
						case 'datetime':
							fs = this.$logic.date.toDateTimeString(this.timeValue)
							break
						case 'date':
							fs = this.$logic.date.toDateString(this.timeValue)
							break
						case 'time':

							fs = this.$logic.date.toTimeString(this.timeValue)
							break

					}


				}

				this.setData('fs', fs)
			}

		},
		watch: {
			dateInit(nv, ov) {
				this.setDefaultDateTime()
			},
			/* sliderValue(nv, ov) { //在拖动事件中处理,区间滑块作动态更新两端的值，用于校验
				if ('sliderM' === model.type) {

				}else{
					this.model.value = nv
					this.linkUpdate('value')
				}

				//if (this.isedit) {	}
			}, */
			textValue(nv, ov) {
				if (nv) {
					if (this.model.clearSpace === 'side') {
						if (typeof(nv) === 'string') {
							this.textValue = nv.trim()
						}
					} else if (this.model.clearSpace === 'all') {
						if (typeof(nv) === 'string') {
							this.textValue = nv.replace(/\s+/g, '')
						}

					}

				}

				if (this.isedit) {
					this.model.value = this.textValue
				} else {
					if (this.model.value !== this.textValue) { //判断有变化才更新，否则与$value方法中有重复的linkUpdate
						this.model.value = this.textValue
						this.linkUpdate('value')
					}

					//hidden自身不能触发change事件，在此实现
					if (this.model.type === 'hidden') {
						this.doEvent({
							eventName: 'change',
							value: nv
						}, '$event_change')
					}


					//
					//console.log(this.model,nv,ov)
					//	this.setData('value', this.textValue)
				}
			},
			numValue(nv, ov) {
				if (this.isedit) {
					this.model.value = nv
				} else {
					this.model.value = nv
					this.linkUpdate('value')
					//	this.setData('value', nv)
				}
			},
			timeValue(nv, ov) {

				if (this.isedit) {
					this.model.value = this.$value()
				} else {
					this.model.value = nv
					this.linkUpdate('value')
					//	this.setData('value', this.$value())
					this.upateDateFormatResult()
				}
			},
			/* sliderValue(nv,ov){
				
			}, */
			'model.valueS': {
				handler: function(nv, ov) {
					if (this.sliderValue != null && this.sliderValue.length > 0) {
						this.sliderValue[0] = parseInt(nv)
					}
					this.linkUpdate('valueS')

				}
			},
			'model.valueE': {
				handler: function(nv, ov) {
					if (this.sliderValue != null && this.sliderValue.length > 1) {
						this.sliderValue[1] = parseInt(nv)
					}
					this.linkUpdate('valueE')

				}
			},
			/* 'model.value': {
				handler: function(value, ov) {
					//this.$value(value)
					
					this.linkUpdate('value')
				}
			} */



			/* 			timeValue(nv, ov) {
							let model = this.model
							switch (model.type) {
								case 'datetime':
									//model.dateTime = this.toDateTimeString(this.timeValue)
									model.value = this.toDateTimeString(this.timeValue)
									break
								case 'date':
									//model.date = this.toDateString(this.timeValue)
									model.value = this.toDateString(this.timeValue)
									break
								case 'time':
									//model.time = this.toTimeString(this.timeValue)
									model.value = this.toTimeString(this.timeValue)
									break
							}
						},
						'model.value': {
							handler: function(nv, ov) {
								if ('datatime'.indexOf(this.model.type) > -1) {
									this.parseDateTime()
								}
							}

						},

						numValue(nv, ov) {
							this.model.numValue = nv
						},
						'model.numValue': {
							handler: function(nv, ov) {
								this.updateNumber()
							}
						} */

		},
		mounted() {

			if (!this.isedit) {
				if (this.$refs.inputObj && this.$refs.inputObj.focus) { //添加焦点的方法
					this.model.$focus = () => {
						this.$refs.inputObj.focus()
					}
				}
			}

		},
		created() {

			//this.numValue=this.toFloat(this.model.value)
			let model = this.model
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate

			}

			if ('sliderM' === model.type) {
				this.$value(model.valueS + ',' + model.valueE)
			} else if ('datetime'.indexOf(model.type) > -1) {
				let dateUtil = this.$logic.date
				if (model.init === 'now') {
					this.timeValue = new Date()
				} else if (model.init === 'set') {
					this.$value(model.value)
				} else if (model.init === 'yearF') {
					this.$value(dateUtil.getFirstDayOfYear(new Date()))
				} else if (model.init === 'yearL') {
					this.$value(dateUtil.getLastDayOfYear(new Date()))
				} else if (model.init === 'monthF') {
					this.$value(dateUtil.getFirstDateOfMonth(new Date()))
				} else if (model.init === 'monthL') {
					this.$value(dateUtil.getLastDateOfMonth(new Date()))
				} else if (model.init === 'weekF') {
					this.$value(dateUtil.getFirstDayOfWeek(new Date()))
				} else if (model.init === 'weekL') {
					this.$value(dateUtil.getLastDayOfWeek(new Date()))
				} else if (model.init === 'previous') {
					this.$value(dateUtil.getDateAddDays(new Date(), -1))
				} else if (model.init === 'next') {
					this.$value(dateUtil.getDateAddDays(new Date(), 1))
				} else {
					this.timeValue = null
				}
			} else {
				//console.log(model.id,model.value)
				this.$value(model.value)
			}

			/* 	if ('datetime,date,time,dateR,timeR'.indexOf(model.type) > -1) {
					this.setDefaultDateTime()
				} else if ('number' == model.type) {
					this.updateNumber()
				} */
		}
	}
</script>

<style scoped>
	.s {
		align-items: center;
	}

	.inputtext {
		/*
		outline: none;
		
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		outline: none;
		*/
		/* 	width: calc(100% - 2px);
		height: calc(100% - 2px); */
		background-color: var(--input-text-bgcolor);
		border-color: var(--input-text-bdcolor);
		outline: none;
		border: 0px;
	}

	.inputtext:focus {
		background-color: var(--input-text-bgcolorf);
		border-color: var(--input-text-bdcolorf);
	}




	.inputarea {
		height: var(--row-height);
		margin: 4px;
		width: calc(100% - 10px);
		background-color: rgba(255, 255, 255, 0.0);
		border: 1px solid #CCCCCC;
		/* 禁止右下角拖拽调整大小 */
		resize: none;
		outline: none;
		overflow: auto;
	}

	.inputarea:focus {
		border-bottom: 1px solid #0086B3;
		background-color: #FFFFD7;
	}

	.hiddenfield {
		height: 20px;
		width: 24px;
		font-size: 16px;
		border: dotted 1px #CCCCCC;
		/* 	background-color: #FFFFD7; */
	}


	:deep(.el-date-editor .el-input__prefix-inner) {
		/*  解决禁用手点图标仍能选择日期的bug  */
		pointer-events: none;

	}


	:deep(.el-textarea__inner) {
		width: 100%;
		height: 100%;
	}

	:deep(.el-input-number) {
		margin-left: 80px;
	}

	/*:deep(.el-slider__runway){
		width: 6px;
		 background-color: rgba(255, 255, 255, 0); 
	}*/
</style>