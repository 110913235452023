import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn' //用于日期的中文
import 'element-plus/dist/index.css'
//----------import 'element-plus/theme-chalk/dark/css-vars.css'
import './assets/fontawesome-pro-5.13.0-web/css/all.min.css'
import './assets/iconfont/iconfont.css'
import './css/public.css'
import './css/layout.css'
import * as Icons from '@element-plus/icons'
import App from './App.vue'
import router from './router'
import store from './store'
import Codemirror from "codemirror-editor-vue3";
import Clipboard from 'vue-clipboard3'

import print from 'vue3-print-nb'
//---------import * as DataV  from '@dataview/datav-vue3'

import CKEditor from '@ckeditor/ckeditor5-vue';
import './css/ckeditor.css'
import 'highlight.js/styles/atom-one-dark.css' //语法高亮
//------import 'highlight.js/styles/github-dark.css'
//---------import 'highlight.js/styles/gradient-dark.css'
//--------import 'highlight.js/styles/tokyo-night-dark.css'

import logic from './plugins/logic.js'
logic.logic.setStore(store)
const {toClipboard}=Clipboard()
logic.logic.setClipboard(toClipboard)
const app = createApp(App)
app.use(ElementPlus, {
  locale: zhCn,
})
/* app.use(DataV, { classNamePrefix: 'dv-' });
 */
//Element 图标要把人坑死，需要先导入引用组件才能使用，在此一次性全部导入
  for(let i in Icons){
	app.component(i,Icons[i])
}
/*
app.use(store).use(router).use(Codemirror).use(print)).use(logic).mount('#app')
*/
app.use(store).use(router).use(Codemirror).use(print).use(CKEditor).use(logic).mount('#app')//

