<template>
	<!-- <imgupload :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
 -->
	<div v-show="isedit" style="padding: 0px 20px;font-size:20px;">
		<i class="far fa-file-invoice-dollar"></i>
	</div>

</template>

<script>
	import formBase from '../../../formbase.js'
	import CommEvent from '../../../commevent.js'
	import logicUtil from '../../../../../plugins/logicutil.js'
	import ImgUpload from '../base/ImgUpload.vue'
	export default {
		mixins: [formBase, CommEvent],
		data() {
			return {
				/*{
					"words_result": [{
						"result": {
							"AmountInWords": [{
								"word": "壹万贰仟捌佰圆整"
							}],
							"InvoiceNumConfirm": [{
								"word": "99058370"
							}],
							"CommodityEndDate": [],
							"CommodityVehicleType": [],
							"CommodityStartDate": [],
							"CommodityPrice": [{
								"row": "1",
								"word": "12075.47"
							}],
							"InvoiceTag": [{
								"word": "其他"
							}],
							"NoteDrawer": [{
								"word": "李扬"
							}],
							"SellerAddress": [{
								"word": "北京市海淀区知春路113号1幢10层1102-4000805191"
							}],
							"CommodityNum": [{
								"row": "1",
								"word": "1"
							}],
							"SellerRegisterNum": [{
								"word": "91110108MA001U493E"
							}],
							"MachineCode": [{
								"word": "499914319187"
							}],
							"Remarks": [],
							"SellerBank": [{
								"word": "兴业银行海淀支行321230100100199604"
							}],
							"CommodityTaxRate": [{
								"row": "1",
								"word": "6%"
							}],
							"ServiceType": [{
								"word": "服务"
							}],
							"TotalTax": [{
								"word": "724.53"
							}],
							"InvoiceCodeConfirm": [{
								"word": "011002300611"
							}],
							"CheckCode": [{
								"word": "17385085408810596034"
							}],
							"InvoiceCode": [{
								"word": "011002300611"
							}],
							"InvoiceDate": [{
								"word": "2023年07月11日"
							}],
							"PurchaserRegisterNum": [],
							"InvoiceTypeOrg": [{
								"word": "北京增值税电子普通发票"
							}],
							"OnlinePay": [{
								"word": "●"
							}],
							"Password": [{
								"word": "0344*93<9925+>*41>>00--5+0-83/43+-13464766>*7-35/+-192/86344*93<9925+>*41>562/046<-353<3+-+4>7011+5519>-++>32+88"
							}],
							"Agent": [{
								"word": "否"
							}],
							"AmountInFiguers": [{
								"word": "12800.00"
							}],
							"PurchaserBank": [],
							"Checker": [{
								"word": "张竞"
							}],
							"City": [],
							"TotalAmount": [{
								"word": "12075.47"
							}],
							"CommodityAmount": [{
								"row": "1",
								"word": "12075.47"
							}],
							"PurchaserName": [{
								"word": "董"
							}],
							"CommodityType": [],
							"Province": [{
								"word": "北京"
							}],
							"InvoiceType": [{
								"word": "电子普通发票"
							}],
							"SheetNum": [],
							"PurchaserAddress": [],
							"InvoiceNumDigit": [],
							"CommodityTax": [{
								"row": "1",
								"word": "72453"
							}],
							"CommodityPlateNum": [],
							"CommodityUnit": [{
								"row": "1",
								"word": "项"
							}],
							"Payee": [{
								"word": "李扬"
							}],
							"CommodityName": [{
								"row": "1",
								"word": "*现代服务*咨询服务费"
							}],
							"SellerName": [{
								"word": "北京菜鸟无忧教育科技有限公司"
							}],
							"InvoiceNum": [{
								"word": "99058370"
							}]
						},
						"top": 0,
						"left": 0,
						"probability": 0.9761412144,
						"width": 699,
						"type": "vat_invoice",
						"height": 463
					}],
					"log_id": 1852316308701889523,
					"words_result_num": 1
				}
				*/


			}
		},

		methods: {
			ocr(url) {
				if (!this.model.apiKey) {
					this.$logic.tip.error('发票识别失败,未设置API Key')
					return
				}
				this.$logic.http.request('/api/client/ai/invoicemulti/', {
					apiKey: this.model.apiKey,
					filePath: url
				}).then(res => {
					let resultTo = this.model.resultTo
					//let targetType = resultTo.targetType
					let targetModel = null
					if (this.model.targetId) {
						targetModel = this.$logic.pageUtil.getElementById(this.page.formData, this.model.targetId)
						if (!targetModel) {
							this.$logic.tip.error('设置的发票识别数据回填组件ID不存在：' + this.model.targetId)
						}
					}

					let rs = JSON.parse(res.data).words_result

					if (targetModel && resultTo.invoiceTypes) {
						let invoiceMap = {}
						for (let item of resultTo.invoiceTypes) {
							invoiceMap[item.key] = item
						}
						for (let invoice of rs) {
							let invoiceConfig = invoiceMap[invoice.type]
							if (!invoiceConfig) {
								continue
							}
							let newRow = null
							if (targetModel.type === 'inputlist') {
								newRow = targetModel.$addRow()
							}
							for (let item of invoiceConfig.params) {
								if (item.target) {
									let value = invoice.result[item.key]
									if (Array.isArray(value)) {
										value = value[0]
									}
									if (value && typeof(value) === 'object') {
										value = value.word
									}
									let targetObj = this.$logic.pageUtil.getElementById(targetModel, item.target)
									if (!targetObj) {
										this.$logic.tip.error(invoice.label + '设置的数据回填组件ID不存在：' + item.target)
										continue
									}
									if (targetObj.$value) {
										targetObj.$value(value)
									} else {
										if (targetObj.type === 'inputhead' && targetObj.dataName) { //子表编辑器的列
											newRow[targetObj.dataName] = value
										}
									}

								}
							}
						}

					}
					this.doEvent({
						eventName: 'success',
						result: rs,
						url: url
					}, '$comp_success')




				}).catch(err => {
					//this.$logic.tip.error('发票识别失败：' + err.info)
					this.doEvent({
						eventName: 'error',
						errCode: err.code,
						errInfo: err.info,
						url: url
					}, '$comp_error')
				})

			}

		},
		watch: {

		},
		computed: {

		},
		components: {
			imgupload: ImgUpload
		},
		created() {
			if (!this.isedit) {
				this.model.$ocr = this.ocr
			}


		}
	}
</script>

<style scoped>

</style>