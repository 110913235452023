<template>
	<div class="suggest">
		<el-input :id="objId" v-model="inputValue" ref="suggest" @change="onChange" @input="toInput" @blur="toBlur"
			@focus="toFocus" @keydown="onKeyDown" @keyup="onKeyUp" @keypress="onKeyPress" @click="onClick" type="text"
			:placeholder="placeholder" :size="size" :prefix-icon="iconIh" :suffix-icon="iconIt"
			:disabled="status=='disabled'" :readonly="status=='read'" :clearable="clearable" @clear="onClear"
			style="width:100%;height: 100%;">

			<template v-if="iconOh && iconOh.length>0 ||  textOh && textOh.length>0" #prepend>
				<el-button v-if="iconOh && iconOh.length>0">
					<i v-if="iconOh" :class="iconOh" style="margin-right:0px"></i>
				</el-button>
				<span v-if="textOh && textOh.length>0">{{textOh}}</span>
			</template>
			<template v-if="iconOt && iconOt.length>0 ||  textOt && textOt.length>0" #append>
				<el-button v-if="iconOt && iconOt.length>0">
					<i v-if="iconOt" :class="iconOt" style="margin-right:0px"></i>
				</el-button>
				<span v-if="textOt && textOt.length>0">{{textOt}}</span>
			</template>
		</el-input>

		<div class="menu " :style="menuStyle">


			<template v-if="options">

				<template v-if="options.length>0">
					<template v-if="tableMenu && tableSet">
						<table border="1" class="tablelist" cellspacing="0" cellpadding="0">
							<tr class="tabletop">
								<td align="center" class=" colfirst">
									<div>#</div>

								</td>
								<template v-for="col in tableSet.heads">
									<td v-if="col.show !== false" :style="{minWidth:col.width?col.width:'100px'}">
										<div>
											{{col.label}}
										</div>
									</td>
								</template>
							</tr>

							<tr v-for="(item,i) in options" @click="toSelect(item)"
								:class="item===options[rowNo]?'trbody row-sel':'trbody'">
								<td class="tdhead colfirst">{{i+1}}</td>
								<template v-for="(col,j) in tableSet.heads">
									<template v-if="col.fieldName==='label'">
										<td :width="col.with" v-html="item.label"></td>
									</template>
									<template v-else>
										<td :width="col.with">
											{{item.data[col.field]}}
										</td>
									</template>

								</template>

							</tr>
						</table>
					</template>
					<template v-else>
						<div v-for="(item,index) in options" @click="toSelect(item)"
							:class="item===options[rowNo]?'item-row row-sel':'item-row'" v-html="item.label"></div>

					</template>

				</template>
				<template v-else>
					<div style="text-align: center;">无数据</div>

				</template>
			</template>
			<template v-else>
				<div style="text-align: center;"><i class="fad fa-spinner fa-pulse" style="margin-right:5px"></i>加载中...
				</div>
			</template>

		</div>
	</div>

</template>

<script>
	export default {
		props: {
			modelValue: {
				type: String
			},
			options: {
				type: Array,
				default: []
			},
			menuWidth: {
				type: String,
				default: null
			},
			menuHeight: {
				type: String,
				default: '200px'
			},
			tableMenu: {
				type: Boolean,
				default: false,
			},
			tableStyle: {
				default: null
			},
			maxlength: {
				type: Number,
				default: 0
			},
			size: {
				type: String,
				default: 'default'
			},
			iconIh: {
				type: String,
				default: ''
			},
			iconIt: {
				type: String,
				default: ''
			},
			status: {
				type: String,
				default: ''
			},
			clearable: {
				type: Boolean,
				default: ''
			},
			iconOh: {
				type: String,
				default: ''
			},
			textOh: {
				type: String,
				default: ''
			},
			iconOt: {
				type: String,
				default: ''
			},
			textOt: {
				type: String,
				default: ''
			},

			placeholder: {
				type: String,
				default: '请输入'
			},
			tick: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				objId: '0',
				menuLeft: 0,
				menuTop: 0,
				suggestWidth: 0,
				menuShow: false,
				loading: false, //加载状态,防止重复加载 
				timer: null, //定时器对象

				itemvalue: '',
				rowNo: -1

			}
		},
		methods: {
			toSelect(item) {
				this.$emit('update:modelValue', item.data.label)
				this.rowNo = -1
				this.menuShow = false
				this.$emit('choose', item.data)
			},
			toInput(value) {
				if (value) {
					clearTimeout(this.timer) //清除上次的定时器，

					this.timer = setTimeout(() => {
						this.$emit('update:modelValue', value)
						this.$emit('query', value)
						this.menuShow = true



					}, 500)
				} else {
					this.$emit('update:modelValue', value)
					this.menuShow = false
				}

			},
			toBlur() {
				this.$emit('blur')
				setTimeout(() => { //给一个时间点击菜单区
					this.menuShow = false
				}, 500)
			},
			onClick() {
				if (this.status === 'read') {
					return
				}
				/* if (this.modelValue || true) {
					this.rowNo = -1
					this.menuShow = !this.menuShow
				} */
				this.toInput(this.inputValue)
			},
			toFocus() {
				/* if (this.modelValue) {
					this.rowNo = -1
					this.menuShow = true
				} */
				this.$emit('focus')
			},
			onClear() {
				this.$emit('clear')
			},
			onChange(value) {
				this.$emit('update:modelValue', value)

				//this.$emit('choose', item.data)
				//
			},
			onKeyDown(e) {


				//this.$emit('keydown', e)
			},
			itemChoos(item) {
				this.$emit('update:modelValue', item.data.label)
				this.menuShow = false
				this.rowNo = -1
				this.$emit('choose', item.data)
			},
			onKeyUp(e) { //这类通用事件外层容器已自带
				if (e.key == 'Escape') {
					this.menuShow = false
					return
				}
				if (this.options && this.options.length > 0) {
					if (e.key == 'Enter') {
						if (this.rowNo > -1) {
							let item = this.options[this.rowNo]
							if (item) {
								this.itemChoos(item)
							}

						}

					} else if (e.key == 'ArrowDown') {
						this.rowNo++
						this.rowNo = this.rowNo % this.options.length
					} else if (e.key == 'ArrowUp') {
						this.rowNo--
						if (this.rowNo < 0) {
							this.rowNo = 0
						}
					}
				}
				if (this.menuShow) { //出现菜单下拉时阻止键盘事件向上冒泡
					//e.preventDefault();
					e.stopPropagation();
				}
				//this.$emit('keyup', e)
			},
			onKeyPress(e) {

				//this.$emit('keypress', e)
			},

		},
		computed: {
			inputValue: {
				get() {
					return this.modelValue
				},
				set(value) {
					this.$emit('update:modelValue', value)
				}

			},
			tableSet() {
				let ts = this.tableStyle
				if (this.tableMenu && ts) { //开启了表格菜单后才处理
					if (typeof(ts) === 'string') {
						try {
							return JSON.parse(ts)
						} catch (err) {
							this.$logic.tip.error('选项表格列表样式设置错误')
							return null
						}
					} else {
						return ts
					}
				} else {
					return null
				}
			},
			menuListHeight() {
				if (this.menuHeight) {
					if (typeof(this.menuHeight) === 'string') {
						return parseInt(this.menuHeight)
					} else {
						return this.menuHeight
					}
				} else {
					return 200
				}
			},
			menuListWidth() { //设置了宽度按指定宽度，否则采用输入框宽度
				if (this.menuWidth) {
					if (typeof(this.menuWidth) === 'string') {
						return parseInt(this.menuWidth)
					} else {
						return this.menuWidth
					}
				} else {
					return this.suggestWidth
				}

			},
			menuStyle() {
				let css = {
					display: this.menuShow ? '' : 'none'
				}
				css['--menu-left'] = this.menuLeft + 'px'
				css['--menu-top'] = this.menuTop + 'px'

				css['--menu-width'] = this.menuListWidth + 'px'
				css['--menu-height'] = this.menuListHeight + 'px'
				if (this.tableMenu && this.tableSet) {
					css['border-radius'] = '0px'
					css['padding'] = '0px'

				}
				return css
			}
		},
		watch: {
			menuShow(nv, ov) {
				if (nv) { //如果显示,计算屏幕所在位置
					let obj = document.getElementById(this.objId)
					let rect = obj.getBoundingClientRect()
					this.menuLeft = parseInt(rect.left - 11 + 0.5) //内部文本框所在的外部窗口中有左边距
					this.menuTop = parseInt(rect.top + obj.offsetHeight + 2 + 0.5)
					this.suggestWidth = obj.parentNode.clientWidth - 16
					let height = this.menuListHeight
					let width = this.suggestWidth
					if (this.menuWidth) {
						if (typeof(this.menuWidth) === 'string') {
							width = parseInt(this.menuWidth)
						} else {
							width = this.menuWidth
						}
					}
					let screenWidth = document.documentElement.clientWidth
					let screenHeight = document.documentElement.clientHeight
					if (this.menuLeft + width > screenWidth) {
						this.menuLeft = screenWidth - width
					}
					if (this.menuTop + height > screenHeight) {
						this.menuTop = screenHeight - height
					}
				}
			},
			options(newValue, oldValue) { //变化后改变当前项
				this.rowNo = -1

				if (newValue && newValue.length == 1) { //如果只有一个选项并且当前输入值文本等于选项文本，直接选中
					let item = newValue[0]
					if (item.data && item.data.label === this.inputValue) {
						this.itemChoos(item)
					}
				}

			},
			tick(nv, ov) {
				this.$refs.suggest.focus()
			}
		},
		created() {
			this.objId = 's' + new Date().getTime()
		}
	}
</script>


<style scoped>
	.suggest {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.menu {
		/* position: absolute; */
		position: fixed;
		top: var(--menu-top);
		left: var(--menu-left);
		width: var(--menu-width);
		/* width: calc(100% - 16px); */
		padding: 10px 5px 5px 10px;
		min-height: 40px;
		max-height: var(--menu-height);
		border: solid 1px #cccccc;
		border-top: 0px;
		border-radius: 0px 0px 10px 10px;
		background-color: #ffffff;
		box-shadow: 0px 5px 5px 0px #cccccc;
		z-index: 1000;
		overflow: auto;
		color: #606266;
	}

	.item-row {
		padding: 5px 0px;
	}

	.item-row:nth-of-type(even) {
		background-color: #fbfbfb;
		/* border-bottom: solid 1px #cccccc; */
	}

	.item-row:hover {
		background-color: #F2F6FC;
	}

	.row-sel {
		background-color: #ffffd7 !important;
	}


	.tablelist {
		min-width: 100%;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.tabletop {

		position: sticky;
		position: -webkit-sticky;
		top: 0px;
		background-color: #e7e7e7;
	}

	.tabletop>td {
		height: 30px;
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
		border-left: solid 1px #CCCCCC;
		cursor: pointer;

	}

	.tabletop div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.tdhead {
		text-align: center;
		background-color: #eaeaea;

		border-left: solid 1px #EEEEEE;
		border-top: solid 1px #EEEEEE;
		border-right: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.colfirst {
		width: 50px;
		min-width: 50px;
		box-shadow: 1px 0px #dfdfdf;
	}

	.headcell {
		position: relative;
		width: 100%;
		height: 100%;

		text-align: center;

	}

	.trbody {}

	.trbody:nth-child(odd) {
		background-color: #fafafa;
	}

	.trbody:hover {
		background-color: #F2F6FC;
	}

	.trbody>td {
		padding: 2px 5px;
	}
</style>