import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import extend from '../../public/extend.js'
let fits=[{label:'全图自由缩放',value:'fill'},{label:'全图等比缩放',value:'contain'},{label:'等比缩放填充',value:'cover'},{label:'原始大小超出剪裁',value:'none'},{label:'向下拉伸',value:'scale-down'},]
 let dataConfig1=compBase.getDataConfig() 
 let validate=compBase.getValidate() 
 validate.validateSet.exclude='input,blur,change'

let base = [
	{key: 'title',text: '发票识别'	},
    {key: 'apiKey',text: 'ApiKey',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
 //   {key: 'title',text: '扫一扫'	},   
 {key: 'wayType',text: '接口类型',type: 'select',init: 'baidu',	target: '',bursh: true,bind: true,tip: '',list: options.toOptions('百度AI=baidu')},
 
    {key: 'apiType',text: '识别类型',type: 'select',init: 'multiple',	target: '',bursh: true,bind: true,tip: '',list: options.toOptions('通用批量发票=multiple')},
 //{key: 'targetType',text: '回填类型',type: 'select',init: 'inputlist',	target: '',bursh: true,bind: true,tip: '',list: options.toOptions('子表编辑器=inputlist')},
 
 {key: 'targetId',text: '回填目标',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '表单容器ID或子表编辑器ID',list: null},
    {key: 'resultTo',text: '回填设置',type: 'fapiao',init: {},	target: '',bursh: true,bind: true,tip: '',list: null},
  
 
	]

let events=[
//	{key: '$comp_uploadBefore',text: '图片上传前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '上传前触发，如返回false 阻止上传，如返回字符有效文件名称字符患，将以此返回值作为文件名保存',list: null	},
	{key: '$comp_success',text: '识别成功',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '上传完成后触发',list: null	},
	{key: '$comp_error',text: '识别失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '上传完成后触发',list: null	},
	
]	
 
	
let faPiao = {
	data: {
		type: 'fapiao',
	},
	delete: '',
	hide: 'wayType',
	base: base,//.concat(compBase.base)
	special: [],
	action:events.concat(options.getEvents(null,'$hook'))  //options.getCommonEvents().concat(events).concat(options.getEvents(null,'$hook'))
}

export default faPiao