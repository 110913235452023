<template>
	<div :ref="'chart_'+model.id"    :style="chartSiz">

	</div>
</template>

<script>
	import echartBaes from '../echartsBase.js'
	export default {
		mixins: [echartBaes],
		data() {
			return {


			}
		},

		methods: {
			doUpdate(data) { //覆盖公共方法

				let dataSet = null
				if ('heads' in data) { //接口数据
					let head = []
					for (let item of data.heads) {
						head.push(item.label)
					}
					dataSet = head.concat(data.dataList)
				} else { //内置数据
					dataSet = data
				}

				this.updateOption(data)


			},
			optionFilter(option) { //处理种类组件特殊的配置项
				let model = this.model
				let series = option.series
				switch (model.type) {
					case 'chart_bar':
					case 'chart_barC':
						let a = -1,
							b = -1
						if (model.seriesBarStack !== 'none') {
							switch (model.seriesBarStack) {
								case 'all':
									a = 0
									b = option.series.length
									break
								case 'after':
									a = 1
									b = option.series.length
									break
								case 'befor':
									a = 0
									b = option.series.length - 1
									break

							}
						}
						for (let i = 0; i < option.series.length; i++) { //全部取消堆叠，
							if (i >= a && i < b) {
								option.series[i].stack = 'stack'
							} else {
								option.series[i].stack = null //设置值，防止图表内部使用上次的值
							}
						}


						break
					case 'chart_barH':
						option.yAxis.inverse = model.seriesRealtimeInverse === 'Y'
						/* //双轴：
						if (!('length' in option.xAxis)) {
							let x2 = this.$logic.clone.deepClone(option.xAxis)
							x2.name = 'x2'
							x2.position = 'top'
							option.xAxis = [option.xAxis, x2]
						}
							option.series[0].xAxisIndex =1
						 */
						break
					case 'chart_barB': //设置最大最小值，确保两端对称
						let max = 0,
							min = 0
						let ds = option.dataset.source
						for (let i = 1; i < ds.length; i++) {
							let row = ds[i]
							for (let j = 1; j < row.length; j++) {
								if (row[j] > max) {
									max = row[j]
								}
								if (row[j] < min) {
									min = row[j]
								}
							}
						}
						max = Math.abs(max)
						min = Math.abs(min)
						if (min > max) {
							max = min
						}
						option.xAxis.max = max
						option.xAxis.min = -max
						break
					case 'chart_line':
						let a1 = -1,
							a2 = -1
						if (model.seriesLineAreaIsFill !== 'none') {
							switch (model.seriesLineAreaIsFill) {
								case 'all':
									a1 = 0
									a2 = option.series.length
									break
								case 'first':
									a1 = 0
									a2 = 1
									break
								case 'last':
									a1 = option.series.length - 1
									a2 = option.series.length
									break
							}

						}
						for (let i = 0; i < option.series.length; i++) { //全部取消堆叠， 
							option.series[i].step = option.series[i].step === 'none' ? false : option.series[i].step
							if (i >= a1 && i < a2) {
								option.series[i].areaStyle.color = model.color[i % model.color.length]
							} else {
								option.series[i].areaStyle.color = 'rgba(255,255,25,0)'

							}

						}
						break
					case 'chart_barline':
						//取出柱线分类,首柱余线=barfirst,尾柱余线=barlast,N柱余线=barN,首线余柱=linefirst,尾线余柱=linelast,N线余柱=lineN'

						let bl = model.seriesBarLine
						let range = bl.indexOf('first') > -1 ? [0, 0] :
							bl.indexOf('last') > -1 ? [series.length - 1, series.length - 1] : [0, parseInt(model
								.seriesBarLineN) - 1]
						let type = bl.indexOf('bar') == 0 ? 'bar' : 'line'
						let ntype = type == 'bar' ? 'line' : 'bar'
						let barIdx = -1
						let barLast = null
						let lineIdx = -1
						let lineLast = null


						for (let i = 0; i < series.length; i++) {
							series[i].seriesIdx = i //记下每个系统的索引位置，以便于调色板取色
							if (i >= range[0] && i <= range[1]) { //根据分组设定设置每个系统的类型
								series[i] = series[i][type]
								series[i].type = type
							} else {
								series[i] = series[i][ntype]
								series[i].type = ntype
							}
							if (series[i].type == 'bar') { //如果是柱条类型设置堆栈
								barIdx++
								barLast = series[i]
								barLast.stack = null //先取消堆栈再 设置
								switch (model.seriesBarStack) {
									case 'all':
										barLast.stack = 'stack'
										break
									case 'after':
										if (barIdx > 0) {
											barLast.stack = 'stack'
										}
										break
									case 'befor':
										barLast.stack = 'stack' //先全部堆栈，最后移除最后一个
										break

								}
							}
							if (series[i].type == 'line') { //如果是曲线类型设置阶梯线、填充色、两佩空白
								lineIdx++
								lineLast = series[i]
								series[i].step = series[i].step === 'none' ? false : series[i].step
								//先取消填充再设置填充
								series[i].areaStyle.color = 'rgba(255,255,25,0)'
								switch (model.seriesLineAreaIsFill) {
									case 'all':
										series[i].areaStyle.color = model.color[i % model.color.length]
										break
									case 'first':
										if (lineIdx === 0) {
											series[i].areaStyle.color = model.color[i % model.color.length]
										}
										break

								}
							}

						}
						if (model.seriesBarStack === 'befor' && barLast) {
							barLast.stack = null
						}

						if (model.seriesLineAreaIsFill === 'last' && lineLast) {
							lineLast.areaStyle.color = model.color[lineLast.seriesIdx % model.color.length]
						}


						break

				}

			},

		},
		created() {


		}
	}
</script>

<style scoped>

</style>
