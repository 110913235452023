<template>
	<div v-show="model.show !=='none'" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave"
		@mouseup="mouseUp" :style="formStyle" :class="layoutcss">
		<template v-for="item in model.items">
			<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="bindData" />
			<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="bindData" />

		</template>

	</div>
</template>

<script>
	import security from '../../../../../../plugins/logicSecurity.js'
	import FlowUtil from '../../../../../../plugins/flowUtil.js'
	import CommEvent from '../../../../commevent.js'
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase, CommEvent],
		data() {
			return {
				bindData: null,
				formData: null,
				formId: -1
			}
		},

		methods: {
			//扩展方法，统一处理事件
			validateInfo(result) { //根据验证结果进行错误提示
				let model = this.model
				if (result.success === false) { //数据验证失败,用户设置了验证事件用验证事件，没设置用默认提示
					if (model.$functions && model.$functions.$comp_validateFailed) {
						this.doEvent({
							eventName: 'validatefailed',
							error: result.info,
						}, '$comp_validateFailed')
					} else {
						let tips = ''
						for (let info of result.info) {
							tips = tips + info + ' 。 '
						}
						this.$logic.tip.error('表单验证失败：' + tips)
					}
				}

			},
			submitBefor() {
				let check = this.doEvent({
					eventName: 'submitbefore',
					formId:this.model.dataId
					//formData: data,
					//url: url
				}, '$comp_submitBefore')
				if (check === false) {
					return false
				} else {
					return true
				}
			},
			submitAfter(ds) {
				this.doEvent({
					eventName: 'submitAfter',
					result: ds,
					//url: url
				}, '$comp_submitAfter')
			},
			submitError(rs) {
				this.doEvent({
					eventName: 'submitError',
					result: rs,
					//url: url
				}, '$comp_submitError')
			},



			formSubmit(recall = null) {
				if (this.model.dataId === null) {
					this.$logic.tip.error('无效的表单数据ID，无法提交') //:' + this.model.idParam + '
					return
				}
				let model = this.model
				let check = null
				check = this.validate() //_this.page.$checkFormData(_this.page,formId)	

				if (check.success === false) { //数据验证失败,用户设置了验证事件用验证事件，没设置用默认提示
					this.validateInfo(check)
					return
				}
				let recallFunc = recall && typeof(recall) === 'function' ? recall : (res) => {
					// 只处理成功的结果
					if (res.code == 0) {


						if (model.tell === 'Y') {
							this.$logic.tip.success(model.tip)
						}
						if (model.menuTo && model.menuTo.length > 0) {
							let pPage = this.host && this.host.parentPage
							if (!pPage) {
								this.$logic.tip.error('非子页面的表单，不支持自动菜单页面转向')
								return
							}
							pPage.$menuTo(model.menuTo, null, true)
						} else if (model.goto && model.goto.length > 1) {
							this.page.$to(model.goto)
							//alert('转向到：'+model.goto)
						}
					} else {
						this.$logic.tip.error('提交失败 ' + res.info)
					}
				}
				let param = {
					formId: model, //此参数可传ID或表单对象
					_this: this,
					recall: recallFunc
				}
				if (model.ask === 'Y') {
					this.$logic.pop.confirm('确认', model.message, () => {

						this.doFormSubmit(param)

					})
				} else {
					this.doFormSubmit(param)
				}

			},
			doFormSubmit(params = {
				formId: null,
				recall: null,
				url: null

			}) {
				if (!params || !params.formId) {
					this.$logic.tip.error('参数错误，未指定表单ID，无法进行此操作')
					return null
				}
				let model = this.model
				let url = params.url ? params.url : '/api/ds/formsubmit/'

				let ok = this.submitBefor()
				if (ok === false) {
					return
				}
				let data = this.formPick()
				if (!data) {
					return
				}

				if (data.dataId == null) {
					this.$logic.tip.error('无效的表单ID数据，请确认页面请求地址中存在表单容器中设置的参数名')
					return
				}
				
				//console.log(data)
				this.$logic.http.post(true, url, {
					appId: this.project ? this.project.id : 0,
					$dbSourceKey: this.project.dataSource.sourceKey //
				}, data, res => {

					let ds = {
						code: res.data.code,
						info: res.data.info,
						data: res.data.data
					}
					this.submitAfter(ds)
					if (params.recall) {
						params.recall(ds)
					}
				}, err => {
					//console.log(err)
					/* let rs = {
						code: err.code,
						message: err.info,
						data: err.data
					} */
					this.submitError(err)
					params.recall(err)
					/* if (model.$functions && model.$functions.$comp_submitError) {
						this.submitError(rs)

					} else {

					} */

				})
			},
			validate() {

				return this.$logic.pageUtil.formValidate(this.page, this.model)

			},
			clearValidate() {
				this.$logic.pageUtil.clearValidate(this.page, this.model)
			},
			getSqlList(sqlList) {
				let list = null
				if (sqlList) {
					if (Array.isArray(sqlList)) {
						list = sqlList
					} else {
						if ('sql' in sqlList) { //如果是SQL命令对象
							list = [sqlList]
						}
					}
				}
				//对SQL进行编码
				let sqls = []
				if (list) {
					for (let item of list) {
						if ('sql' in item) {
							sqls.push({
								data: security.encodeSql(item.sql),
								params: 'params' in item ? item.params : 'values' in item ? item.values : []

							})
						} else {
							this.$logic.tip.error('表单容器获取到的附加SQL格式错误')
							return null
						}

					}
				}
				return sqls
			},
			formPick() {
				let formData = this.$logic.pageUtil.getFormData(this.page, this.model)
				let beforeSqls = this.doEvent({
					eventName: 'getBeforeSql',
					formId: formData.dataId,
					//formData: data,
					//url: url
				}, '$comp_getBeforeSql')

				beforeSqls = this.getSqlList(beforeSqls)
				//获取需在后端执行的SQL
				let afterSqls = this.doEvent({
					eventName: 'getAddSql',
					formId: formData.dataId,
				}, '$comp_getAfterSql')
				afterSqls = this.getSqlList(afterSqls)

				formData.doBefore = beforeSqls
				formData.doAfter = afterSqls
				//console.log(formData)
				return formData
			},
			$load(formId) {
				/* 		let id = formId || this.model.dataId
						if (id < 0) {
							//this.$logic.tip.error('无效的表单ID参数值，无法加载数据')
							return
						} */
				this.loadFormData(formId)
				/* 	let subItems = []
					let sk = [].concat(this.model.items)
					while (sk.length > 0) {
						let node = sk.pop()
						if (node.$load) {
							subItems.push(node)
						} else {
							if (node.items) {
								for (let item of node.items) {
									sk.push(item)
								}
							}
						}
					}
					for (let item of subItems) {
						item.$load(id)
					} */

			},
			loadFormData(formId) {

				let model = this.model
				if (!(model.dbTable && model.dbTable.length > 0 && model.idField.fieldName && model.idField.fieldName
						.length > 0)) {
					this.$logic.pop.message('确认', '请设置数据表名与表ID字段')
					return
				}

				//if (this.formData) { //如果是重新载入，先清空数据 
				this.init()
				//}
				if (!formId || formId == '0') { //parseInt(formId) < 1
					return
				}
				let sql = this.doEvent({
					eventName: 'loadbefore',
					formId: formId,
				}, '$comp_loadBefore')

				if (!(sql && sql.length > 10)) {
					sql = 'select * from ' + this.model.dbTable + ' where ' + this.model.idField.fieldName + '=?'
				}
				this.model.dataId = null //如果加载失败表单就不能提交
				 
				this.$logic.http.sqlQuery(this.project.dataSource.sourceKey, sql, [formId], {
					isJson: true
				}).then(res => {
					if (res.data.data.dataList.length < 1) {
						//this.$logic.pop.message('错误', '不存在的表单ID：' + formId)

						let rs = this.doEvent({
							eventName: 'loadEmpty',
							dbTable: this.model.dbTable,
							idField: this.model.idField.fieldName,
							dataId: formId
						}, '$comp_loadEmpty')

						if (rs) { //忽略错误

						} else {
							if (rs === false) { //不忽略不自动提示

							} else { //未处理，自动提示
								this.$logic.tip.error('不存在的表单ID：' + formId)
							}
							return
						}

					}

					this.model.dataId = formId
					let data = res.data.data.dataList.length > 0 ? res.data.data.dataList[0] : {}
				 
					let config = this.getConfig()
					let sk = [].concat(this.model.items)
					while (sk.length > 0) { //为表单组件赋值
						let obj = sk.pop()
						if (obj.$flowInit) { //流程发起时不覆盖表单默认值，此属性在FlowPage.vue中设置						
							continue
						}
						//子组件加载数据
						if (obj.$load) {
							obj.$load(formId)
						}
						//处理输入型的元素赋值
						if (obj.isInput === 'S' || obj.isInput === 'M' || obj.isInput === 'L') {
							/* if (obj.$fresh) {//数据加载完后更新组件内部数据，组件自行实现些方法,如:联想输入组件
								obj.$fresh()
							} */

							let cfgs = config.objs[obj.type].configs
							for (let cfg of cfgs) {
								if (cfg.type === 'field') { //根据字段加载数据
									let field = obj[cfg.key]
									if (obj.isInput === 'M') {
										obj.$set(cfg.attribute, data[field.fieldName])
									} else {
										if(obj.$value){
											obj.$value(data[field.fieldName])
										}else{
											obj[field.fieldName]=data[field.fieldName]
										}
										
									}
								}
							}

						} else {
							if (obj.items) {
								for (let so of obj.items) {
									sk.push(so)
								}
							}
						}

					}

					this.bind(data, true)
					this.dataTo(model.dataTo, data)
					if (model._dataLoadRecall) { //如果存在回调函数执行回调,用于流程模拟器上切换用户权限，否则执行表单权限处理功能
						model._dataLoadRecall(model, this.project, data)

					} else {
						let user = this.$logic.getUser()
						FlowUtil.accessFilter(null, model, user.id, this.project, data)
					}
					this.bindData = data
					this.formData = data
					//console.log(data)
					this.doEvent({
						eventName: 'loadafter',
						data: data,
					}, '$comp_loadAfter')

				}).catch(err => {
					console.log(err)
					this.$logic.tip.error('数据请求异常：' + err.info)
				})
			},
			init() { //初始化表单

				//console.log(this.bindData,this.formData)
				if (this.bindData) {
					let bindData = {
						...this.bindData
					}
					for (let key in this.bindData) {
						if (this.formData && (key in this.formData)) { //仅清除表单中存在的数据
							//bindData[key] = null
							bindData[key] = null
						}
					}
					this.bind(bindData, true)
				}
				this.formData = null //清除当前表单加载的数据
				this.model.dataId = 0
				//this.bindData = bindData
				let sk = [].concat(this.model.items)
				while (sk.length > 0) { //为表单组件赋值
					let obj = sk.pop()

					if (obj.$init) { //初始化组件
						obj.$init()
					}
					if (obj.items) {
						for (let sub of obj.items) {
							sk.push(sub)
						}
					}
				}

			}



		},
		computed: {
			formStyle() {
				let model = this.model
				let css = this.layoutstyle
				if (this.isedit) {
					//css.border = this.isedit ? 'dotted 1px ' : '0px'
					css.border = 'dotted 1px '
				}

				return css
			},
		},
		/* 	beforeDestroy(){
				console.log('beforeDestroy')
			},
			beforeUpdate(){
				console.log('beforeUpdate')
			}, */
		mounted() {
			if (this.isedit) {
				return
			}
			let model = this.model
			let dataId = null
			if (this.model.idParam) { //网址中ID参数值将设置到当前表单 

				if (this.model.idParam in (this.page.$params || {})) {
					dataId = this.page.$params[this.model.idParam]
				}

			} else {
				this.$logic.tip.error('未指定表单ID参数名')
			}
			dataId = dataId ? dataId : 0

			if ((dataId === null || dataId === '')) {
				/* if (!this.isedit) {
					setTimeout(() => {
						this.$logic.tip.error('无效的表单ID参数，请指定表单ID参数名，并确保请求地址中有值')
					}, 1000)
				} */
			} else {

				model.dataId = dataId //预留非数值型Id的可能
				//this.formId = dataId
				if (!model.idField.fieldName) { // || model.idField.unionName != 'int8'
					this.$logic.pop.message('提示', '请指定表单对应数据库表的ID字段，建议ID字段为长整型(8位)')
				} else {
					if (dataId) { //有值才调用 

						this.loadFormData(model.dataId)
					}

				}

			}
		},
		created() {
			if (this.model) {

				let model = this.model

				//编辑模式下修改默认事件指向
				if (this.isedit) { //如果元素中增加了动态事件或动态属性，计算属性的值不会直接自动更新到视图，其它容器暂取消动态配置
					/* 					let map = this.eventsMap
										map.$event_click = {
											name: 'click',
											handler: this.mouseClick
										}
										map.$event_mousedown = {
											name: 'mousedown',
											handler: this.mouseDown
										}
										map.$event_mouseup = {
											name: 'mouseup',
											handler: this.mouseUp
										}
										map.$event_mouseleave = {
											name: 'mouseleave',
											handler: this.mouseLeave
										}
										map.$event_mouseover = {
											name: 'mouseover',
											handler: this.mouseOver
										}
					 */
				} else {
					if (model.type == 'form') {} //为表单添加提交方法
					this.model.$set = this.setData
					model.$submit = this.formSubmit
					model.$pick = this.formPick
					model.$validate = this.validate
					model.$validateInfo = this.validateInfo
					model._submitBefor = this.submitBefor
					model._doFormSubmit = this.doFormSubmit
					model._submitAfter = this.submitAfter
					model._submitError = this.submitAfter
					model.$clearValidate = this.clearValidate
					model.$load = this.$load
					model.$init = this.init


					//this.initEvents()
					this.createInit()
				}
				/* this.doEvent({
					eventName: 'create'
				}, '$hook_create') */


			}
		}
	}
</script>

<style scoped>

</style>