<template>

	<commtable v-if="model.type=='commtable'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<treetable v-else-if="model.type=='treetable'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<foldtable v-else-if="model.type=='foldtable'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<crosstab v-else-if="model.type=='crosstab'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<div v-else>未知报表元素</div>
</template>

<script>
	import CommTable from './CommTable.vue'
	import TreeTable from './TreeTable.vue'
	import FoldTable from './FoldTable.vue'
	import Crosstab from './Crosstab.vue'

	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		//props: ["model", "datas", "isedit"],
		data() {
			return {
				superComp: true
			}
		},

		methods: {

		},
		created() {


		},
		components: {
			commtable: CommTable,
			treetable: TreeTable,
			foldtable: FoldTable,
			crosstab:Crosstab

		},

	}
</script>

<style scoped>

</style>