<template>
	<div :style="compStyle" @click="headSelect=null"><!-- :head-list="model.dataSet.headItems"
			:order-items="model.dataSet.orderItems" :base-sql="model.dataSet.sql" -->
		<div ref="querybar" v-show="model.allowQuery==='Y'">
			<reportquery ref="queryRef" @query="toQuery" @setorder="setOrder" @buttonclick="buttonClick" :page="page"
				:project="project" :report-model="model" :get-custom-sql="getCustomSql" :query-set="model.dataSet"
				:params="urlParams" :auto-query="model.autoQuery" :allow-advance="model.advanceQuery==='Y'"
				:allow-head="model.headCustom !='Y'" :allow-order="model.allowOrder==='Y'" label-align="right"
				:item-width="model.queryItemWidth" :buttons="buttons" :form-do="formDo"
				:fold-button="model.foldButton==='Y'" :isedit="isedit" />
		</div>
		<div ref="tablearea" class="table-area" :style="tableStyle">
			<a ref="reportdown" href="" style="display:none;"></a>
			<div v-if="model.customSet==='Y'" @click.stop="setCurrentElement(model.dataSet.listPanel)"
				:style="listPanelStyle">
				<template v-if="isedit" v-for="item in 3">
					<container :model="model.dataSet.itemPanel" :project="project" :page="page" :host="host"
						:isedit="isedit" :datas="null" />
				</template>


				<template v-else v-for="(row,index) in dataRows">
<!-- @click="rowClick(row,index)" @dblclick="rowDblClick(row,index)" -->
					<container :model="row.$cellModel.$itemPanel" :project="project" :page="page" :host="host"
						 :isedit="isedit" :datas="row" />

				</template>
			</div>

			<table v-else ref="listTable" @mouseup="headSizeFinish" :border="model.lineShow=='Y'?1:0" class="tablelist"
				cellspacing="0" cellpadding="0">
				<template v-if="model.headShow=='Y'">
					<tablehead v-if="model.headCustom==='Y'" @reset="headReset" :fixed="model.fixAllow==='Y'"
						:init-cols="initHeadCols" init-bgcolor="#f8f8f8" :table-host="model" :deep="0"
						:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas"></tablehead>
					<tr v-if="model.headCustom==='N'" @mousemove="headSizeMove" class="tabletop">
						<td v-if="hasTool" class="col-tool">
							<div>
								<input v-if="model.allowCheck ==='M'" v-model="selectStatus" type="checkbox"
									@change="setItemCheck" /><!-- || formDo && formDo.action -->
							</div>
						</td>
						<td v-if="model.showNum=='Y'" class="colfirst">
							<div>

								序号
							</div>

						</td>
						<template v-for="head in visibleHeads">
							<td :ref="'head'+head.id" @click.stop="headClick(head)" class="datacol noselect"
								:style="{width:head.width,minWidth:head.minWidth || model.colMinWidth ,fontFamily:head.fontName,fontSize:head.fontSize,'--title-text-color':head.fontColor,fontWeight:head.fontBold,backgroundColor:head.bgColor}">
								<div :class="headSelect===head?'current-col':''">
									<div v-if="head.allowOrder==='Y'" @click="headSort(head)"
										style="width:20px;font-size: 16px;">
										<i v-if="sortHead===head && head.order==='asc'" class="fad fa-sort-up"></i>
										<i v-else-if="sortHead===head && head.order==='desc'"
											class="fad fa-sort-down"></i>
										<i v-else class="fad fa-sort"></i>
										<!-- <div></div><i class="fas fa-sort-up"></i>
										<div></div> -->
									</div>
									<div
										:style="head.allowOrder==='Y'?'width:calc(100% - 26px);':'width:calc(100% - 6px);'">
										{{head.label}}
									</div>
									<div @mousedown="headSizeStart($event,head)" style="width:6px;cursor:e-resize;">
									</div>
								</div>

							</td>

						</template>
						<td v-if="hasEdit" class=" col-edit">
							<div>
								编辑
							</div>

						</td>
					</tr>
				</template>
				<tr v-if="isedit " class="row-data">
					<td v-if="hasTool" class="col-tool">
						<div>
							<i v-show="model.allowFold==='Y'" class="fas fa-caret-right fold-botton"></i>
							<input v-if="model.allowCheck !='N'" type="checkbox" />
							<!-- ||  formDo && formDo.action -->

						</div>
					</td>
					<td v-if="model.showNum=='Y'" class="col-disable">
						<div>

							模板
						</div>
					</td>
					<td v-for="head in visibleHeads" :style="{verticalAlign:head.vAlign,textAlign:head.hAlign}"
						:class="head.mode==='custom'?'col-design':'col-disable'">
						<container v-if="head.mode==='custom' && head.panel" :model="head.panel" :project="project"
							:page="page" :host="host" :isedit="isedit" :datas="null" />

						<div v-else>
							#{{head.typeGroup==='text'?'文本':head.typeGroup==='num'?'数值':head.typeGroup==='time'?'时间':'未知'}}
						</div>
					</td>
					<td v-if="hasEdit" class="col-edit">
						<div>
							<i v-if="model.rowEdit==='Y'" class="fas fa-pen fold-botton"></i>
							<i v-else-if="model.editButton==='Y'" class="fas fa-edit fold-botton"></i>

							<i v-show="model.delButton==='Y'" class="fas fa-times fold-botton"></i>
						</div>

					</td>
				</tr>
				<tr v-if="isedit && model.allowFold==='Y'">
					<td v-if="hasTool"><!-- 保留一个缩进宽度 -->

					</td>
					<td :colspan="foldColSpan">
						<container :model="model.dataSet.foldPanel" :project="project" :page="page" :host="host"
							:isedit="isedit" :datas="null" />
					</td>
				</tr>
				<template v-for="(row,index) in dataRows">
					<tr class="row-data" @click="rowClick(row,index)" @dblclick="rowDblClick(row,index)"
						:style="{zIndex:1,'--table-text-color':row.$color,backgroundColor:row.$bgColor?row.$bgColor:model.checkColor && row.$check?model.checkColor:model.clickColor && row===currentRow?model.clickColor:null}">
						<td v-if="hasTool" class="col-tool  ">

							<i v-if="model.allowFold==='Y'" @click="rowFold(row)"
								:class="row.$cellModel.$fold?'fas fa-caret-down fold-botton':'fas fa-caret-right fold-botton'"></i>
							<input v-if="model.allowCheck !='N'" @change="rowChecked(row)" v-model="row.$check"
								type="checkbox" /><!-- ||  formDo && formDo.action -->
							<!-- <div class="fix-col-content">
							</div> -->
						</td>
						<td v-if="model.showNum=='Y'" class="col-index ">

							{{row.$rowNo}}
							<!-- <div class="fix-col-content">
							</div> -->
						</td>
						<td v-for="head in visibleHeads" class="td-data "
							:style="{verticalAlign:head.vAlign,textAlign:head.hAlign}" @click="dataClick(row,head)">
							<container v-if="head.mode==='custom' && head.panel" :model="row.$cellModel[head.fieldName]"
								:project="project" :page="page" :host="host" :isedit="isedit" :datas="row" />
							<!-- <div class="fix-col-content"
								:style="{verticalAlign:head.vAlign,textAlign:head.hAlign}"></div> -->
							<span v-else class="data-span">
								{{row[head.fieldName]}}
							</span>

						</td>
						<td v-if="hasEdit" class="col-edit">
							<!-- <div style="display:flex;justify-content: space-between;"></div> -->
							<template v-if="model.rowEdit==='Y' ">
								<i v-show="!row.$edit" @click="setRowEdit(row,true)" class="fas fa-pen fold-botton"></i>

								<i v-show="row.$edit" @click="updateRowData(row)" class="fas fa-check fold-botton"
									title="提交保存"></i>
								<i v-show="row.$edit" @click="setRowEdit(row,false)" class="fas fa-undo fold-botton"
									title="取消保存"></i>

							</template>
							<template v-else-if="model.editButton==='Y'">
								<i @click="doForm('edit',row)" class="fas fa-edit fold-botton" title="编辑此数据"></i>

							</template>
							<i v-if="model.delButton==='Y' && !row.$edit" @click="doForm('del',row)"
								class="fas fa-times fold-botton" title="删除此数据"></i>

						</td>
					</tr>
					<tr v-if="model.allowFold==='Y'" v-show="row.$cellModel.$fold">
						<td v-if="hasTool" class="fix-col"><!-- 保留一个缩进宽度,这个宽度是冻结的 -->

						</td>
						<td :colspan="foldColSpan">
							<container v-if="row.$cellModel.$foldCreated" :model="row.$cellModel.$foldPanel"
								:project="project" :page="page" :host="host" :isedit="isedit" :datas="row" />
						</td>
					</tr>
				</template>


			</table>
			<!-- <div ref="headFixArea">
				<table ref="headFixTable" @mouseup="headSizeFinish" :border="model.lineShow=='Y'?1:0"
					 class="tablelist" style="min-width: unset;" cellspacing="0" cellpadding="0">
					
				</table>
			</div> -->
		</div>
		<div ref="pagearea" v-if="model.allowPage==='Y' " class="foot-page-bar">
			<el-pagination v-if="model.pageType==='num'" v-model:currentPage="currentPage" v-model:page-size="pageSize"
				:small="model.pageSmall==='Y'" :disabled="false" :background="model.pageBackground==='Y'"
				layout="total, sizes, prev, pager, next, jumper" :total="rowsTotal" @size-change="pageSizeChange"
				@current-change="pageCurrentChange" />
			<div v-else-if="model.pageType==='next'">
				<el-link v-show="currentPage>1" @click="pageCurrentChange(1)" type="primary"
					style="margin-right: 10px;"><i class="far fa-arrow-alt-to-left"></i></el-link>
				<el-link :disabled="currentPage<2" @click="pageCurrentChange(currentPage-1)" type="primary"
					style="margin-right: 10px;"><i class="far fa-chevron-left" style="margin-right: 5px;"></i>
					上一页
				</el-link>
				<span>
					{{currentPage}}
				</span>
				<el-link :disabled="model.pageDataCount!=model.pageSize" @click="pageCurrentChange(currentPage+1)"
					type="primary" style="margin-left: 10px;">下一页<i class="far fa-chevron-right"
						style="margin-left: 5px;"></i></el-link>

			</div>
		</div>


		<logicdialog ref="formEditDialog" @ok="formEditDialogOk" @close="formEditDialogClose"
			@buttonclick="dialogButtonClick" :auto-close="false"
			:title="model.dialogTitle?model.dialogTitle:buttonType=='add'?'创建新数据':buttonType=='view'?'表单查看':'表单编辑'"
			:dialog-height="model.editHeight" :dialog-width="model.editWidth"
			:icon="buttonType=='view'?'far fa-search':'far fa-edit'" :show-ok="buttonType!='view'"
			:buttons="buttonType=='add' && model.editContinue==='Y'?addButtons:[]">
			<container :model="subPage" :project="project" :page="page" :host="host" :isedit="isedit" :datas="{}" />

		</logicdialog>

	</div>


</template>

<script>
	import util from '../../../../../plugins/logicutil.js'
	import TableHead from './TableHead.vue'
	import ReportQuery from './ReportQuery.vue'
	//	import ReportSet from '../../../../comm/report/ReportSet.vue'
	//import LogicDiv from '../layout/sub/Div.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		emits: ['dataload'],
		data() {
			return {
				resizeHead: null,
				resizeWidth: 0,
				resizeX1: 0,
				sortHead: null,
				currentRow: null,
				pageModel: null,
				subPage: null,
				formModel: null,
				buttonType: null, //创建、查看、编辑、删除的当前点南功能按钮 
				headSelect: null,
				dataRows: [],

				pageSize: 10,
				currentPage: 1,
				rowsTotal: 100,
				urlParams: {},
				sql: null,
				params: [],

				selectStatus: false,
				addButtons: [{
					"key": "next",
					"text": "添加并继续",
					"icon": "far fa-plus",
					"type": "success",
					"enabled": "Y",
					"loading": "N"
				}]



			}
		},

		methods: {

			headSizeStart(event, head) {
				this.resizeHead = head
				let headRef = this.$refs['head' + head.id]
				//console.log(headRef.offsetWidth)
				this.resizeWidth = headRef.offsetWidth //      0 //head.minWidth
				this.resizeX1 = event.screenX
				//console.log(event)
			},
			headSizeMove(event) {
				if (this.resizeHead) {
					let offsetX = event.screenX - this.resizeX1
					this.resizeHead.minWidth = (this.resizeWidth + offsetX) + 'px'
					this.resizeHead.width = this.resizeHead.minWidth
				}
			},
			headSizeFinish(event) {
				this.resizeHead = null
				if (this.model.fixLeft || this.model.fixRight) { //如果设置了冻结就重新处理
					setTimeout(() => {
						this.setTableColFix()
					}, 1000)

				}

			},

			clearValidate() {
				if (!this.subPage || !this.subPage.$getPage) {
					return
				}
				let formPage$ = this.subPage.$getPage().$
				let forms = formPage$('.form')
				if (forms.length < 1) {
					return
				} else {
					for (let form of forms) {
						if (form.$clearValidate) {
							form.$clearValidate()
						}
					}

				}
			},
			formEditDialogClose(confirm = true) {
				let model = this.model
				let check = this.doEvent({
					eventName: 'closeBefore',
				}, '$comp_closeBefore')
				if (check === false) {
					return
				}
				if (this.buttonType == 'edit' && confirm) {

					this.$logic.pop.confirm('确认', '将丢失当前的内容，是否确定退出编辑', () => {
						this.clearValidate() //取消表单的验证错误状态，以免提示信息出现在屏幕
						this.$refs.formEditDialog.hide()

					})
				} else {
					this.clearValidate() //取消表单的验证错误状态，以免提示信息出现在屏幕
					this.$refs.formEditDialog.hide()
				}

			},
			dialogButtonClick() {
				this.formEditDialogOk(true)
			},
			formEditDialogOk(next) {
				let formPage$ = this.subPage.$getPage().$
				let forms = formPage$('.form')
				if (forms.length < 1) {
					this.$logic.tip.error('设置的编辑页面中不存在表单容器，无法进行提交保存')
					return
				} else {
					let form = forms[0]
					if (!form.$submit) {
						this.$logic.tip.error('表单容器的事件尚未初始化')
						return
					}
					let check = this.doEvent({
						eventName: 'okBefore',
					}, '$comp_okBefore')
					if (check === false) {
						return
					}

					form.$submit((res) => {
						if (res.code == 0) {
							if (next === true) {
								this.subPage.$fresh()
							} else {
								this.doQuery()
								this.$refs.formEditDialog.hide()
							}

							this.$logic.tip.success(form.tip)
						} else {
							this.$logic.tip.error('提交失败：' + res.info)

						}
					})
				}
				//this.formModel.$validate()
				/* 			let model = this.model
				let param = {
					formId: this.formModel,
					_this: this,
					recall: () => {
						this.$logic.tip.success('提示成功')
						this.doQuery(1)
						this.$refs.formEditDialog.hide()


					}
				}
				if (model.confirmInfo) {
					if (this.formModel.$validate().success) {
						this.$logic.pop.confirm('确认', model.confirmInfo, () => {
							this.$logic.http.formSubmit(param)
						})
					}
				} else {
					this.$logic.http.formSubmit(param)
				}
 */



			},
			/* 			toEdit(formId){
							
						}, */
			doForm(type, row = null) { //指定了行处理此行

				if (type == 'excel') {
					this.exportExcel()
					return
				}
				if (this.formModel && this.formModel.idField && this.formModel.idField.fieldName) {

				} else {
					if (type == 'add' || type == 'view' || type == 'edit' || type == 'del') {
						this.$logic.tip.error('编辑页面中未获取到表单的Id字段，请检查表单容器的数据表配置')
						return
					}

				}
				let idName = this.formModel.idField.fieldName

				if (type == 'add') {
					this.formAdd()

				} else if (type == 'view' || type == 'edit' || type == 'del') {
					let checkItems = []
					if (row) {
						checkItems.push(row)
					} else {
						for (let item of this.dataRows) {
							if (item.$check) {
								checkItems.push(item)
							}
						}
					}

					if (checkItems.length < 1) {
						this.$logic.tip.error('请选择数据项')
						return
					}
					if (type == 'edit') {
						this.formEdit(checkItems[0][idName])
					} else if (type == 'view') {
						this.formView(checkItems[0][idName])
					} else if (type == 'del') {
						let ids = []
						for (let item of checkItems) {
							ids.push(item[idName])
						}
						this.formDel(ids, checkItems)
					}

				}


			},
			exportExcel() {
				let cols = []
				for (let item of this.model.dataSet.headItems) {
					if (item.mode === 'custom') { //只导出数据列
						continue
					} else {
						cols.push({
							label: item.label,
							field: item.fieldName
						})
					}
				}
				let option = {
					size: this.model.excelSize,
					fileName: '',
					cols: cols,
					showLayer: true
				}
				//console.log(this.sql)
				this.$logic.http.sqlExport(this.dbSourceKey, this.sql, this.params, option)
					.then(res => {
						//console.log(res.data)
						let link = this.$refs.reportdown
						if (link) {
							let url = res.data.data
							let x = url.indexOf('.')
							link.download = this.model.name + url.substring(x)
							link.href = url
							link.click()
						} else {
							window.open(res.data.data)
						}


					}).catch(err => {
						let info = err.info ? err.info : err.message
						this.$logic.tip.error('数据查询处理异常：' + info)
					})




			},
			formAdd() { //固定值ID为0
				this.buttonType = 'add'
				this.openSubPage(0, this.buttonType)
			},
			formView(id) { //单值
				if (!id) {
					this.$logic.tip.error('无法执行查看操作，无效的数据项ID值')
					return
				}
				this.buttonType = 'view'
				this.openSubPage(id, this.buttonType)
			},
			formEdit(id) { //单值
				if (!id) {
					this.$logic.tip.error('无法执行编辑操作，无效的数据项ID值')
					return
				}
				this.buttonType = 'edit'
				this.openSubPage(id, this.buttonType)
			},
			formDel(id, items) { //数组或单值
				if (!id) {
					this.$logic.tip.error('无法执行删除操作，无效的数据项ID值')
					return
				}
				let ids = Array.isArray(id) ? id : [id]
				let formTable = this.formModel.dbTable
				let formField = this.formModel.idField.fieldName
				let sqls = []
				let subLists = this.$logic.pageUtil.getElements(this.formModel.items, '.inputlist', false)
				let fileLists = this.$logic.pageUtil.getElements(this.formModel.items, '.upload', false)

				for (let formId of ids) {
					sqls.push({
						sql: 'delete from ' + formTable + ' where ' + formField + '=?',
						params: [formId]
					})
					for (let sub of subLists) {
						let subTable = sub.dbTable
						//let subIdField=sub.idField.fieldName
						let subPidField = sub.pidField.fieldName
						sqls.push({
							sql: 'delete from ' + subTable + ' where ' + subPidField + '=?',
							params: [formId]
						})
					}
					for (let file of fileLists) {
						let fileTable = file.dbTable
						let filePidField = file.pidField.fieldName
						sqls.push({
							sql: 'delete from ' + fileTable + ' where ' + filePidField + '=?',
							params: [formId]
						})
					}

				}
				//自定义验证

				let check = this.doEvent({
					eventName: 'delBefore',
					ids: ids,
					items: items
				}, '$comp_delBefore')
				if (check === false) {
					return
				}

				if (this.model.deleteInfo) {
					this.$logic.pop.confirm('确认', '是否确定删除此数据', () => {

						this.page.$sql.do(sqls).then(res => {
							this.doQuery(1)
						}).catch(err => {
							this.$logic.tip.error('操作失败：' + err.info)
						})
					})

				} else {
					this.page.$sql.do(sqls).then(res => {
						this.doQuery(1)
					}).catch(err => {
						this.$logic.tip.error('操作失败：' + err.info)
					})
				}

			},

			buttonClick(button) {
				let model = this.model
				if (button.key == 'add' ||
					button.key == 'view' ||
					button.key == 'edit' ||
					button.key == 'del' ||
					button.key == 'excel'
				) {
					this.doForm(button.key)
				} else {
					let list = []
					for (let item of this.dataRows) {
						if (item.$check) {
							list.push(item)
						}
					}
					this.doEvent({
						eventName: 'buttonClick',
						button: button,
						dataList: this.dataRows,
						checked: list,
					}, '$comp_buttonClick')
				}

			},
			rowFold(row) {
				row.$cellModel.$fold = !row.$cellModel.$fold
				if (row.$cellModel.$fold) { //只要已展开过,就已生成折叠区的内容
					row.$cellModel.$foldCreated = true
				}
			},
			rowCheck(target, check = true) {
				let row = this.getDataRow(target)
				if (!row) {
					return
				}
				if (row.$check === check) { //只在状态不同的情况下作切换，防止用户在事件代码里调用本函数对同一行数据设置，将导致死循环触发事件
					return
				} else {
					row.$check = check
					this.rowChecked(row) //触发选择的事件
				}



			},
			rowChecked(item) {
				//多选模式且当前行是勾选的状态的情况
				if (item.$check && this.model.allowCheck != 'M') {
					for (let row of this.dataRows) {
						if (row != item) {
							if (row.$check) { //取消其它的选择
								row.$check = false
							}
						}

					}
				}
				this.doEvent({
					eventName: 'rowCheck',
					dataList: this.dataRows,
					row: item,
					checked: item.$check,
				}, '$comp_rowCheck')

			},
			setItemCheck() {

				for (let row of this.dataRows) {
					row.$check = this.selectStatus
				}
				this.doEvent({
					eventName: 'rowCheck',
					dataList: this.dataRows,
					row: null,
					checked: this.selectStatus,
				}, '$comp_rowCheck')
			},
			rowDblClick(row, index) {
				this.currentRow = row
				let dblClick = this.model.dblClick
				if (!this.isedit) {

					if ('view' == dblClick || 'edit' == dblClick || 'delete' == dblClick) {
						if (!this.formModel) {
							this.$logic.tip.error('报表组件已设置自动双击行为,必须配合设置表单编辑页面')
							return
						}
						if (!this.formModel.idField || !this.formModel.idField.fieldName) {
							this.$logic.tip.error('报表组件已设置双击后的表单操作行为，表单编辑页面必须已设置ID字段')
							return
						}
						let id = row[this.formModel.idField.fieldName]

						if ('view' == dblClick) {
							this.formView(id)
						} else if ('edit' == dblClick) {
							this.formEdit(id)
						} else if ('delete' == dblClick) {
							this.formDel(id)
						}
					} else if ('rowedit' == dblClick) {
						this.setRowEdit(row, true)

					}


					this.doEvent({
						eventName: 'rowDblClick',
						row: row,
						index: index
					}, '$comp_rowDblClick')
				}

			},
			rowClick(row, index) {

				this.currentRow = row
				if (!this.isedit) {
					this.doEvent({
						eventName: 'rowClick',
						row: row,
						index: index
					}, '$comp_rowClick')
				}

			},
			dataClick(row, head) {
				let data = {
					row: row,
					field: head.fieldName,
					value: row[head.fieldName],
				}
				if (!this.isedit) {
					this.doEvent({
						eventName: 'dataClick',
						data: data
					}, '$comp_dataClick')
				}

			},
			getCustomSql(fixParams = {}, virtualParams = {}) { //查询组件的回调值，固定查询和虚拟查询项
				if (this.isedit) {
					return null
				} else {
					return this.doEvent({
						eventName: 'getSql',
						fixParams: fixParams,
						virtualParams: virtualParams
					}, '$comp_getSql')
				}
			},
			pageCurrentChange(pageNo) {
				if (pageNo < 1) {
					pageNo = 1
				}
				this.currentPage = pageNo
				//console.log(this.currentPage)
				this.doQuery()
			},
			pageSizeChange(size) {
				if (!this.isedit) {
					this.currentPage = 1
					this.pageSize = size
					this.doQuery()
				}

			},
			toQuery(sql = null, params = null) {
				if (this.isedit) {
					return
				}
				if (!sql || sql.length < 6) {
					return
				}
				if (sql) { //SQL发生改变重置为第一页
					this.sql = sql
					this.currentPage = 1
				}
				if (params) {
					this.params = params
				}
				this.doQuery()
				//console.log(sql)
			},
			sqlUpdate() {
				this.callQuery()
			},
			/* dataUpdate() {//暂不启用
				for (let i = 0; i < this.dataRows.length; i++) {
					this.dataRows[i] = {
						...this.dataRows[i]
					}
				}
			}, */

			getDataRow(target) {
				let row = null
				if (typeof(target) === 'object') {
					row = target
				} else if (typeof(target) === 'number') {
					row = this.dataRows[target]
				}
				if (row) {
					return row
				} else {
					this.$logic.tip.error('不存在的数据行')
					return null
				}

			},
			setRowData(target, key, value) { //暂不启用
				let row = this.getDataRow(target)

				if (!row) {
					return
				}
				let x = this.dataRows.indexOf(row)
				if (x < 0) {
					return
				}
				let newRow = {
					...row
				}
				newRow[key] = value
				this.dataRows[x] = newRow //用于触发前端更新数据

			},
			/* toEditRowData(row, doType) {
				let model = this.model
				if (doType == 'edit') {
					this.doForm('edit', row)
				} else if (doType == 'del') {

				}

				let id = null
				if (model.idField && model.idField.fieldName) { //设置了字段取字段什，否调用编辑页配置的处理
					id = row[model.idField.fieldName]
					
					if (id) { //ID有值直接操作，否则调用编辑页面的操作
						sql = 'delete from ' + model.dbTable + ' where ' + model.idField.fieldName + '=?'
						let sqlItem = [{
							sql: sql,
							params: [id]
						}]
						
					} else {
						this.doForm('del', row)
					}
				}



			}, */

			setRowEdit(target, isEdit = true) {
				this.setRowData(target, '$edit', isEdit)

			},
			rowFind(target, compId) {
				let row = this.getDataRow(target)
				if (!row) {
					return
				}

				let item = this.$logic.pageUtil.findElementFromBindData(row, compId)
				let obj = item ? item._proxy : null
				return obj

			},
			updateRowData(target) {
				let row = this.getDataRow(target)
				if (!row) {
					return
				}
				let model = this.model
				let comps = {}
				//构建表单
				let formObj = {
					type: 'form',
					dbTable: model.dbTable,
					idField: model.idField,
					pidField: {
						fieldName: null
					},
					items: []
				}
				formObj.dataId = row[model.idField.fieldName]
				if (!model.idField || !model.idField.fieldName) {
					this.$logic.tip.error('未设置行编辑的表ID字段')
					return
				}
				if (!formObj.dataId) {
					this.$logic.tip.error('数据中未包含ID字段[' + model.idField.fieldName + ']的值')
					return
				}
				let panels = []
				for (let head of this.model.dataSet.headItems) {
					//if (item.show === 'show' && item.mode !== 'hide') {	}
					if (head.mode === 'custom') {
						panels.push(row.$cellModel[head.fieldName])
					}
				}
				if (this.model.allowFold === 'Y') {
					panels.push(row.$cellModel.$foldPanel)
				}
				if (this.model.customSet === 'Y') {
					panels.push(row.$cellModel.$itemPanel)
				}
				let stack = panels
				while (stack.length > 0) {
					let node = stack.pop()
					if (node.isInput && node.save === 'Y' && node.fieldName && node.fieldName.fieldName) {
						comps[node.fieldName.fieldName] = node
						formObj.items.push(node)
					}
					if (node.items && node.items.length > 0) {
						for (let item of node.items) {
							stack.push(item)
						}
					}
				}

				if (Object.keys(comps).length < 1) {
					this.$logic.tip.error('没有任何已绑定字段的组件，无法提交')
					return
				}

				let check = this.$logic.pageUtil.formValidate(null, formObj)
				if (!check.success) {
					this.$logic.tip.error('数据验证未通过，不能提交:' + check.describe)
					return
				}
				let formData = this.$logic.pageUtil.getFormData(null, formObj)
				this.$logic.http.post(true, '/api/ds/formsubmit/', {
					appId: this.project.id,
					$dbSourceKey: this.project.dataSource.sourceKey //
				}, formData, res => {
					for (let key in comps) { //值回写到当前行
						row[key] = comps[key].$value()
					}
					this.$logic.tip.success('提交成功')
					this.setRowEdit(row, false)
				}, err => {

					this.$logic.tip.error('提交失败：' + err.info)

				})



			},
			/* filt(filter){//设置查询项的值并
				
			}, */
			callQuery(params, fromPage1 = true, sql = null) {
				if (fromPage1) {
					this.currentPage = 1
				}
				if (params && typeof(params) === 'object') { //传参
					for (let key in params) {
						this.urlParams[key] = params[key]
					}
				}
				if (sql) { //传入SQL直接执行SQL
					if (params) {
						this.params = params
					}
					this.sql = sql
					this.doQuery()
				} else {
					if (this.$refs.queryRef) {
						this.$refs.queryRef.getQuery()
					}
				}


			},
			doQuery(pageNo) { // 不传参刷新当前页 
				if (this.isedit) {
					return
				}
				/* if (pageNo && pageNo > 0) {
					this.currentPage = pageNo
				} */
				//console.log(pageNo)
				//console.log(this.currentPage)

				//滚动条水平方向复位
				/* let tableArea = this.$refs.tablearea
				if (tableArea) {
					tableArea.scrollLeft = 0
				}
 */

				this.dataRows = []

				let isTotal = this.model.pageType === 'num'
				let option = {
					isJson: true,
					total: isTotal,
					size: this.pageSize,
					pageNo: this
						.currentPage,
					showLayer: true
				}
				let sql = this.sql
				let params = this.params
				let sqlRs = this.doEvent({
					eventName: 'queryBefore',
					sql: this.sql,
					params: this.$logic.clone.deepClone(params) //创建副本，保持原来的数据
				}, '$comp_queryBefore')

				if (sqlRs === false) {
					return
				} else {
					if (sqlRs && typeof(sqlRs) === 'object' && ('sql' in sqlRs) && ('params' in sqlRs)) {
						sql = sqlRs.sql
						params = sqlRs.params
					}
				}
				this.setData('allDataCount', 0)
				this.$logic.http.sqlQuery(this.dbSourceKey, this.sql, this.params, option)
					.then(res => {
						let c = ((this.currentPage > 0 ? this.currentPage : 1) - 1) * this.pageSize
						/*
						 */

						let total = parseInt(res.data.data.totalAll)
						if (total > -1) {
							this.rowsTotal = total
						} else {
							this.rowsTotal = res.data.data.dataList.length
						}
						this.setData('allDataCount', this.rowsTotal)
						this.setList(res.data.data.dataList, c)
						//this.$emit('dataload', res.data.data)
					}).catch(err => {
						let info = err.info ? err.info : err.message
						this.$logic.tip.error('数据查询处理异常：' + info)
					})

			},
			getList(onlySeleted = false) {
				let list = null
				if (onlySeleted) {
					list = []
					for (let item of this.dataRows) {
						if (item.$check) {
							list.push(item)
						}
					}
				} else {
					list = this.dataRows
				}
				return list
			},
			setList(list, c = 0) {
				if (list && 'length' in list) {
					this.doEvent({
						eventName: 'dataLoad',
						dataList: list,
					}, '$comp_dataLoad')

					let model = this.model
					let ds = this.model.dataSet
					for (let item of list) {
						item.$bgColor = null
						item.$color = null
						item.$check = false
						item.$edit = false
						item.$rowNo = ++c
						item.$cellModel = {
							$fold: false, //折叠区是否打开
							$foldCreated: this.model.foldLazy === 'Y' ? false : true //折叠区内容是否创建

						}

						for (let pm of ['bgColor', 'color']) { //根据数据设置前景色背景色
							let js = ''
							let rs = null
							let pv = model[pm + 'Field']
							if (pv) { //设置过取值字段或表达式
								try {
									if (pv.indexOf('?') > -1) {
										//js = ''//let dataBag= item;
										for (let key in item) {
											if (key.indexOf('$') < 0) {
												js = js + 'let ' + key + '=item["' + key + '"];'
											}
										}
										js = js + 'rs=' + pv
										eval(js)
									} else {
										rs = item[pv]
									}

								} catch (ex) {
									rs = null
								}

								item['$' + pm] = rs
							}
						}

						//创建model副本
						let excludFields = ['parent', '$linkObj', '$sqlLink']
						let refsFields = ['$linkObj', '$sqlLink']
						if (this.model.allowFold === 'Y') {
							item.$cellModel.$foldPanel = this.$logic.clone.deepClone(this.model.dataSet
								.foldPanel, excludFields, refsFields
							)
							//console.log(this.model.dataSet.foldPanel)
							this.$logic.clone.setParent(item.$cellModel.$foldPanel, item)

						}
						if (this.model.customSet === 'Y') {
							item.$cellModel.$itemPanel = this.$logic.clone.deepClone(this.model.dataSet
								.itemPanel,
								excludFields, refsFields)
							this.$logic.clone.setParent(item.$cellModel.$itemPanel, item)
						}


						for (let head of ds.headItems) {
							if (head.mode === 'custom' && head.panel && head.panel.type) { //如果存在有效的自定义
								let panel = this.$logic.clone.deepClone(head.panel, excludFields, refsFields)
								this.$logic.clone.setParent(panel, item)
								//panel.$functions = head.panel.$functions
								item.$cellModel[head.fieldName] = panel
							} else if (head.mode === 'text') {
								item[head.fieldName] = util.getFormatText('text', head.dataType, head
									.format, item[
										head.fieldName])
							} else if (head.mode === 'num') {
								item[head.fieldName] = util.getFormatNum('num', head.dataType, head
									.format, item[
										head.fieldName])
							} else if (head.mode === 'time') {
								item[head.fieldName] = util.getFormatTime('time', head.dataType, head
									.format, item[
										head.fieldName])
							}
						}

						item.$find = (compId) => {
							return this.rowFind(item, compId)
						}

					}
					this.currentRow = null
					this.dataRows = list
					this.setData('pageDataCount', list.length)
					/* if (this.model.allDataCount < list.length) {
						this.setData('allDataCount', this.rowsTotal)
					} */
					this.$nextTick(() => { //下个周期执行，等dom已经生成
						this.doEvent({
							eventName: 'dataLoaded',
							dataList: list,
						}, '$comp_dataLoaded')
					})

					setTimeout(() => { //延迟一点时间确保页面已经生成
						this.setTableColFix()
					}, 1000)
					/* this.$nextTick(() => { //设置冻结列
						
					}) */

				} else {
					this.$logic.tip.error('无效的表格数据赋值，请使用数组类型数据')
				}

			},
			sendTo(compId, start = 0, end = 99999999) { //传送的目标和数据行起始位置，截止位置
				let obj = this.$logic.pageUtil.getElementById(this.page.formData, compId)

				if (obj == null || obj.type != 'commtable') {
					this.$logic.tip.error('指定的组件ID：[' + compId + ']不是有效的报表组件')
					return
				}
				let list = this.dataRows
				let rs = []
				let lastIndex = list.length - 1
				if (end < lastIndex) {
					lastIndex = end
				}
				for (let i = start; i <= lastIndex; i++) {
					let src = list[i]
					let data = {}
					for (let key in src) {
						if (key.indexOf('$') < 0) {
							data[key] = src[key]
						}
					}
					rs.push(data)
				}
				obj.$setList(rs)

			},

			setTableColFix() { //冻结列

				let queryBar = this.$refs.querybar
				let tableArea = this.$refs.tablearea
				let pageArea = this.$refs.pagearea
				let table = this.$refs.listTable

				/* if (tableArea) {
					tableArea.scrollLeft = 0
				} */
				if (!queryBar || !tableArea || !table) {
					return
				}
				let queryHeight = queryBar.offsetHeight + (pageArea ? pageArea.offsetHeight : 0)
				if (queryHeight > 0) {
					queryHeight = queryHeight + 10 //多加一点间距
				}
				tableArea.style.height = 'calc(100% - ' + queryHeight + 'px)'
				if (this.isedit || this.model.customSet === 'Y') {
					return
				}
				if (this.model.fixAllow != 'Y') {
					return
				}
				let baseIndex = 20
				//子表格内的层次要小于父表的
				let parentNode = table.parentNode
				while (parentNode) {
					if (parentNode.classList && parentNode.classList.contains('tablelist')) {
						baseIndex--
					}
					parentNode = parentNode.parentNode
				}
				let left = this.model.fixLeft || 0
				let right = this.model.fixRight || 0

				let rows = table.rows //.querySelectorAll('tr.tabletop')
				let sum = 0
				let rowColSpans = {} //每行的索引号对应的累计偏移列数
				let colsLeft = {}
				colsLeft[0] = 0 //第一个的左偏移量为0
				let headRowCount = 0
				if (rows && rows.length > 0) {
					for (let i = 0; i < rows.length; i++) { //上一行的高度是下一行的偏移值
						let row = rows[i]
						if (row.classList && row.classList.contains('tabletop')) {

							row.style.top = sum + 'px'
							row.style.position = 'sticky'
							row.style['z-index'] = baseIndex
							sum = sum + row.offsetHeight
							//分析是否有跨行的列,目前只处理左侧部分,将有跨行的单元格的偏移记录到有偏移值的数据
							let rowSpan = rowColSpans[i] //取出当前行是否有标记的数据
							let colOffset = rowSpan ? rowSpan.left : 0
							for (let j = 0; j < left; j++) {
								if (colOffset + j >= left) {
									break
								}
								let td = row.cells[j]
								if (td && td.rowSpan > 0) {
									for (let k = 0; k < td.rowSpan - 1; k++) {
										let rowIndex = i + 1 + k
										let rowSpanItem = rowColSpans[rowIndex]
										if (rowSpanItem) {
											rowSpanItem.left = rowSpanItem.left + 1
											//rowSpanItem.width = rowSpanItem.width + td.offsetWidth
										} else {
											rowColSpans[rowIndex] = {
												left: 1,
												//	width: td.offsetWidth
											}
										}
									}
								}


							}
							/* for (let td of row.cells) {
								td.style.border = '0px'
							} */

							headRowCount++

						} else { //发现第一个不含此表头class的就是数据列，取出各列的宽度偏移量，作为下面列定位的依据
							let sum = 0
							for (let n = 1; n < row.cells.length; n++) {
								let td = row.cells[n - 1] //取前一个列宽
								sum = sum + td.offsetWidth
								colsLeft[n] = sum
							}
							break
						}

					}

				}
				if (left == 0 && right == 0) {
					return
				}
				let scrollLeft = tableArea.scrollLeft
				let offLeft = table.offsetLeft + scrollLeft
				rows = table.getElementsByTagName("tr") //table.querySelectorAll('tr.row-data')
				let headFixTable = this.$refs.headFixTable
				for (let k = 0; k < rows.length; k++) {
					let row = rows[k]
					let cellCount = row.cells.length
					if (cellCount < 3) { //折叠区的行不作处理
						continue
					}

					let rowSpanItem = rowColSpans[k]
					let colOffset = rowSpanItem ? rowSpanItem.left : 0
					let leftSum = rowSpanItem ? rowSpanItem.width : 0
					for (let i = 0; i < left && i < row.cells.length; i++) {
						if (i + colOffset >= left) {
							break
						}
						let colLeft = colsLeft[i + colOffset]
						let td = row.cells[i]
						td.classList.add('fix-col')
						td.style.position = 'sticky' //直接设置此属性，兼容自定义表头的列

						if (colLeft == 0) { //最左边的单元格设置边框，否则水平滚动会没左边框 
							td.style.borderLeftWidth = '1px';
						}
						td.style.left = colLeft +
							'px' //leftSum + 'px' //(td.offsetLeft - (k > 0 ? offLeft : 0)) + 'px' //- offLeft
						//leftSum = leftSum + td.offsetWidth
					}

					let rightSum = 0
					for (let j = 0; j < right && j < row.cells.length; j++) {
						let td = row.cells[cellCount - j - 1]
						td.classList.add('fix-col-right')
						td.style.position = 'sticky'

						td.style.right = (rightSum) + 'px'
						rightSum = rightSum + td.offsetWidth

					}

					//创建镜像表头行,只保留左上角的几列 headFixTable
					//headFixArea
					/* if (k < headRowCount && headFixTable) {
						let newRow = row.cloneNode(true)
						for (let td of newRow.cells) { //去掉非冻结的列
							if (!td.classList || !td.classList.contains('fix-col')) {
								
								newRow.removeChild(td)
							}else{
								console.log(td.innerText)
							}
						}
						headFixTable.appendChild(newRow);
					} */

				}





				return
				/* 	let table = this.$refs.listTable
					if (!table) {
						return
					}
					//let trs = table.getElementsByTagName("tr")
					let colLeft = []
					 
					let left = 4

					for (let row of table.rows) {


						for (let i = 0; i < left; i++) {
							let td = row.cells[i]
							td.classList.add('fixtd')
							td.style.left = td.offsetLeft + 'px'

						}

					} */
			},
			headSort(head) {
				this.sortHead = head

				if (head.order) {
					head.order = head.order === 'desc' ? 'asc' : 'desc'
				} else {
					head.order = head.allowOrder ? head.allowOrder : 'asc'
				}
				this.model.dataSet.orderItems = [head]
				this.callQuery()
			},
			setOrder(list) {
				//console.log(list)
				this.model.dataSet.orderItems = list
				if (list && list.length === 1) {
					this.sortHead = list[0]
				} else {
					this.sortHead = null
				}

			},
			headClick(head) {
				if (this.isedit) {
					head.parent = this.model
					this.headSelect = head
					this.setCurrentElement(head)
				}


			},
			headReset(config) {
				this.model.headConfig = config
			},
			setRecall(dataSet) {
				for (let key in dataSet) {
					this.model.dataSet[key] = dataSet[key]
				}
				for (let head of this.model.dataSet.headItems) {
					if (head.mode === 'custom' && head.panel && head.panel.type) { //如果存在有效的自定义元素
						head.panel.parent = this.model
					}
				}
				return true
			},
			initCustomEvents() { //生成自定义单元格内的元素事件
				if (this.model.dataSet && this.model.dataSet.listPanel) {
					this.$logic.clone.setParent(this.model.dataSet.listPanel)
				}
				if (this.model.dataSet && this.model.dataSet.itemPanel) {
					this.model.dataSet.itemPanel.parent = this.model.dataSet.listPanel
					this.$logic.clone.setParent(this.model.dataSet.itemPanel)
				}
				if (this.model.dataSet && this.model.dataSet.foldPanel) {
					this.$logic.clone.setParent(this.model.dataSet.foldPanel)
				}
				this.initTempatePanel() //初始化自定义列的父级关系
				if (this.isedit) {


					for (let head of this.model.dataSet.headItems) {
						if (head.mode === 'custom' && head.panel && head.panel.type) { //如果存在有效的自定义元素
							head.panel.parent = this.model
						}
					}
				} else { //模板容器内的元素初始化事件、联动绑定关系

					if (this.model.customSet === 'Y') {
						if (this.model.dataSet.itemPanel) { //自定义模板编辑区this.model.dataSet.itemPanel.items
							this.$logic.http.initModel(this.elementConfig,[this.model.dataSet.itemPanel] , true, this
								.page)
						}
					} else {
						let list = []
						for (let head of this.model.dataSet.headItems) {
							if (head.mode === 'custom' && head.panel && head.panel.type) { //如果存在有效的自定义元素
								list.push(head.panel)
								head.panel.parent = this.model
							}
						}
						let allTemplatePanels = [].concat(list) //列模板+折叠模板
						if (this.model.dataSet.foldPanel) {
							allTemplatePanels.push(this.model.dataSet.foldPanel)
						}
						/*	allTemplatePanel.push(this.model.dataSet.foldPanel ? this.model.dataSet.foldPanel : []) //得到所有的模板容器
					 			if (list.length > 0) {
							this.$logic.http.initModel(this.elementConfig, list, true, this.page, list)
						}
 */
						if (allTemplatePanels.length > 0) {
							this.$logic.http.initModel(this.elementConfig, allTemplatePanels, true, this
								.page)
						}

					}


				}


			},
			initTempatePanel() { //初始化自定义单元格内的模板元素
				for (let head of this.visibleHeads) {
					if (head.panel) { //如果存在有效的容器对象
						let sk = [head.panel]
						while (sk.length > 0) {
							let p = sk.pop()
							for (let item of p.items || []) {
								item.parent = p
								sk.push(item)
							}
						}

					}
				}
			},
			openSubPage(formId, actionType = '') {
				if (this.isedit) {
					return
				}
				if (!this.formModel) {
					this.$logic.tip.error('未加载表单编辑页面，请确定设置了有效的[编辑页面]属性')
					return
				}
				if (!this.formModel.idParam) {
					this.$logic.tip.error('未设置表单编辑页的ID字段')
					return
				}
				this.subPage.url = this.model.pageKey + (this.model.pageKey.indexOf('?') > 0 ? '&' : '?') + this
					.formModel
					.idParam +
					'=' + formId + '&$ticksequence=' + new Date().getTime() + '&$actionType=' +
					actionType + (this.model.pageParam ? '&' + this.model.pageParam : '')

				this.$refs.formEditDialog.show()
				/* let param = {
					timestamp: new Date().getTime()
				}
				param[this.formModel.idParam] = formId
				this.$refs.formEditDialog.show()
				if (this.subPage._loadContent) {
					this.subPage._loadContent(this.pageModel, param)
				} else {
					setTimeout(() => {
						this.subPage._loadContent(this.pageModel, param)
					}, 100)

				} */


			},
			initSubPage() {

				if (this.isedit || !this.model.pageKey) {
					return
				}

				this.formModel = null
				let pageKey = this.model.pageKey
				let x = pageKey.indexOf('?')
				if (x > 0) {
					pageKey = pageKey.substring(0, x)
				}
				let params = {
					appId: this.page.appId,
					appVersion: this.page.appVersion,
					formKey: pageKey
				}

				this.$logic.http.pageLoad(this.project, this.elementConfig, null, params, res => {
					let pageModel = res.formData
					let forms = this.$logic.pageUtil.getElements(pageModel.items, '.form', true)
					if (forms.length < 1) {
						this.$logic.tip.error('指定的表单页面中不存在表单容器')
						return
					}
					let formItem = forms[0]
					if (!formItem.idField || !formItem.idField.fieldName) {
						this.$logic.tip.error('请设置数据编辑表单容器的表单数据库Id字段')
						return
					}
					this.pageModel = pageModel
					this.formModel = formItem
					let subPage = this.elementConfig.create('subpage', null, this.page.formData)
					subPage.parent = this.model
					subPage.url = null
					this.subPage = subPage

				}, err => {
					this.formModel = null
					this.$logic.tip.error('通用报表[' + this.model.id + ']配置的编辑表单[' + pageKey + ']加载失败 ' + err.info)
				}, this.isedit)
			},

			getSubPanels() { //从组件自定义编辑区内部查找元素,表单设计器中调用此方法
				//let obj = null
				let stack = this.model.dataSet.foldPanel ? this.model.dataSet.foldPanel.items : []
				stack = stack.concat(this.model.dataSet.itemPanel ? this.model.dataSet.itemPanel.items : [])
				for (let item of this.model.dataSet.headItems) {
					if (item.mode === 'custom' && item.panel && item.panel.items) {
						stack = stack.concat(item.panel.items)
					}
				}
				return stack


			},
			getHead(colId) {
				let head = null
				for (let item of this.model.dataSet.headItems) {
					if (item.id === colId) {
						head = item
					}
				}
				return head

			},
			$getHeadItems() {
				return this.model.dataSet.headItems || []
			},

			$setFilter(fieldName = '', value1 = null, value2) {
				if (!fieldName) {
					return
				}
				let noFind = true
				let key1 = fieldName.toLowerCase()
				for (let item of this.model.dataSet.queryItems) {
					if (item.fieldName && item.fieldName.toLowerCase() === key1) {
						if (item.input == 'multi') {
							if (Array.isArray(value1)) {
								item.multi = value1
							} else if (value1 && (typeof(value1) === 'string')) {
								item.multi = value1.split(',')
							} else {
								item.multi = null
							}

						} else {
							item.value1 = value1
							if (typeof(value2) === 'undefined') {} else {
								item.value2 = value2
							}
						}
						noFind = false
						break
					}
				}
				if (noFind) {
					this.$logic.tip.error('不存在的查询项字段名：' + fieldName)
				}

			},
			$getInnerDome() {
				let items = []

				if (this.model.customSet === 'Y') {
					if (this.model.dataSet.itemPanel) {
						items.push(this.model.dataSet.itemPanel)
					}

				} else {
					if (this.model.allowFold === 'Y' && this.model.dataSet.foldPanel) {
						items.push(this.model.dataSet.foldPanel)
					}
					for (let item of this.$getHeadItems()) {
						if (item.mode === 'custom' && item.panel && item.panel.items) {
							items.push(item.panel)
						}
					}
				}
				for (let item of this.model.dataSet.headItems) {
					items.push(item)
				}

				return items
			}


		},
		computed: {
			/* 	pageSize() {
					let size = parseInt(this.model.pageSize)
					if (isNaN(size) || size < 1) {
						size = 1
					}
					return size
				}, */
			listPanelStyle() {
				let css = this.$logic.getStyle(this.model.dataSet.listPanel, false)

				return css
			},
			formDo() {
				let model = this.model
				let rs = {
					add: model.addButton == 'Y',
					view: model.viewButton == 'Y',
					edit: model.editButton == 'Y',
					del: model.delButton == 'Y',
				}
				rs.action = rs.add || rs.view || rs.edit || rs.del
				return rs
			},
			dbSourceKey() {
				return this.model.dataSet.dataSourceKey ? this.model.dataSet.dataSourceKey : this.project
					.dataSource.sourceKey
			},
			visibleHeads() {
				let heads = []
				for (let item of this.model.dataSet.headItems) {
					if (item.show === 'show' && item.mode !== 'hide') {
						heads.push(item)
					}
				}
				return heads
			},
			initHeadCols() {
				let cols = this.model.dataSet.headItems
				if (this.model.showNum === 'Y') {
					cols = [{
						label: '序号'
					}].concat(cols)
				}
				return cols
			},
			buttons() {
				let bs = []
				try {
					let data = JSON.parse(this.model.buttons)
					for (let item of data) {
						if (item.key && ',query,super,head,order,add,view,edit,del,excel,init,'.indexOf(',' + item.key +
								',') >
							-1) {
							let key = item.key + 'Button'
							if (this.model[key] === 'Y') {
								bs.push(item)
							}
						} else {
							if (this.model.allowButton == 'Y') {
								bs.push(item)
							}
						}
					}

				} catch (ex) {
					this.$logic.tip.error('报表按钮数据定义格式错误：' + ex)
				}
				return bs

			},

			tableStyle() { //不启用
				//let css = this.compStyle
				/* 				let color = this.$logic.color.getLightColor(this.model.headBgColor, 0.5)
								css['--title-back-color'] = 'linear-gradient(0deg,' + color + ',' + this.model.headBgColor + ',' +
									color + ')'
							 */
				let css = {}
				if (this.model.fixAllow === 'Y') {
					css['--table-border-collapse'] = 'separate'
				} else {
					css['--table-border-collapse'] = 'collapse'
				}
				return css
			},
			hasTool() { //是否否自带的的操作功能
				let model = this.model
				return model.allowCheck != 'N' || model.allowFold === 'Y'

			},
			hasEdit() { //是否自带编辑功能
				let model = this.model
				return model.rowEdit === 'Y' || model.editButton === 'Y' || model.delButton === 'Y'

			},
			foldColSpan() {
				let cols = this.visibleHeads.length
				if (this.model.showNum === 'Y') {
					cols++
				}
				if (this.hasEdit) {
					cols++
				}
				return cols

			}

		},
		watch: {
			'model.pageSize'(newValue, oldValue) {
				let size = newValue
				if (size) {
					size = parseInt(size)
					if (size > 10000) { //控制上限
						size = 10000
					}
					if (size < 1) {
						size = 1
					}

				} else {
					size = 1
				}

				this.pageSizeChange(size)
			},
			'model.pageKey'(newValue, oldValue) {
				this.initSubPage()
			}
			/* 'model.dataSet.tick'(newValue, oldValue) {
				console.log('up')
			} */
			/* 		dataRows: {
						deep: true,
						handler(nv, ov) {
							console.log('Y')
							if ( nv && nv.length===ov.length) { //只有数据体内元素发生变化才执行
							console.log('P')
								for (let i = 0; i < nv.length; i++) {
									nv[i] = {
										...nv[i]
									}
								}
							}
						}
					} */

		},
		components: {
			//	reportset: ReportSet,
			reportquery: ReportQuery,
			tablehead: TableHead,

		},
		mounted() {
			if (this.isedit) {
				this.setTableColFix()
			}
		},
		created() {
			//初始化分页大小
			let pageSize = 10000
			if (this.model.pageSize) {
				let size = parseInt(this.model.pageSize)
				if (size > 0) {
					pageSize = size
				}
			}
			this.pageSize = pageSize
			//初始化自定义单元格内元素的事件
			this.initSubPage()
			this.urlParams = this.page.$params || {}

			this.model.$getSubPanels = this.getSubPanels
			this.model.$getInnerDome = this.$getInnerDome


			if (!this.isedit) {
				this.rowsTotal = 0
				this.model.$setList = this.setList
				this.model.$getList = this.getList
				this.model.$query = this.callQuery //程序调用点击查询按钮
				this.model.$sqlUpdate = this.sqlUpdate
				this.model.$dialogClose = () => {
					//this.formEditDialogClose(false)
					this.$refs.formEditDialog.hide()
				}
				this.model.$setFilter = this.$setFilter
				this.model.$getHead = this.getHead
				this.model.$getHeads = this.$getHeadItems
				this.model.$add = this.formAdd
				this.model.$view = this.formView
				this.model.$edit = this.formEdit
				this.model.$del = this.formDel
				this.model.$setRowData = this.setRowData
				this.model.$setRowEdit = this.setRowEdit
				this.model.$updateRowData = this.updateRowData
				this.model.$rowCheck = this.rowCheck
				this.model.$rowFind = this.rowFind //行内查找组件
				this.model.$sendTo = this.sendTo //将当前报表数据数据传送给别一个报表

				//console.log(this.model.dataSet.queryItems)

				//this.model.$update = this.dataUpdate
			} else {
				this.model.$setRecall = this.setRecall //只在设计模式下添加查询设置回调函数
				//自定义模式的外部容器对象
				if (!this.model.dataSet.listPanel) {
					let listPanel = this.elementConfig.create('row', null, this.page.formData)
					listPanel.name = '列表容器'
					listPanel.minH = '100px'
					listPanel.parent = this.model
					this.model.dataSet.listPanel = listPanel

					let itemPanel = this.elementConfig.create('row', null, this.page.formData)
					itemPanel.name = '数据项编辑区'
					itemPanel.minH = '20px'
					itemPanel.parent = listPanel
					this.model.dataSet.itemPanel = itemPanel


				} else {
					this.model.dataSet.listPanel.parent = this.model
					this.model.dataSet.itemPanel.parent = this.model.dataSet.listPanel

				}
				//初始化折叠内容的容器
				if (!this.model.dataSet.foldPanel) {
					let foldPanel = this.elementConfig.create('row', null, this.page.formData)
					foldPanel.name = '折叠内容区'
					foldPanel.minH = '50px'
					foldPanel.parent = this.model
					this.model.dataSet.foldPanel = foldPanel
				} else {
					this.model.dataSet.foldPanel.parent = this.model

				}


			}
			this.initCustomEvents()

		}
	}
</script>

<style scoped>
	.table-area {
		width: 100%;
		position: relative;
		overflow: auto;
	}



	.tablelist {
		min-width: 100%;
		/*border-collapse: collapse;
		 	border-collapse: separate; */
		border-collapse: var(--table-border-collapse);
		border-spacing: 0px;
		border: 1px solid var(--table-line-color);

	}

	.tablelist td {
		border-width: 0;
		border-style: solid;
		border-color: var(--table-line-color);
		border-right-width: 1px;
		border-bottom-width: 1px;
		/* border-color: var(--table-line-color); */
		color: var(--table-text-color);
	}

	/* firefox加了这种属性后设置了背景色表格线不显示 */

	.tableheadfix {
		/* 此样式与background-color 如果同时有,打印时表头不出现表格线 */
		position: sticky;
		position: -webkit-sticky;
	}

	.tabletop {
		background-color: var(--title-back-color);
	}




	.tabletop>td {
		height: var(--head-height);

	}

	.tabletop>.datacol {

		min-width: var(--col-min-width);
		/* 		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
 */
		/* 	border-left: solid 1px var(--table-line-color);
 */
		cursor: pointer;
	}

	/* background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5); */
	.tabletop div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		/* background-color: var(--title-back-color);  如果这里加了背景色打印时不出表格线 */

		/* 	border-top: solid 1px var(--table-line-color);
		border-bottom: solid 1px var(--table-line-color); */
		color: var(--title-text-color);

	}

	/* 	.tabletop .fix-col>div {
		border-right: solid 1px var(--table-line-color);
	}

	.tabletop .fix-col-right>div {
		border-left: solid 1px var(--table-line-color);
	}
 */


	.colfirst {
		width: var(--index-width);
		min-width: 30px;
		text-align: center;
		background-color: var(--title-back-color);
		box-shadow: 1px 0px #dfdfdf;

	}

	.col-tool {
		width: 45px !important;
		min-width: 45px !important;
		text-align: center;
	}

	.col-edit {

		width: 80px !important;
		min-width: 80px !important;
		text-align: center;
	}

	.current-col {
		--title-back-color: #FF4500;
		background: linear-gradient(#ffdddd, #FF4500, #ffdddd);
		color: #ffffff;
	}

	.col-disable {
		height: 50px;
		background-color: #f8f8f8;
		cursor: not-allowed;
		border: solid 1px #cccccc;
		text-align: center;
	}

	.col-index {
		text-align: center;
	}

	.hascheck {
		text-align: left;
	}

	.col-design {
		height: 50px;
		cursor: pointer;
		border: dashed 1px #cccccc;
	}

	.row-data {
		height: var(--row-height);
		background-color: var(--row-bg-color);
	}

	.row-data:nth-of-type(odd) {
		background-color: var(--row-split-color);
	}

	.row-data:hover {
		/* 改变当前行的背景色与斑马线颜色为当前悬浮色，便得冻结的列的单元格背景色同步为悬浮色 */
		--row-bg-color: var(--row-hover-color);
		--row-split-color: var(--row-hover-color);
		background-color: var(--row-hover-color);
	}

	.fix-col {
		/* 必须要设置背景色,否则滚动时右侧内容会滚过来 */
		position: sticky;
		position: -webkit-sticky;
		left: 0px;
		background-color: #ffffff;
		/* border: #000000 0px solid; */
		border-left-width: 0px;
		border-right-width: 0px;
		/* box-shadow: 0px 0px 0px 0.2px var(--table-line-color) inset !important; */
	}

	/* 冻结的列的单元格背景色为行背景色 */
	.row-data .fix-col {
		background-color: var(--row-bg-color);
	}

	.row-data:nth-of-type(odd) .fix-col {

		background-color: var(--row-split-color);
	}

	.fix-col-right {
		/* 必须要设置背景色,否则滚动时左侧内容会滚过来 */
		position: sticky;
		position: -webkit-sticky;
		right: 0px;
		background-color: #ffffff;
		border-width: 0px;
		box-shadow: 0px 0px 0px 0.2px var(--table-line-color) inset !important;
	}

	.row-data .fix-col-right {
		background-color: var(--row-bg-color);
	}

	.row-data:nth-of-type(odd) .fix-col-right {
		background-color: var(--row-split-color);
	}

	/* 	.fix-col .fix-col-content {
		height: 100%;
		width: calc(100% - 1px);
		border-right: 1px solid var(--table-line-color);

	} */

	.td-data {
		padding-top: var(--row-padding-top);
		padding-bottom: var(--row-padding-bottom);
		padding-left: var(--row-padding-left);
		padding-right: var(--row-padding-right);
	}

	.data-span {
		cursor: pointer;
	}

	.data-span:hover {
		color: rgb(36, 64, 179);
		text-decoration: underline;
		text-underline-offset: 2px;
	}

	.fold-botton {
		margin: 0px 5px 0px 5px;
		font-size: 18px;
		cursor: pointer;
	}

	.fold-botton:hover {
		color: #FF4500;
	}

	.foot-page-bar {
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: var(--table-pagebar-align);
	}

	.foot-page-bar i {
		font-size: 14px;
	}

	:deep(.el-pagination__total) {
		color: var(--table-text-color);
	}

	:deep(.el-pagination__jump) {
		color: var(--table-text-color);
	}

	:deep(.el-input__wrapper) {
		background-color: rgba(255, 255, 255, 0);

	}

	:deep(.el-input__inner) {
		color: var(--table-text-color);
	}

	:deep(.el-pagination button) {
		background-color: rgba(255, 255, 255, 0);
	}

	:deep(.el-pager) {
		color: var(--table-text-color);
	}

	:deep(.el-pager li) {
		background-color: rgba(255, 255, 255, 0);
	}

	:deep(.el-pager li.btn-quicknext, .el-pager li.btn-quickprev) {
		color: var(--table-text-color);
	}
</style>