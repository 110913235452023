class ReportCfg {
	constructor() {
		this.lastId = new Date().getTime()
	}

	getNewHead(data) {
		let item = {
			...data,
			type: 'reportcol',
			width: '',
			minWidth: '60px',
			typeGroup: data.typeGroup,
			dataType: data.unionType,
			totalType: 'sum', //默认求和
			mode: data.typeGroup,
			name: data.label,
			show: 'show',
			hAlign: 'center',
			vAlign: 'middle',
			before: '', //前置内容
			after: '', //后置内容
			color: '',
			bgColor: '',
			fontSize: '',
			format: {},
			order: 'asc',
			panel: null //自定义类型单元格的容器
		}

		item.id = 'c' + (this.lastId++)
		return item
	}
	getNewQuery(data, init) {
		let item = {
			...data,
			queryType: 'field', //查询类型： field=字段条件，replace=值替换(固定条件),virtual=不起直接作用传递给动态取SQL的函数用于逻辑处理
			//initData:null,//初始值
			width: '100px',
			compare: 'eq',
			input: 'input', //输入方式
			show: true,
			enum: 0, //枚举对象的ID，当input 为single 或multi时需指定枚举数据
			normal: true, //是否可用于高级查询
			use: false, //快捷查询时是否启用此条件，预留用户指定是否启用些条件
			value1: null,
			value2: null,
			multi: [],
			user: {
				userId: null,
				userName: ''
			},
			org: {
				id: null,
				textPath: ''
			},
		}
		if (init) {
			for (let key in init) {
				item[key] = init[key]
			}
		}
		return item
	}

}
export default new ReportCfg()