import options from '../../../../../public/options.js'
import gridConfig from '../gridConfig.js'
let base = gridConfig.base
let special = [
	{key:'seriesBarLine',text:'柱线分组',type:'select',init:'barfirst',bursh:true,bind:true,tip:'',list:options.toOptions('首柱余线=barfirst,尾柱余线=barlast,N柱余线=barN,首线余柱=linefirst,尾线余柱=linelast,N线余柱=lineN')},
	{key:'seriesBarLineN',text:'N值设定',type:'number',init:1,bursh:true,bind:true,tip:'N柱余线时前N组数据为柱条，后面为线条；N线余柱时同理',list:null},
	
	 {key:'title',text:'柱条系列'},
//	 {key:'seriesType',text:'图形类型',type:'text',init:'bar',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
	 
	 {key:'seriesBarWidth',text:'柱条宽度',type:'text',init:null,path:'_series.bar.barWidth',bursh:true,bind:true,tip:'可指定柱条宽度，直接输入数值为象素值，支持百分比如10%',list:null},
	 {key:'seriesBarborderRadius',text:'柱条圆角',type:'number',init:3,path:'_series.bar.itemStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarborderWidth',text:'描边粗细',type:'number',init:1,path:'_series.bar.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarborderColor',text:'描边颜色',type:'color',init:null,path:'_series.bar.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},

	 {key:'seriesBarLabelShow',text:'显示标签',type:'switch',init:'N',path:'_series.bar.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBarLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.bar.label.color',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBarLabelRotate',text:'标签角度',type:'number',init:0,path:'_series.bar.label.rotate',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarLabelPosition',text:'标签位置',type:'select',init:'top',path:'_series.bar.label.position',bursh:true,bind:true,tip:'',list:options.toOptions('顶部=top,中间=inside,底部=insideBottom')},

	 {key:'seriesBarStack',text:'柱条堆叠',type:'select',init:'none',bursh:true,bind:true,tip:'',list:options.toOptions('不堆叠=none,全部堆叠=all,第一个之后=after,最后一个之前=befor')},

	 {key:'seriesBarColorBy',text:'取色方式',type:'select',init:'series',path:'_series.bar.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesShowBackground',text:'启用背景色',type:'switch',init:'N',path:'_series.bar.showBackground',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBackgroundColor',text:'柱条背景色',type:'colorA',init:'rgba(180, 180, 180, 0.2)',path:'_series.bar.backgroundStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderColor',text:'背景描边色',type:'colorA',init:null,path:'_series.bar.backgroundStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderType',text:'描边线类型',type:'select',init:'solid',path:'_series.bar.backgroundStyle.borderType',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	 {key:'seriesBackgroundBorderWidth',text:'描边线粗细',type:'number',init:0,path:'_series.bar.backgroundStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderRadius',text:'圆角大小',type:'number',init:0,path:'_series.bar.backgroundStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesRealtimeSort',text:'实时排序',type:'switch',init:'N',path:'_series.bar.realtimeSort',bursh:true,bind:true,tip:'',list:options.YON},

	 {key:'title',text:'线条系列'},
//	 {key:'xAxisAxisBoundaryGap',text:'两侧留白',type:'switch',init:'Y',path:'xAxis.line.boundaryGap',bursh:true,bind:true,tip:'',list:options.YON},
//	 {key:'seriesLineColorBy',text:'取色方式',type:'select',init:'series',path:'_series.line.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesLineStep',text:'阶梯线形式',type:'select',init:'none',path:'_series.line.step',bursh:true,bind:true,tip:'',list:options.toOptions('无阶梯=none,起点拐角=start,中间点拐角=middle,终点拐角=end')},
	{key:'seriesLineWidth',text:'线条粗细',type:'number',init:2,path:'_series.line.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
	{key:'seriesLineType',text:'线条类型',type:'select',init:'solid',path:'_series.line.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	{key:'seriesLineSmooth',text:'平滑度',type:'number',init:0.5,path:'_series.line.smooth',bursh:true,bind:true,tip:'0到1之前的小数',list:null},
	
	{key:'seriesLineAreaIsFill',text:'区域填充',type:'select',init:'none',path:'_series.line.areaStyle.isFill',bursh:true,bind:true,tip:'',list:options.toOptions('不填充=none,全部填充=all,首个填充=first,最后填充=last')},
//	{key:'seriesLineAreaOrigin',text:'填充方向',type:'select',init:'auto',path:'_series.areaStyle.origin',bursh:true,bind:true,tip:'象数值',list:options.toOptions('自动=auto,起始位置=start,结束位置=end')},
	 
	 {key:'seriesLineLabelShow',text:'显示标签',type:'switch',init:'N',path:'_series.line.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesLineLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.line.label.color',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesLineLabelRotate',text:'旋转角度',type:'number',init:0,path:'_series.line.label.rotate',bursh:true,bind:true,tip:'',list:null},


].concat(gridConfig.special)
let action = gridConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_barline',color:options.chartColor.bar3,seriesLineAreaIsFill:'first',
		chartClan: 'grid',
	},
	delete: '',
	hide: 'seriesType,dataOption',
	base:[].concat(base) ,
	special: special,
	action: gridConfig.action.timer.concat(gridConfig.action.comm)
}
export default cfg
