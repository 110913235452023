import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
import compSet from '../../../public/comp.js'
/* import row from '../row.js'
import clone from '../../../../../../plugins/deepClone.js'
let body=clone.deepClone(row) */
let base=[ 
	{key:'dialogTitle',text:'对话框标题',type:'text',init:'抽屉对话框标题',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'dialogIcon',text:'对话框图标',type:'icona',init:'far fa-folder-open',target:'',bursh:true,bind:true,tip:'是否显示取消按钮',list:null},
	{key:'direction',text:'弹出方向',type:'select',init:'rtl',target:'',bursh:true,bind:true,tip:'',list:options.toOptions('从右向左=rtl,从左向右=ltr,从上向下=ttb,从下向上=btt')},

	{key:'cancelConfirm',text:'关闭前确认',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'是否关闭前是否需进行确认',list:null},
	{key:'confirmText',text:'确认提示语',type:'text',init:'是否确定取消',target:'',bursh:true,bind:true,tip:'点击右上角关闭图标或取消按钮前确认的提示信息',list:null},
	
	{key:'dialogWidth',text:'宽度',type:'text',init:'600px',target:'--dialog-width',bursh:true,bind:true,tip:'左右方向弹出时生效，象素、百分比',list:null},
	{key:'dialogHeight',text:'高度',type:'text',init:'300px',target:'--dialog-height',tip:'上下方向弹出时生效，象素、百分比',bursh:true,bind:true,list:null},	
	{key:'buttonType',text:'按钮排布',type:'select',init:'right',target:'',bursh:true,bind:true,tip:'按钮位置',list:options.toOptions('左侧=left,中间=center,右侧=right')},

	{key:'title',text:'取消按钮'},

	{key:'showCancel',text:'取消按钮',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'是否显示取消按钮',list:null},
	{key:'cancelText',text:'按钮文本',type:'text',init:'取消',target:'',bursh:true,bind:true,tip:'取消按钮的文本',list:null},
	{key:'cancelIcon',text:'按钮图标',type:'icona',init:'',target:'',bursh:true,bind:true,tip:'取消按钮的文本',list:null},
	{key:'cancelType',text:'按钮样式',type:'select',init:'default',target:'',bursh:true,bind:true,tip:'取消按钮的外观',list:compSet.colorTypes},
	
	{key:'title',text:'确定按钮'},
	{key:'showOK',text:'确定按钮',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'确定按钮的文本',list:null},
	{key:'okText',text:'按钮文本',type:'text',init:'确定',target:'',bursh:true,bind:true,tip:'确定按钮的文本',list:null},
	{key:'okIcon',text:'按钮图标',type:'icona',init:'',target:'',bursh:true,bind:true,tip:'确定按钮的文本',list:null},
	{key:'oKType',text:'按钮样式',type:'select',init:'primary',target:'',bursh:true,bind:true,tip:'确定按钮的外观',list:compSet.colorTypes},
	
/* 	{key:'minH',text:'最小高度',type:'text',init:'20px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
 
	{key:'rotateTime',text:'旋转周期(S)',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'光影旋转一周的时间（秒）',list:null},

	{key:'borderSize',text:'边框粗细',type:'text',init:'1px',target:'',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
	{key:'borderColor',text:'边框颜色',type:'colorA',init:'#00ffff',target:'',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	{key:'shadowColor',text:'光影颜色',type:'color',init:'#00ffff',target:'',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	{key:'shadowSize',text:'光影大小',type:'number',init:'10',target:'',bursh:true,bind:true,tip:'阴影扩散距离',list:null},
	{key:'twinkling',text:'光影闪动',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'duration',text:'闪动频率(S)',type:'number',init:'0.5',target:'',bursh:true,bind:true,tip:'',list:null},

 */

	//{key:'backColor',text:'背景颜色',type:'colorA',init:'#000000',target:'',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	
	

   // {key:'borderRadius',text:'圆角大小',type:'text',init:'6px',target:'--border-radius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 
	//{key:'flowSpeed',text:'流速',type:'number',init:80,target:'',bursh:true,bind:true,tip:'1-100之间',list:null},
	


	
	
]
 let events=[
 	 {key: '$comp_openBefore',text: '弹出前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '弹出前触发，可进行数据初始化等处理，如果返回false,将阻止弹出',list: null	},
 	 {key: '$comp_openAfter',text: '弹出后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '对话框弹出后触发',list: null	},

	 {key: '$comp_closeBefore',text: '关闭前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '点击X或取消按钮时触发，如返回false 可阻止关闭',list: null	},
//	 {key: '$comp_clickCancel',text: '点击取消',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '点击取消按钮时触发,会触发关闭前人事件，',list: null	},
	 {key: '$comp_clickOK',text: '点击确定',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '点击确认按钮时触发，在事件中自行关闭对话框',list: null	},

 ] 
  

let drawer= {
	data:{
		type:'modal_drawer',
	},
	delete:'',
	hide:'',
	base:base,//layout.concat(font) ,
	special :[],
	action:[].concat(events) //options.getEvents(null,'$timer,$event,$hook','$event_change,$event_input')
}

export default drawer